import React, { useState, useEffect, FormEvent } from 'react';
import { FullContainer, AlertMessaSucess, SelectionArtist, FormCard, ContentWrapper, Button } from '../../styles/pages/artist/homeStyles'
import { Container } from '../../styles/pages/responsive/artistResposiveStyle'
//import { FiUser, FiAtSign, FiMail, FiPhone } from 'react-icons/fi'
import { FaCheckCircle } from 'react-icons/fa';
import Footer from '../../components/Footer';
import ContainerArtistsImg from "../../assets/artists/contentArtistsImg.png";
import AD from "../../assets/android-store.svg";
import IO from "../../assets/ios-store.svg";
import GroupTelas from "../../assets/powerArtists__.png";
import contentProviderImg from "../../assets/contentProviders.png";

import loading from '../../assets/loading.gif';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import NavBar from '../../components/NavBar';
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { createAccountProvider, listArtists, getArtistExiste, dataContentProvider } from '../../services/CreateAccount';
import { dataGenres, formatNumbers } from '../../utils';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/config';
import { ProviderType } from '../../types';

const ArtistHome: React.FC = () => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectTypeUser, setSelectTypeUser] = useState("");
	const [process, setProcess] = useState(false);
	const [process_, setProcess_] = useState(false);
	const [advance, setAdvance] = useState(true);
	const [advances, setAdvances] = useState(true);
	const [advancesstep, setAdvancesstep] = useState(0);
	const [searchArtist, setSearchArtist] = useState<any>([]);
	//const [takeArtist, setTakeArtist] = useState<string>("");

	const [activeListArtsist, setActiveListArtsist] = useState<boolean>(false);

	const [allArtist, setAllArtist] = useState<any>();
	const [itemSelect, setItemSelect] = useState(false);
	const [editeArtist, setEditeArtist] = useState(false);
	const [result, setResult] = useState(false);
	const [result_, setResult_] = useState(null);
	const [existe, setExisteArtst] = useState("");
	const [messageError, setMessageErro] = useState("")
	const [providers, setProviders] = useState<ProviderType[]>([]);
	const [getValue, setGetValue] = useState<string>("");
	const [showMenu, setShowMenu] = useState<boolean>(true);
	const [activeNav, setActiveNav] = useState('#content__ProviderImg');
	const [tags, setTags] = useState<MultiValue<{ value: string; label: string }>>([]);
	const [tagsSingle, setTagsSingle] = useState<SingleValue<{ value: string; label: string; email: string }>>();
	const [form, setForm] = useState<any>({
		type: "",
		active: false,
		providerName: "",
		phoneNumber: "",
		email: "",
		representativeName: ""
	});
	const [formArtist, setFormArtist] = useState<any>({
		artist: "",
		type: "",
		active: true,
		phoneNumber: "",
		fullname: "",
		contentProviderEmail: "",
		contentProviderName: "",
		singerGenres: []
	});

	
	/************************functions ******************************** */
	useEffect(() => {
		const VerifyConcludeAdvance = () => {
			switch (selectTypeUser) {
				case "artist":
					if (!formArtist.fullname || !formArtist.artist || !formArtist.contentProviderEmail || !formArtist.phoneNumber) {
						setAdvance(true);
					}
					else {
						setAdvance(false);
					}
					break;
				case "provider":
					if (!form.providerName || !form.representativeName || !form.email || !form.phoneNumber) {
						setAdvance(true);
					}
					else {
						setAdvance(false);
					}
					break;
				default:
					return null;
			}
		}
		const listingArtists = async () => {
			const res = await listArtists();
			setAllArtist(res);
		};
		VerifyConcludeAdvance();
		listingArtists();
	}, [form, formArtist, selectTypeUser, advancesstep]);

	useEffect(() => {
		const selectProvider = async () => {
			const providers = await dataContentProvider();
			const arrProviders = providers.filter(value=> value.email !== 'info@toqueplay.com')
			if (arrProviders.length > 0) {
				setProviders(arrProviders);
			}
		}
		selectProvider();
		setFormArtist((formArtist: any) => ({ ...formArtist, singerGenres: tags }))
	}, [tags])

	useEffect(() => {
		if (formArtist.artist !== "" && formArtist.phoneNumber !== "" && formArtist.fullname !== "" && tags.length > 0) {
			setAdvance(false)
		} else {
			setAdvance(true)
		}

	}, [tags, formArtist])

	useEffect(() => {
		if (form.providerName !== "" && form.phoneNumber !== "" && form.email !== "" && form.representativeName) {
			setAdvances(false)
		} else {
			setAdvances(true)
		}
	}, [form])

	const onEditeArtist = () => {
		setFormArtist({ ...form, artist: "" });
		setEditeArtist(!editeArtist);
	}

	const capitalizeAllString = (str: string) => {
		const substing = str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
			return a.toUpperCase();
		});
		return substing;
	};

	const handleTags = (array: MultiValue<{ value: string, label: string }>, action: ActionMeta<{ value: string, label: string }>) => {
		if (action.action === "select-option") {
			const newTag = typeof action.option === "undefined" ? { value: "", label: "" } : action.option;
			setTags([...tags, newTag])

		} else if (action.action === "remove-value") {
			setTags(tags.filter((tag) => tag.value !== action.removedValue?.value))
			console.log(action.removedValue)
		} else if (action.action === "clear") {
			setTags([])
		}
	}

	/* const handleContentProvider = (contentProvider: SingleValue<{ value: string; label: string; email: string }>) => {
		setTagsSingle(contentProvider)
	} */

	let ArrGenres: string[] = [];


	const handleRequesArtistSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setProcess(true);

		formArtist.singerGenres.map((value: any) => ArrGenres.push(value.value))
		
		
		formArtist.type = selectTypeUser;
		formArtist.fullname = capitalizeAllString(formArtist.fullname);
		formArtist.contentProviderEmail = tagsSingle?.email;
		formArtist.contentProviderName = tagsSingle?.value;
		formArtist.singerGenres = tags;

		const EmailContentProvider = httpsCallable(functions, 'sendRequestEmailToContentProvider');
		
		const artistData = {
			contentProviderName: formArtist.contentProviderName,
			fullname: formArtist.fullname,
			artist: formArtist.artist,
			phoneNumber: formArtist.phoneNumber,
			contentProviderEmail: tagsSingle?.email,
			singerGenres: ArrGenres
		};

		console.log('artistData ==> ', artistData);

		EmailContentProvider(artistData)	
			
			.then((result: any) => {
				setResult(true);
				setProcess(false);
				onClear();
			
			}).catch((e) => {
				console.log("GOT ERROR:", e);
			})
	};

	const handleRequestProviderSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setProcess_(true);
		form.type = selectTypeUser;
		form.providerName = capitalizeAllString(form.providerName);
		form.representativeName = capitalizeAllString(form.representativeName);

		const data: any = await createAccountProvider(form);

		setProcess_(false)
		if (data === 1) {
			setResult_(data);
			setMessageErro("");
		};
		if (data === 2) {
			setMessageErro("Não foi possível fazer a socilitação. O email já está a ser usado por outra conta");
		};
		setProcess_(false)
		onClear();
	}

	const onchangeDataProvider = (e: React.FormEvent<HTMLInputElement>) => {
		const { name, value } = e.currentTarget;
		if (value && result) {
			setAdvancesstep(advancesstep + 1);
			setForm({ ...form, [name]: value });
		} else {
			setResult_(null);
			setForm({ ...form, [name]: value });
		}
	}
	const onchangeDataArtist = (e: React.FormEvent<HTMLInputElement>) => {
		const { name, value } = e.currentTarget;
		if (name === "artist") {
			setEditeArtist(true);
			const searched = allArtist?.filter((item: any) => {
				return (item.artist.toLowerCase().indexOf(value) !== -1 || item.artist.toUpperCase().indexOf(value) !== -1);
			})
			if (value) {
				setActiveListArtsist(true);
				setExisteArtst("");
				// if (searched.length === 0) { setTakeArtist("Não encontrou seu nome? Entre em contacto com a sua Label ou com o seu Provedor de Conteúdos.") }
				// else {
				// 	setTakeArtist("");
				// 	setExisteArtst("");
				// }
			} else {
				setActiveListArtsist(false);
				setEditeArtist(false);
			}
			setSearchArtist(searched)
		} else
			setAdvancesstep(advancesstep + 1);
		setFormArtist({ ...formArtist, [name]: value });
	}

	const onClear = () => {
		setForm({
			type: "",
			active: false,
			providerName: "",
			phoneNumber: "",
			email: "",
			representativeName: "",
			requestDate: "",
		});
		setFormArtist(
			{
				type: "",
				active: false,
				artist: "",
				phoneNumber: "",
				fullname: ""
			})
		setTags([]);
		//setTagsSingle(formArtist.contentProviderName);
		//setTakeArtist("");
		setEditeArtist(false);
	}
	const onFocusUser = () => {
		setMessageErro("");
		setProcess(false)
	}
	const clearMessage = () => {
		setMessageErro("");
	}

	/****************requestAccount*/
	const verifyArtistIfExiste = async (artist: any) => {
		const artistInfo = { artist: artist }
		const res = await getArtistExiste(artistInfo);
		if (res === "existe") {
			setExisteArtst(`Este artista já possui uma conta. Se és o ${artist} Por favor, acesse a sua conta.`);
			setFormArtist({ ...formArtist, artist: "" });
		} else {
			setExisteArtst("");
			setFormArtist({ ...formArtist, artist: artist });
		}
	}
	return (
		<>
			<FullContainer showModal={showModal} >
				<Container>
					<header className="header" >
						<div className="nav-bar">
							<NavBar status={false} showMenu={showMenu} setShowMenu={setShowMenu} />
						</div>
						<ContentWrapper>
							<div className="body-bainner">
								<div className="header-wrapper">
									<h1>Dê palco a sua voz.</h1>
									<img src={ContainerArtistsImg} alt='' />
								</div>
								<div className="mobile-btn">
									<button onClick={() => {
										setShowModal(true); setSelectTypeUser("");
									}}>Solicitar acesso
									</button>

									<a href="https://artists.toqueplay.com/">Acessar</a>
								</div>

							</div>
						</ContentWrapper>
					</header>
					<main>
						<ContentWrapper>
							<section className="section-artist" id="Page">
								<div className="wrappper-content">
									<h2>Desenvolvido para dar mais poder aos artistas</h2>

									<div className="card_">
										<img src={GroupTelas} alt='Desenvolvido para dar mais poder aos artistas' />
										<div className='badges__Phones'>
											<a href="https://play.google.com/store/apps/details?id=com.toquemedia.artists" rel="noreferrer" target='_blank'>
												<img className='store__android' src={AD} alt='Google Store' />
											</a>
											<a href="https://apps.apple.com/us/app/toqueplay-para-artistas/id1624300493" rel="noreferrer" target='_blank'>
												<img className='store__apple' src={IO} alt='Apple Store' />
											</a>
										</div>
									</div>

								</div>
							</section>
						</ContentWrapper>

						<div className='bg_'>
							<ContentWrapper>
								<section className="section-artist background" id="Page">
									<div className="wrappper-content">
										<h2>Porquê o ToquePlay para os Artistas?</h2>
										<div className="card-content">
											<div>
												<h3>Os seus dados, o seu percurso</h3>
												<p>Esta aplicação oferece uma experiência completamente grátis e o acesso às estatísticas de que precisa, tudo no mesmo lugar.</p>
											</div>
											<div>
												<h3>Estatísticas em que pode confiar</h3>
												<p>Aceda a estatísticas precisas que podem ser usadas para aumentar a sua audiência e melhorar os seus streamings.</p>
											</div>
											<div>
												<h3>Seja mais ouvido</h3>
												<p>Crie uma playlist Best Of dos teus Hits e posicione no topo das tuas obras discográficas no ToquePlay.</p>
											</div>
											<div>
												<h3>Conheça quem gosta dos seus conteúdos</h3>
												<p>Entre nos bastidores e descubra quem está a ouvir. Saiba mais acerca do seu público, incluindo o género e idade, para o ajudar a criar uma estratégia de conteúdo direccionado.</p>
											</div>
											<div>
												<h3>Partilhar é gostar</h3>
												<p>Quer sejam estatísticas-chave ou objetivos pessoais alcançados, partilhe as suas conquistas com a sua comunidade e mantenha-a ligada ao seu percurso.</p>
											</div>
										</div>
									</div>
								</section>
							</ContentWrapper>
						</div>

						<div className='bg'>
							<ContentWrapper>
								<section className="section-artist" id="Page">
									<div className="wrappper-content">
										<h2 className={activeNav === '#content__ProviderImg' ? 'h2_white' : 'no_h2_white'}>
											O ToquePlay trabalha com vários Content Providers, incluindo:
										</h2>

										<h2 className={activeNav === '#musico__form' ? 'h2_white_' : 'no_h2_white_'}>
											És artistas e ainda não estás no ToquePlay?
										</h2>

										<div className='card__'>
											<div className='buttons__'>
												<button
													onClick={() => setActiveNav('#content__ProviderImg')}
													className={activeNav === '#content__ProviderImg' ? 'active' : 'no__active'}>Content Provider
												</button>

												<button
													onClick={() => setActiveNav('#musico__form')}
													className={activeNav === '#musico__form' ? 'active' : 'no__active'}>Sou Músico
												</button>
											</div>
											{
												activeNav === "#content__ProviderImg" ?

													<div id='content__ProviderImg'>
														<img src={contentProviderImg} alt='Content Provider' />
														<div className="btn-access">
															<button onClick={() => {
																setShowModal(true);
																setSelectTypeUser("");
															}}>Solicitar acesso</button>
														</div>
													</div>

													:

													<div id='musico__form'>
														<h3>Complete o formulário de registo</h3>
														<form className="user-artist-form" onSubmit={handleRequesArtistSubmit}>
															<div className="input-group">
																<Input
																	name="fullname"
																	placeholder="Insira  o seu nome completo"
																	value={formArtist.fullname}
																	onChange={onchangeDataArtist}
																	autoComplete="off"
																	onFocus={() => onFocusUser()}
																/>
															</div>
															<div className="input-group">
																<Input
																	type="text"
																	name="artist"
																	placeholder="Insira o seu nome artístico"
																	value={formArtist.artist}
																	onChange={onchangeDataArtist}
																	onEditeArtist={onEditeArtist}
																	editeArtist={editeArtist}
																	onFocus={() => onFocusUser()}
																/>
																<SelectionArtist activeListArtsist={activeListArtsist}>
																	{
																		searchArtist?.map((item: any) => (
																			<ul key={item.artist}>
																				<li onClick={() => {
																					setActiveListArtsist(false);
																					verifyArtistIfExiste(item.artist);
																				}

																				}><div className="profile-artist" ><img src={item.profile} alt="foto do artista" /></div>
																					<div className="name-artsit">{item.artist}</div>
																				</li>

																			</ul>
																		))
																	}
																</SelectionArtist>
																<span className="artist-not-found">{existe ? existe : ""}</span>
															</div>
															<div className="input-group">
																<CreatableSelect className='select_'
																	placeholder="Content Provider com quem desejas trabalhar"
																	options={providers}
																	value={tagsSingle}
																	onChange={setTagsSingle}
																	onFocus={() => onFocusUser()}
																	formatCreateLabel={() => <span>Seleciona o provedor</span>}
																/>
															</div>
															<div className="input-group">
																<CreatableSelect className='select_'
																	placeholder="Que estilo de música cantas?"
																	isMulti
																	options={dataGenres}
																	value={tags}
																	onChange={handleTags}
																	onFocus={() => onFocusUser()}
																	formatCreateLabel={() => <span>Seleciona o seu genero</span>}
																/>
															</div>
															{/*<span className="artist-not-found">{activeListArtsist ? takeArtist : ""}</span>*/}
															<div className="input-group">
																<Input
																	type="phone"
																	name="phoneNumber"
																	placeholder="Insira o seu telefone"
																	value={formArtist.phoneNumber}
																	onChange={onchangeDataArtist}
																	onFocus={() => onFocusUser()}
																/>
															</div>
															<div className="btn-group">
																{
																	!process && result ?
																		(
																			<AlertMessaSucess>
																				<div><FaCheckCircle color="green" size={40} /></div>
																				<article className='info__'>
																					<h3>Solicitação enviada com sucesso.</h3>
																				</article>
																			</AlertMessaSucess>
																		)
																		:
																		(process ?
																			<img src={loading} alt="processando..." />
																			:
																			<Button advance={advance} disabled={advance}>
																				Enviar
																			</Button>
																		)
																}
															</div>

														</form>

													</div>
											}

										</div>
									</div>
								</section>
							</ContentWrapper>
						</div>
						<ContentWrapper>
							<section className='section-artist'>
								<div className="wrappper-content">
									<h2>Calculadora de Royalties</h2>
									<div className="calc__royalty">
										<form className="royalt-form" >
											<h4>Streaming</h4>
											<label htmlFor="fullvalor">Com Base no Cálculo de Streaming Por Mil (SPM)</label>
											<Input
												name="fullvallor"
												type='text'
												maxLength={15}
												placeholder="Insira o valor"
												value={getValue}
												onChange={(e) => { setGetValue((e.target.value.replace(/\D/g, ''))) }}
											/>
											<h1>
												{
													formatNumbers(Number(getValue), true)
												}
											</h1>
										</form>
									</div>
								</div>
							</section>
						</ContentWrapper>
					</main>

					<Footer background={"black"} />
				</Container>
			</FullContainer>

			<Modal showModal={showModal} setShowModal={setShowModal} setSelectTypeUser={setSelectTypeUser} itemSelect={itemSelect} setItemSelect={setItemSelect} clearMessage={clearMessage}>

				<FormCard selectTypeUser={selectTypeUser}>
					<div className="logo">
						<img src={"/logo.svg"} alt="ToquePlay" />
					</div>
					<h3>Complete o formulário de registo</h3>

					<div className="modalContent">
						<form onSubmit={handleRequestProviderSubmit} className="user-user_provider-form">
							<Input
								type="text"
								name="providerName"
								placeholder="Insira o nome do provedor de conteúdo"
								value={form.providerName}
								onChange={onchangeDataProvider}
								onFocus={() => onFocusUser()}
							/>
							<Input
								type="text"
								name="representativeName"
								placeholder="Insira o nome do representante"
								value={form.representativeName}
								onChange={onchangeDataProvider}
								onFocus={() => onFocusUser()}
							/>
							<Input
								type="email"
								name="email"
								placeholder="Insira o seu email"
								value={form.email}
								onChange={onchangeDataProvider}
								onFocus={() => onFocusUser()}
							/>
							{
								messageError ?
									<div className="artist-not-found" ><span>{messageError}</span></div> : null
							}
							<Input
								type="phone"
								name="phoneNumber"
								placeholder="Insira o seu telefone"
								value={form.phoneNumber!}
								onChange={onchangeDataProvider}
								onFocus={() => onFocusUser()}
							/>
							<div className="btn-group">
								{
									process_ ?

										<img src={loading} alt="processando..." />
										:
										<Button advance={advances} disabled={advances}>
											Solicitar acesso
										</Button>
								}
							</div>
							{
								result_ ? (
									<AlertMessaSucess>
										<div><FaCheckCircle color="green" size={50} /></div>
										<h2>Solicitação enviada com sucesso. Você será contactado num período de 24 a 48 horas.</h2>
									</AlertMessaSucess>
								) : ""
							}
						</form>
					</div>
				</FormCard>
			</Modal>
		</>

	)
}

export default ArtistHome;
