import React from 'react';
import styled from 'styled-components';
import { UserType } from '../../types';


const AccountOverview = (): JSX.Element => {
    const user = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;


    return (
        <div>
            {/* <SliderTop /> */}
            <Container>
                <h1>Visão geral da conta</h1>
                <h2>Perfil</h2>
                <div className='user-profile-info'>
                    <ul>
                        <li>
                            <span className='span'>Nome: </span>
                            <span className='user--data user-name'>{user.displayName}</span>
                        </li>
                        {user.phoneNumber ?
                            <li>
                                <span className='span'>ID: </span>
                                <span className='user--data'>{user.phoneNumber}</span>
                            </li>
                            :
                            <li>
                                <span className='span'>Email: </span>
                                <span className='user--data'>{user.phoneNumber}</span>
                            </li>
                        }
                    </ul>
                </div>
                {/* <button className='join-btn'> <a href='/dashboard/editprofile'>Edit profile</a></button> */}
            </Container>
        </div>
    );
};

const Container = styled.div`
    padding: 1em;
    font-family: "Segoe UI", -apple-system, BlinkMacSystemFont,  Roboto, "Helvetica Neue",
	Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	
    
    h1{
        font-size: 4rem;
        color: #000;
        font-weight: bold;
        text-align:center;
		margin-bottom: 1em
    };
    h2{
        font-size: 1.4rem;
        font-weight: 400;
        color: #000;

    };
	@media (max-width: 768px) {
		h1{
			font-size: 2rem;
			margin-top: 1em;
   		};
		h2{
			display: none;
   		};
		.user-name{
			margin-left: 1em;
		}
		
	}
    .user-profile-info li{
        position: relative;
        display: block;
        padding: 5px 5px;
        margin-bottom: -1px;
        background-color: #fff;
        border-bottom: 1px solid #ddd;
        padding: 1.3rem 0;
        margin:1em 0;
        display: flex;
        .span{
            width: 14%;
            font-size: 1.3em;
            color: #999;
        }
        .user--data{
            font-size: 1.4em;
            font-weight: 400;
            color:black
        }
    }

    button{
        display: inline-block;
        padding: 1em 2em;
        color: white;
        margin-top: 1em;
        
        font-size: 1.3em;
        font-weight: bold;
        vertical-align: middle;
        background-image: none;
        border: 1px solid #999;
        cursor: pointer;
        background-color: transparent;
        border-radius: 2em;
        text-decoration:none;

        &:hover {
            background: #eee;
        }
        &:active{
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -o-transform: translateY(5px);
    }

    a{
        text-decoration:none;
        color: black;
    }

    
}
`;
export default AccountOverview;