import { httpsCallable } from 'firebase/functions';
import React, { useState } from 'react';
// import { FaPaypal } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import Styled from 'styled-components';
import ExpressLogo from '../../../assets/express.webp';
import Timer from '../../../components/timer';
import { dbFirestore, firestore, functions } from '../../../firebase/config';
import { PlanType, UserType } from '../../../types';
import { ErrorModal, SucessModal } from '../../store-page/Store';


interface Props {
    closeModal: () => void,
    planData: PlanType
}

const PayPlanModal = ({ closeModal, planData }: Props): JSX.Element => {
    const [multicaixaExpr, setMulticaixaExpr] = useState<boolean>(false);
    const [mobilePhone, setMobilePhone] = useState<string>('');
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [buySuccess, setBuySuccess] = useState(false);
    const [buyError, setBuyError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [token, setToken] = useState<string>('');
    const user = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [openModalBuyModal, setOpenModalBuyModal] = useState(false);
    // ============ SOCKETIO ======================

    const requestToken = () => {
        const getAcessToken = httpsCallable(functions, 'requestToken');
        getAcessToken()
            .then((result: any) => {
                setToken(result.data.access_token);

            })
            .catch((e) => {
                console.log(e);
            });
    }

    const subscribePremium = () => {

        if (token !== '') {
            return new Promise(async () => {

                setLoadingBtn(true);
                const subscribePremiumPlan = httpsCallable(functions, 'subscribePremiumPackage');
                subscribePremiumPlan(
                    {
                        description: 'Adesão do plano premium de 30 dias',
                        amount: planData.price,
                        mobilePhone: '244' + mobilePhone,
                        token: token
                    }
                )
                    .then(async (result: any) => {
                        console.log(result);
                        if (result.data.errorCode) {
                            switch (result.data.errorCode) {

                                case 'PROCESSOR_907':
                                    setErrorMessage('Operação recusada. Por favor, tente novamente. Caso persista, entre em contacto connosco.');
                                    break;

                                case 'ISSUER_907':
                                    setErrorMessage('Não existem fundos suficientes na tua conta.');
                                    break;

                                case 'EPMS_907':
                                    setErrorMessage('Operação recusada pelo processador de pagamentos - EMIS.');
                                    break;

                                case 'PROCESSOR_940':
                                    setErrorMessage('Você cancelou o pagamento.');
                                    break;

                                case 'TIMEOUT':
                                    setErrorMessage('O tempo para concluir o pagamento esgotou. Por favor, tente novamente.');
                                    break;

                                case 'BANK_822':
                                    setErrorMessage('Excedido o limite de pagamento.');
                                    break;

                                case 'EXT_AUTH_907':
                                    setErrorMessage('O tempo para concluir o pagamento esgotou. Por favor, tente novamente.');
                                    break;

                                case "EPMS_940":
                                    setErrorMessage('Você cancelou o pagamento.');
                                    break;
                            }

                            setBuyError(true);
                            setTimeout(() => {
                                setMobilePhone('');
                                setLoadingBtn(false);
                                setBuyError(false);
                            }, 1000)
                        } else if (result.data.code) {

                            await firestore.setDoc(
                                firestore.doc(dbFirestore, 'premium_active', user.phoneNumber as string),
                                planData
                            ).then(() => {
                                window.location.reload();
                                localStorage.setItem('currentPlan', JSON.stringify({
                                    plan: 'premiunPlan',
                                    user: user.phoneNumber
                                }));
                            })
                            setBuySuccess(true);
                            setTimeout(() => {
                                setLoadingBtn(false);
                                setBuySuccess(false);
                                setMobilePhone('');
                                window.location.reload();
                            }, 1000)
                        }

                    }).catch(async (e) => {
                        console.log(e);

                        setBuyError(true);
                        setTimeout(() => {
                            setMobilePhone('')
                            setLoadingBtn(false)
                            setBuyError(false);
                            //window.location.reload();
                        }, 5000)
                        setErrorMessage('Por alguma razão o pagamento não foi efectuado.');
                    })
            });
        } else {
            console.log('TOKEN ERROR...')
        }

    }

    const history = useHistory();

    const goLogin = () => {
        history.push('/login');
    };
    const time = 0;
    return (
        <Container>
            <div> <button onClick={closeModal} className='close-modal'>x</button></div>
            <div className='checkout--modal'>
                {/* <div className='paypal-btn'>
                    <FaPaypal className='paypal-icon'/>
                    <span>PAYPAL</span>
                </div> */}
                <div className='express-payment'>
                    <button className='btn-express-payment'
                        onClick={() => {
                            if (user) {
                                requestToken();
                                setMulticaixaExpr(true);
                            } else {
                                goLogin()

                            }
                        }}
                    >
                        <img className="logo" src={ExpressLogo} height='40' width='40' alt=' Express Logo' />
                        <span>MULTICAIXA Express</span>
                    </button>

                    <div className="payment-content">
                        {multicaixaExpr &&
                            <div className="multicaixa-payment">
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                }}>

                                    <p>Digite o número associado ao Multicaixa Express</p>

                                    <div className="payment-field">
                                        <p>+244</p>
                                        <input type="tel" placeholder="número"

                                            maxLength={9}
                                            size={9}
                                            onChange={(e) => {
                                                setMobilePhone(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div className="experess-btn">

                                        {
                                            loadingBtn !== false && mobilePhone.length === 9 ?
                                                <div className="loader-spiner">
                                                    <p>Irá receber uma notifição no seu Multicaixa Express</p>
                                                    <ClipLoader color={"#FF5F00"} loading={true} size={30} />
                                                    <Timer
                                                        time={time}
                                                        setOpenModalBuyModal={setOpenModalBuyModal}
                                                        setLoadingBtn={setLoadingBtn}
                                                        setBuyError={setBuyError}
                                                        setMulticaixaExpr={setMulticaixaExpr}
                                                    />
                                                </div>
                                                :
                                                token !== '' ?
                                                    <button
                                                        type="submit"
                                                        onClick={() => {
                                                            if (mobilePhone.length === 9) {
                                                                subscribePremium();
                                                            }
                                                        }}
                                                    >Prosseguir</button>
                                                    :
                                                    <ClipLoader color={"#FF5F00"} loading={true} size={30} />
                                        }

                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>


            {buySuccess &&
                (
                    <SucessModal>
                        <div>
                            <p>Plano Aderido com sucesso!</p>
                        </div>
                    </SucessModal>
                )
            }
            {
                buyError &&
                (
                    <ErrorModal>
                        <div>
                            <p>{errorMessage}</p>
                        </div>
                    </ErrorModal>
                )
            }
        </Container>
    );
};
const Container = Styled.div`
        background: rgba(0, 0, 0, .90);
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 99999;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .close-modal{
            display: inline-block;
            width:50px;
            height:50px;
            border: 1px solid transparent;
            cursor: pointer;
            background-color: #555;
            border-radius: 50%;
            color:white;
            font-weight:bold;
            font-size:1.2em;
            margin-bottom:5px;
            &:active{
                transform:translateY(5px)
            }
             &:hover {
                background: #444;
            }
        }

		
        .checkout--modal{
            background-color: #fff;
            width:400px;
            height:300px;    
            border-radius: 0.5em;
            padding:0.3em; 
			margin:1em;
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction:column;
            .paypal-btn{
                position:relative;
                background:#009cde;
                font-size:1.5em;
                .paypal-icon{
                color:white;
                }
            }
            .paypal-btn,.express-payment{
                width:80%;
            }
        }
		@media (max-width: 768px) {
			
			.checkout--modal{
				width:95%;
				margin: 0 auto;
			}
		
		}
    .btn-express-payment,.paypal-btn{
        border-radius: 0.4em;
        background-color: #db7e05;
        display: inline-block;
        color: white;
        font-size: 1.2em;
        font-weight: bold;
        text-transform: uppercase;
        background-image: none;
        border: 1px solid transparent;
        cursor: pointer;
        margin-top:1em;
        padding:0.5em;
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width:100%;
        &:hover {
            background: #ee8803;
        }
         &:active{
                transform:translateY(5px)
        }
        img{margin-right:5px}
    }

    .multicaixa-payment{
        P{color:#000;}
    }
    .payment-content {h1 {color: #333;}
		.multicaixa-payment {
			display: flex;
			flex-direction: column;
			p {
				color: #333;
				margin: 10px 0;
			}
			.payment-field {
				padding: 0 15px;
				display: flex;
				align-items: center;
				height: 45px;
				border: 1px solid #FF5F00;
				border-radius: 4px;
				p {margin-right: 5px;color: #333;}
				input {
					width: 90%;
					padding: 0 10px;
					height: 40px;
					border: 0;
					color: #333;
					font-size: 16px;
				}
			}
			.experess-btn{
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 20px 0;
				height: 60px;
				width: 100%;
				button {
					background: #FF5F00;
					text-transform: capitalize;
					width: 120px;
					height: 45px;
					border: 0;
					border-radius: 4px;
					transition: filter .2s;
					&:hover {
						filter: brightness(0.8);
					}
				}
			}
			.loader-spiner {
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				width: 100%;
				p {font-size: 12px;}
			}
		}
	}
`;
export default PayPlanModal;
