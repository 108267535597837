import React from 'react';
import Styled from 'styled-components';
import { UserType } from '../../types';
import Login from '../login-page/Login';
import Header from './Header';
import Profile from './Profile';
import Sidebar from './SideBar';

const Dashboard = (): JSX.Element => {
    const user = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;

    if(user === null){
        return(
            <Login />
        )
    }
    return (
        <DashboardStyles>
            <Header />
            <Wrapper>
                <Sidebar />
                <Profile/>
            </Wrapper>
        </DashboardStyles>
    );
};

const DashboardStyles = Styled.div`
    display: flex;
    flex-direction: column;
    background:#fff;
`;
const Wrapper = Styled.div`
    display: flex;
    flex-direction: row;
    margin-top:5em;
    .profile_active{
        background: #222;
        a {
            color: #ff2a00;
        }
    }

    .payment_plans_active{
        background: #000;
        a {
            color: #fff;
        }
    }
`;
export default Dashboard;
