import styled from 'styled-components'
export const FullContainer = styled.div<{ showModal: boolean }>`
    	background-color: #000;
		position: ${(props) => (props.showModal ? "fixed" : "none")};
		.header	{
			width: 100vw;
			height: 130vh;
			position: relative;
			
			.nav-bar {
				.full-menu {
					.btn-create { 
						justify-content: right;

						a { display: none; }

						.btn-access {
							width: auto;
							display: block;
							padding: 20px 0px; 						
							position: relative;
							
							a, button {
								padding: 10px 8px;
								background-color: #f00;
								display: block;
								border-radius: 30px;
								width: 200px;
								text-align: center;
								cursor: pointer;
								border: none; 
								outline: none;
								:hover { color: #fff; }
							}
						}
					}
				}			
			}
		}
		.body-bainner{
			display: flex;
			flex-direction: row;
			flex: 1;
			position: relative;

			.mobile-btn{ display: none; }

			.header-wrapper{
				flex: 1.5;
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				h1{ 
					width: auto;
					margin-top: 9rem;
					text-align: center;
					font-size: 70pt;
				}
				img {
					padding-top: 2rem;
					display: block;
					margin: 0 auto;
				}
			}	
		}
		.footer-go-home{
			display: flex;
			align-items: flex-end;
			position: absolute;
			bottom: 0;
			padding: 20px 0px;

			span{ cursor: pointer;}
		}
		.artist{
			z-index: 0;
			width: 250px;
			min-width:250px;
			height:270px;
			clip-path: circle();
	
			img{
				min-height: 250px;
				min-width: 250px;
				width: 100%;
				height: 100%;
			}
		}
		main{
			background-color: #fff;
			color: #000;
			z-index: 1;
			position: relative;	
		}

		.bg { background: #000; }

		.bg_ { background: #f2f2f2; }

		.section-artist{
			width: 100%;
			height: auto;
			display: flex;
			padding: 40px 0px;

			.background{
				background: #f4f4f4;
			}
		}
		.wrappper-content{
			height: auto;
			display: block;
			margin: 0 auto;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			order: 2;
			padding: 10px 0;

			h2, h3{ color: #000; }
			
			h2 { font-size:45pt; }

			.h2_white { color: #fff; padding: 0 5rem;  }
			.h2_white_ { color: #fff;  padding: 0 5rem;  }
			.no_h2_white{ display: none; border: 1px solid #f00; }
			.no_h2_white_{ display: none; border: 1px solid #f00; }
				
			h3 { 
				font-size: 30pt; 
				padding-top: 4rem;
				padding-bottom : 1rem ;
			}
			.card-content{
				display: grid; 
				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: auto;
				column-gap:40px;
				row-gap: 15px;

				div{
					width:100%;
					padding-top: 10px; 
					text-align: left;

					h3{	color: #000; font-size: 16pt; }

					p{ font-size:15pt; color: #555; }
				}
			}
			.card_ {
				padding-top: 50px;

				img {
					width: 60%;
					height: auto;
					display: block;
					margin: auto;
				}
				.badges__Phones {
					display: block;
					margin: 0 auto;
					align-items: center; 
					display: inline-flex; 
				}
				a img.store__android { width: 10rem;}

				a img.store__apple { width: 12rem; height: 2.8rem; }
			}
			.card__ {
				text-align: center; 
				
				.buttons__ {
					width: 100%;
					margin: 0 auto;
					padding: 3rem 0rem;
					display: flex;
					justify-content: center;

					button {
						padding: 0.9rem 1.5rem;
						border-radius: 0.5rem;
						margin: 0 2rem;
						font-size: 2rem;
						font-weight: 800;
						border: none;
					}
					.active { background: #f00; color: #fff; }
					.no__active { background: #fff; color: #000; }
					
				}
				#content__ProviderImg {
					img {
						width: 700px; 
						height: auto;
						margin: 2rem 0;
					}
					.btn-access {
						width: 100%;
						display: flex; 
						padding: 35px 20px 20px; 

						button{
							padding: 0.5rem 1rem;
							background-color: #f00;
							display: block;
							margin: 0 auto;
							border-radius: 5px;
							font-weight: 800;
							width: 230px;
							text-align: center; 
							font-size: 1.5rem;
							cursor: pointer;
							border: none;
							outline: none;
						}
					}
				}
				#musico__form {
					width: 50%;
					height: auto;
					margin: 0 auto;

					h3{ color: #fff; padding: 0px; }

					form { margin-top: 20px; 
						
						.input-group { margin: 0.5rem 0; 

							.select_ { text-align: left; }
		
							div.css-6j8wv5-Input { 
								padding: 10px; 
								margin: 3px 0;
							}
							.joCgUl {
								background: #fff;
								padding-left: .6rem;
							}
						}
					}
				}
			}
			.calc__royalty {
				width: 80%;
				border-top: 4px solid #777; 
				border-radius: 10px;
				background: #000; 
				color: #fff; 
				margin-top: 20px;
				margin-bottom: 40px;
				margin-left: auto;  
				margin-right: auto;
				padding: 2rem;

				form {
					h4 { color: #fff; font-size: 15pt; }
					
					input {
						text-align: center;
						font-size: 12pt;
						font-weight: 700;
						::placeholder { color: #777; }
					}
					label { font-size: 12pt; margin-bottom: 30px; }

					h1 { font-size: 21pt; margin-top: 20px; }
				}
			}
		}
	
		/*************MEDIA QUERIES (SMALL DEVICES)***********/
		@media (max-width: 667px ) {
			.header { 
				height: 50vh; 
				width: auto;

				.body-bainner{

					.header-wrapper {
						h1	{
							margin-top: 4rem;
							font-size: 20pt;
						}
						img {
							width: 300px;
							height: auto;
						}
					}

					.mobile-btn {
						button {
							padding: 4px 2px;
						}
						a {
							padding: 4px 2px;
						}
					}
				}	
			}
			.section-artist {
				.wrappper-content {
					.h2_white { color: #fff; padding: 0rem; }
					.card__{
						.buttons__{
							width: auto;
							padding: 3rem 0; 
							
							button {
								padding: 1rem;
								margin: 0 1rem;
								font-size: 1rem;	
							}
						}
						#content__ProviderImg {
							img{
								width: 300px;
								margin: 1rem 0;
							}
						}
						#musico__form {
							width: auto;
						}
					}
				}
			}
		}
   `
export const ContentWrapper= styled.div`
   max-width:1200px;
   width: 96%;
   margin: auto;
   display: flex;
   flex-direction: column;
`
export const FormCard = styled.div<{selectTypeUser: string}>`
   width: 70%;
   display: flex;
   margin: auto;
   justify-content: start;
   flex-direction: column;
   padding: 20px 30px;
   color: #555;
   overflow-y: auto;
   overflow-x: hidden;
	z-index: 0;
	height: 100%;
	.logo { margin: 0 auto; }
	h3 { text-align: center; }
	.user-artist-form {
		.sc-ksluID { margin-top: 10px; }
	}

	@media (max-width: 768px) {
		padding: 10px 10px;
		align-items: center;
		
	}

	@media (max-width: 600px) {
		width: 100%;
	}
   .logo{
		width: 250px;
		img{ width: 100%; }

		@media (max-width: 600px) {
			width: 160px;
		}
	}
 
    h3{
		color: #000;
		font-size: 16pt;
		padding: 20px 0px;
		font-weight:bold;
		@media (max-width: 768px) {
			text-align: center;
			font-size: 12pt;
			width: 100%;
	}
	}
	.user-artist-form{
		display: ${(props) => (props.selectTypeUser==="artist"? "flex" : "flex")};
		flex-direction: column;
		
	}
	
	.user-user_provider-form{
		display: ${(props) => (props.selectTypeUser ==="provider"? "flex" : "flex")};
		flex-direction: column;
	}
	


	.select-acount{
		background: #fff;
		display: flex;
		align-items: center;
		width: 100%;
		border: 1px solid #ddd;
		border-radius: 3px;
	
		
		.options{
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			position: relative;
			cursor: pointer;
			color: #000;
			
		
			.select-item{
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-radius: 4px;
				padding: 16px;
				width: 100%;
				color: #000;
				font-size:14pt;

				
			}
		}
	}
	.modalContent{
		width: 100%;
		padding: 0px;
	}

	.input-group{
		height: auto;
		position: relative;
		color: #000;
	
		select{
			border: none;
			background: #fff;
			border-radius: 4px;
			text-align: left;
			padding: 16px;
			width: 100%;
			color: #666360;
			display: flex;
			align-items: center;
			font-size: 14pt;
			outline: none;
			border: 1px solid #ddd;
			
			option{
				color: #f00;
				font-size: 14pt;
				color: #1c1c1c;
				padding: 30px;
			}
		}
	}
	.artist-not-found{
		color: #d00;
		width: 100%;
		text-align: left;
		font-size:14pt;
	}
	.btn-group{
		padding-top:20px;
	}
`;

export const Button = styled.button<{advance:boolean}>`
	width: 200px;
	background: #d00;
	border-radius: 50px;
	padding: 12px;
	border:none;
	color: #fff;
	margin-top: 30px;
	text-align: center;
	cursor:  ${(props) => (props.advance? "auto": "pointer")};
	opacity: ${(props) => (props.advance? .5: 1)};

	@media (max-width: 768px) {
		padding: 10px 20px;
		width: 100%;
	}
`
export const ItemTypeUser = styled.div<{itemSelect:boolean}>`
  display: ${(props) => (props.itemSelect? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  height: auto;
  background-color: #fff;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 20;
  box-shadow: 0px 0px 1px rgba(0,0,0,0.5);
  overflow-y: auto;
  overflow-x: hidden;

  ul {
	  width: 100%;
	  display: block;
	 
	  li{
		  padding: 5px 10px;
		  font-size: 13pt;
		  color: #666360;
		  cursor: pointer;

		  :hover{
			  background-color: #666360;
			  color: #fff;
			  transition: all .2s;
			  cursor: pointer;
		  }
		 
	  }
  }

`

export const SelectionArtist = styled.div<{activeListArtsist:boolean}>`
		display: ${(props) => (props.activeListArtsist? "flex" : "none")};
		flex-direction: column;
		position: absolute;
		width: 100%;
		height: auto;
		background-color: #fff;
		left: 0;
		top: 100%;
		width: 100%;
		z-index: 20;
		box-shadow: 0px 0px 1px rgba(0,0,0,0.5);
		overflow-y: auto;
		overflow-x: hidden;

		ul{
			margin-top: 3px;
			display: flex;
			flex-direction: column;
			justify-content: start;

			li {
				width: inherit;
				height: inherit;
				display: flex;
				align-items: center;
				flex-direction: row;
				padding: 10px;
				cursor: pointer;
				:hover{
					background-color: #ddd;
				}
				.name-artsit{
					margin-left: 15px;
				}
				.profile-artist{
					height: 50px;
					width: 50px;
					
					img {
						height: 50px;
						width: 50px;
						border-radius: 10px;
					}
				}
			}
		}
  
`
export const AlertMessaSucess = styled.div`
	width: 100%;
	padding:30px 0px;
	margin-top: 30px;
	text-align: center;
	border-top: 1px solid #ddd;
	
	h2{
		color: #555;
		font-size: 15pt;
		padding: 10px;
	}

`


