import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiPhone } from 'react-icons/fi';
import { GrApple, GrGoogle } from 'react-icons/gr';
import { dbFirestore, firestore } from "../../firebase/config";
import { onSignInSubmit } from "../../services/Authentication";
import { LoginWithApple, LoginWithGoogle } from '../../services/server';
import { Container } from "../../styles/pages/login/styles";
import NavBar from '../../components/NavBar';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
// import { useAuthState } from 'react-firebase-hooks/auth';

const Login = () => {
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [verifyError, setVerifyError] = useState(false);
	const [dontHaveAccount, setDontHaveAccount] = useState<boolean>(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [, setLoginPhoneNumber] = useState(false);
	const [signInPhoneNumberPromise, SetsignInPhoneNumberPromise] = useState<any>();
	const [inputCode, setInputCode] = useState('');
	const [hasPhoneNumber, SetHasPhoneNumber] = useState(false)
	const [loadingNumber, setloadingNumber] = useState<boolean>(false);
	const [loadingLogin, setloadingLogin] = useState<boolean>(false);

	// const [user] = useAuthState(auth.getAuth());
	
	const history = useHistory();

	const goHome = () => {
		history.goBack()
	};

	// ================ number phone authentication ==================================
	const handleChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setPhoneNumber(e.target.value.replace(/[^0-9]/g, ''));
	};
	const handleSubmitPhoneNumber = async (e: any) => {
		setloadingNumber(true)
		e.preventDefault();

		const number = `+244${phoneNumber}`;

		const userRef = firestore.doc(dbFirestore, 'users', number);
		const dataDoc = await firestore.getDoc(userRef);

		if (dataDoc.exists()) {
			setloadingNumber(false);
			SetHasPhoneNumber(true);

			const signInPhoneNumber = onSignInSubmit(phoneNumber);
			signInPhoneNumber.then(() => {
				SetsignInPhoneNumberPromise(signInPhoneNumber);
			})

		} else {
			setDontHaveAccount(true);
			setloadingNumber(false)
		}
	};
	const handleSubmitInputCode = async (e: any) => {
		e.preventDefault();
		setloadingNumber(true)
		signInPhoneNumberPromise.then((confirmationResult: any) => {
			confirmationResult.confirm(inputCode)
				.then(async (result: any) => {

					const email = result?.user.phoneNumber as string;
					const userRef = firestore.doc(dbFirestore, 'users', email);
					const dataDoc = await firestore.getDoc(userRef);

					if (dataDoc.exists()) {
						setloadingNumber(false)
						const data = dataDoc.data();
						localStorage.setItem('userToquen', JSON.stringify(data));
						setTimeout(() => {
							goHome();
						}, 1000);
					}

				}).catch((error: any) => {

					setloadingNumber(false)
					setVerifyError(true);
					setTimeout(() => {
						SetHasPhoneNumber(false);
						setVerifyError(false);
					}, 3000);
					console.log('codigo de verificacao invalido:' + error);
				});
		}).catch((error: any) => {
			console.log(error);
		});
	};
	const handleChangeInputCode = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setInputCode(e.target.value);
	};
	// ================ number phone authentication end==================================

	const googleLogin = useCallback(async () => {
		try {
			LoginWithGoogle()
				.then(async result => {
					setloadingLogin(true);

					const email = result.user.providerData[0].email as string;
					// console.log(result);
					const userRef = firestore.doc(dbFirestore, 'users', email);
					const dataDoc = await firestore.getDoc(userRef);

					if (dataDoc.exists()) {

						const data = dataDoc.data();

						localStorage.setItem('userToquen', JSON.stringify(data));

						setloadingLogin(false);

						setTimeout(() => {
							history.goBack()
						}, 1000);

					} else {
						setDontHaveAccount(true);
						setloadingLogin(false);

					}
				})
		} catch (error) {
			console.log(error)
		}
	}, [history]);


	const appleLogin = useCallback(async () => {
		try {
			LoginWithApple()
			.then(async result => {
				setloadingLogin(true);

				const email = result.user.providerData[0].email as string;
				// console.log(result);
				const userRef = firestore.doc(dbFirestore, 'users', email);
				const dataDoc = await firestore.getDoc(userRef);

				if (dataDoc.exists()) {
					setloadingLogin(false);
					const data = dataDoc.data();
					localStorage.setItem('userToquen', JSON.stringify(data));

					setTimeout(() => {
						history.goBack()
					}, 1000);

				} else {
					setloadingLogin(false);
					setDontHaveAccount(true);
				}
			})
		} catch (error) {
			console.log(error)
			
		}
	}, [history]);

	return (
		<Container>
			<div className='header-container'>
				<NavBar status={true} showMenu={showMenu} setShowMenu={setShowMenu} />
			</div>
			<div className="login-container">
				<h1>Inicie sessão</h1>
				<p>Escolha uma opção para utilizar a sua conta</p>
				<div className="login-field">

					<div className="login-provider goog-provider"
						onClick={googleLogin}
					>
						<p> <GrGoogle />Continuar com google</p>
					</div>

					<div className="login-provider apple-provider"
						onClick={appleLogin}
					>
						<p> <GrApple size={30} />Continuar com Apple</p>
					</div>

					{/* <div className="login-provider app-provider">
						<p><FaApple/> Continuar com apple</p>
					</div> */}
					{/* <div className="login-provider mob-provider">
						<p> <FiPhone/> Continuar telefone</p>
					</div> */}
					<div style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
						<hr style={{ maxWidth: "70px", width: "100%" }} />
						<p style={{ width: "100px", fontSize: "14px" }} > ou entrar com</p>
						<hr style={{ maxWidth: "70px", width: "100%" }} />
					</div>
					<div id="sign-in-button" />
					<form onSubmit={handleSubmitPhoneNumber} >
						<div className="mobile-field">
							<p><FiPhone />+244</p>
							<input type="text" placeholder='número de telefone'
								onChange={handleChangePhoneNumber}
							/>
						</div>
						<button className='btn-send'
							onClick={() => {

								setLoginPhoneNumber(true);
							}
							}>
							{
								loadingNumber ? <ClipLoader color={"#fff"} loading={true} size={20} /> : 'Entrar'
							}

						</button>
					</form>

					{
						hasPhoneNumber && (
							<GetCodeModal>
								<div className="verify-code-field">
									<p>Enviamos código de verificação</p>
									{/* <div className='resend-field'>
										
										<button>reenviar</button>
									</div> */}
									<div className="verify-code">
										<form onSubmit={handleSubmitInputCode}>
											<input type="text" placeholder='Digite o código'
												onChange={handleChangeInputCode}
												required
											/>
											{
												verifyError && (
													<div>
														<p
															style={{
																color: "#ff7f7f",
																margin: "0"
															}}
														>código invalido, tente novamente</p>
													</div>
												)
											}
											<div className='btn-options'>
												<button className='btn-verify' type='submit'>

													{
														loadingNumber ? <ClipLoader color={"#fff"} loading={true} size={20} /> : 'Verificar'
													}


												</button>
												<button className='btn-cancel'
													onClick={() => {
														goHome();
													}}
												>Cancelar</button>
											</div>
										</form>
									</div>
								</div>
							</GetCodeModal>
						)
					}
				</div>
			</div>
			{
				dontHaveAccount && (
					<ErrorModal>
						<div>
							<h1>Não tem uma Conta no Aplicativo ToquePlay</h1>
							<h3>Baixe aplicação na Google Play ou na App Store</h3>
							<button onClick={() => { goHome() }}>Voltar</button>
						</div>
					</ErrorModal>
				)
			}

			{
				loadingLogin&&(
					<LodingLogin>
						<div>
							<h2>Processando <ClipLoader color={"#000"} loading={true} size={20} /> </h2>
						</div>
					</LodingLogin>
				)
			}
			

		</Container>
	)
}

const GetCodeModal = styled.div`
	position: fixed;   
	z-index: 100;   
	width: 100%;   
	height: 100vh;   
	left: 0;   
	top: 0;   
	right: 1em;   
	background: rgba(0, 0, 0, 0.90)!important;    
	pointer-events: all;   
	display: flex;   
	justify-content:center;   
	align-items:center; 
	
	
	
	div{
		width:500px;
		display: flex;   
		align-items:center;    
		flex-direction:column;  
		background:#fff;
		padding-bottom:0.5em;
	
	}

	@media (max-width: 768px) {
		div{
			width:96%;
			margin: 0 auto;
		}		
	}
	.verify-code-field{
		border:1px solid #ddd;
		p{
			color:#000;
			margin-top:1em;
			font-size:1.3em;
		}
		input{
			padding: 10px 5px;
			width:80%;
			margin: 0 auto;
			border:none;
			color: #fff;
			border:1px solid #000;
			background:#000;
			
		}
		
		button{
			padding: 8px 15px;
			border:none;
			color: #fff;
			background: #890F0D ;
			margin-top:1em;
			font-size:1.3em;
			&:active{
					transform:translateY(5px)
			}
		}

		.btn-options{
			display: flex;   
			align-items:center;    
			flex-direction:row;  
			justify-content:center;  
			margin-top:1em; 
			button{
				margin:0 1em;
			}
			.btn-cancel{
				color: #fff;
				background: #000 ;	
			}
		}
	}
	
`;


const ErrorModal = styled.div`
	position: fixed;   
	z-index: 100;   
	width: 100%;   
	height: 100vh;   
	left: 0;   
	top: 0;   
	right: 1em;   
	background: rgba(0, 0, 0, 0.90)!important;    
	pointer-events: all;   
	display: flex;   
	justify-content:center;   
	align-items:center;  
	div{   
		width:500px;
		height:230px;
		display: flex;   
		justify-content:center;   
		align-items:center;    
		flex-direction:column;  
		border-radius: 8px;
		background:#fff;       
		padding:1.5em; 
		color: #890F0D ; 
		h1{
			font-size:1.4em;
			color:#333;
			margin-bottom:0.5em;
		}
		h3{
			font-size:1em;
			color:black;
			padding:7px;
			font-weight:400;
			color:#000;
			&:active{
					transform:translateY(5px)
				}
		}
		button{
			padding: 6px 12px;
			border:none;
			color: #fff;
			background: #890F0D ;
			margin-top:1em;
			font-size:1.3em;
			&:active{
					transform:translateY(5px)
				}
		}
	} ;
`;


const LodingLogin = styled.div`
	position: fixed;   
	z-index: 100;   
	width: 100%;   
	height: 100vh;   
	left: 0;   
	top: 0;   
	right: 1em;   
	background: rgba(0, 0, 0, 0.90)!important;    
	pointer-events: all;   
	display: flex;   
	justify-content:center;   
	align-items:center;  
	div{   
		width:300px;
		height:50px;
		display: flex;   
		justify-content:center;   
		align-items:center;    
		flex-direction:column;  
		background:#fff;       
		padding:1.5em; 
		h2{
			color:#000;
			font-weight:400;
		}
	} ;
`;


export default Login;