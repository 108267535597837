import React from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import Styled from 'styled-components';


const LogoutMenu = (): JSX.Element => {
    const history = useHistory();

	const goHome = () => {
		history.push('/')
	};

    const handleLogout = () => {
        goHome();
        localStorage.removeItem('userToquen');
        window.location.reload();
    };

    return (
        <Router>
            <LogoutMenuStyles>
                <div onClick={handleLogout}>
                    <span>Terminar Sessão</span>
                </div>
            </LogoutMenuStyles>
        </Router>
    );
};

const LogoutMenuStyles = Styled.div`
    position: absolute;
    bottom: 1rem;
    width:100%;
    padding: 0.5rem 2rem;
    cursor: pointer;
    &:hover {color: #ff2a00;background: #222;}
    color: #fff;
    font-weight: 400;
    display: flex;
    align-items: left;
    &:active{
         transform: translateY(5px);         
    }
   
    background: #444;
`;

export default LogoutMenu;
