import React, { useState } from 'react';
import { ContainerAbout } from '../../styles/pages/about/aboutStyles';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import ToquePlayBrownLogo from '../../assets/BrownLogo.png'

import LeftAsideImageHeader from '../../assets/Left-side-image.png'
import RightAsideImageHeader from '../../assets/Right-side-image.png'

import HalfLoveDisc from '../../assets/Half love disc.png'
import Songs from '../../assets/Songs.png'
import iconIphone from '../../assets/ios-store.svg';
import iconAndroid from '../../assets/android-store.svg';
import imgYolaSemedo from '../../assets/artistas_pagina_about/01-Yola semedo.webp'
import imgTelmaLee from '../../assets/artistas_pagina_about/02-telma-lee.jpg'
import imgYolaAraujo from '../../assets/artistas_pagina_about/03-Yola Araujo.jpg'
import imgPongo from '../../assets/artistas_pagina_about/04-Pongo.jpg'
import imgVannize from '../../assets/artistas_pagina_about/05-Vannize.jpg'
import imgEdmazia from '../../assets/artistas_pagina_about/06-Edmazia.jpg'
import imgBonga from '../../assets/artistas_pagina_about/07-Bonga.jpg'
import imgMcCabinda from '../../assets/artistas_pagina_about/08-mc-cabinda.jpg'

import imgProdigio from '../../assets/artistas_pagina_about/09-Prodigio.webp'
import imgDjiTafinha from '../../assets/artistas_pagina_about/10-dji-tafinha.jpg'
import imgBebuchoQCuia from '../../assets/artistas_pagina_about/11-Bebucho Q Cuia.jpg'
import imgGerilsonInsrael from '../../assets/artistas_pagina_about/12-gerilson.jpg'
import imgEdsonDosAnjos from '../../assets/artistas_pagina_about/13-edson-dos-anjos.jpg'
import imgDJLutonda from '../../assets/artistas_pagina_about/14-dj-lutonda.jpg'
import imgNGA from '../../assets/artistas_pagina_about/15-Kizua Gourgel.jpg'

import imgNumber1 from '../../assets/1.png'
import imgSection1 from '../../assets/3425171.jpg'

import imgNumber2 from '../../assets/2.png'
import imgSection2 from '../../assets/lio-francis.png'

import imgNumber3 from '../../assets/3.png'
import imgSection3 from '../../assets/ToqueVerso-edit.png'
import { AiOutlineCheck } from 'react-icons/ai';



const About: React.FC = () => {
	const [showMenu, setShowMenu] = useState<boolean>(false);
	return (
		<div className="main-container">
			<ContainerAbout>
				<header className="header" >
					<div className="nav-bar">
						<NavBar showMenu={showMenu} setShowMenu={setShowMenu} />
					</div>
					<div className="bainner" >

						<img src={ToquePlayBrownLogo} className="toqueplay-brown-logo" alt="Logotipo da ToquePlay com cor castanha" />

						<section className="section-container-bainner">
							<img src={LeftAsideImageHeader} className="left-aside-image-header" alt="Imagem do lado esquerdo" />

							<p className="bainner-text" > “Obtenha a melhor  <br /> experiência musical de sempre"</p>
							<img src={RightAsideImageHeader}  alt="Right imageg asider" className='right-image-aside-header' />

						</section>

					</div>


					<div className="about-app-container" >

						<h1 className='about-app-title-text'> Sobre a aplicação </h1>
						<img src={HalfLoveDisc} alt="Imagem da metade de um disco com coração" className='half-love-disc-img' />

						<div className="about-app-p-container" >
							<p className='about-app-content-text'>Toqueplay é uma plataforma de streaming e vendas de músicas que aproxima os fãs dos artistas através de experiências únicas e da mais alta qualidade do som.</p>
							<p className='about-app-content-text'>É uma plataforma angolana de venda e streaming de ficheiros
								multimídia,que permite aos artistas e criadores de conteúdos, disponibilizarem os seus trabalhos para o
								público, e serem recompensados.</p>

							<p className='about-app-content-text'>É uma marca da empresa <strong>Toque Media, Lda </strong>, que se posiciona como uma ponte
								para a dinamização da cultura nacional, de formas a dar mais poder aos artistas por via de uma
								aplicação adaptada as exigências do nosso mercado.</p>

						</div>

						<div className="footer-store-head">
							<div className="footer-containner">

								<div className="store-icon icon-android">
									<a href="https://play.google.com/store/apps/details?id=com.toquemedia.toqueplay" rel="noreferrer" target='_blank'>
										<img src={iconAndroid} alt="Google Store" />
									</a>
								</div>

								<div className="store-icon icon-apple">
									<a target='_blank' rel="noreferrer" href="https://apps.apple.com/us/app/toqueplay/id1573165403">
										<img src={iconIphone} alt="Apple Store" />
									</a>
								</div>


							</div>
						</div>

						<img src={Songs} alt="Imagem de símbolo de música" className='songs-img' />
						
					</div>
				</header>


				<section className='container-artists-img'>
					<img className="artists-img-item" src={imgYolaSemedo} alt="Imagem da cantora Yola Semedo" />
					<img className="artists-img-item" src={imgTelmaLee} alt="Imagem da cantora Telma Lee" />
					<img className="artists-img-item" src={imgYolaAraujo} alt="Imagem da cantora Yola Araújo" />
					<img className="artists-img-item" src={imgPongo} alt="Imagem da cantora Pongo " />
					<img className="artists-img-item" src={imgVannize} alt="Imagem da cantora Vannize" />
					<img className="artists-img-item" src={imgEdmazia} alt="Imagem da cantora Edmázia" />
					<img className="artists-img-item" src={imgBonga} alt="Imagem do cantor Bonga" />
					<img className="artists-img-item" src={imgMcCabinda} alt="Imagem do cantor MC Cabinda" />
					<img className="artists-img-item" src={imgProdigio} alt="Imagem do cantor Prodígio" />
					<img className="artists-img-item" src={imgDjiTafinha} alt="Imagem do cantor Dji Tafinha" />
					<img className="artists-img-item" src={imgBebuchoQCuia} alt="Imagem do cantor Bebucho Q kuia" />
					<img className="artists-img-item" src={imgGerilsonInsrael} alt="Imagem do cantor Gerilson Insrael" />
					<img className="artists-img-item" src={imgEdsonDosAnjos} alt="Imagem do cantor Edson dos Anjos da TRX" />
					<img className="artists-img-item" src={imgDJLutonda} alt="Imagem do DJ Lutonda" />
					<img className="artists-img-item" src={imgNGA} alt="Imagem do cantor NGA" />
					<div className="overlap-div"></div>
					<p className='overlap-div-text'>“Aplicação feita por angolanos, para angolanos!”</p>
				</section>

				<section>

					<section className='section-1'>
						<section className="text-aside-container">
							<img className="text-aside--img" src={imgNumber1} alt='bla vla' />
							<h1 className="text-aside--text">A qualidade do som <br /> que te envolve</h1>
							<p className="text-aside--content-text">Música é vida e é por isso que trazemos mais <br />  qualidade para a sua vida.
								Somos o canal entre <br /> a música e os momentos proporcionados por ela.</p>
						</section>

						<section className='img-aside-container'>
							<img src={imgSection1} className="img-aside--img" alt="People dancing" />
						</section>
					</section>

					<section className='section-2'>

						<section className='img-aside-container-especial'>
							<img src={imgSection2} className="img-aside--img--especial" alt="People dancing" />
							<div className="nome-artista">Lio Francis</div>
						</section>


						<section className="text-aside-container-especial">
							<img className="text-aside--img--especial" src={imgNumber2} alt='bla vla' />

							<h1 className="text-aside--text--especial">Compromisso com <br /> a arte</h1>
							<p className="text-aside--content-text--especial">Damos poder aos artistas para transmitir a sua arte</p>
						</section>
					</section>

					<section className='section-3'>
						<section className="text-aside-container text-aside--section3">
							<img className="text-aside--img" src={imgNumber3} alt='bla vla' />
							<h1 className="text-aside--text"> O ToqueVerso <br /> sonoro disponível para ti</h1>
							<p className="text-aside--content-text"> Colaborações únicas com a comunidade artística,<br /> permite-nos
								oferecer aos fãs de música <br /> experiências únicas mais qualidade para a sua vida.</p>
						</section>

						<section className='img-aside-container'>
							<img src={imgSection3} className="img-aside--img" alt="People dancing" />
						</section>
					</section>

				</section>

				<section className='section-slogan'>
					<p className='section-slogan-text'>Sinta as combinações dos sons e deixe <br /> o Toqueplay dar mais vida a sua vida!</p>
				</section>

				<section className="section-plans">
					<div className="section-plans-item item-1">
						<div className="section-plans-item--head">
							<p>Freemium</p>
						</div>
						<div className="section-plans-item--body body-1">
							<ul>
								<div>
									<li className="span"><span><AiOutlineCheck /></span> <span className="span">+ de 1 milhão de músicas</span> </li>

									<li className="span"><span><AiOutlineCheck /></span> <span className="span">Músicas com qualidade básica  </span> </li>

									<li className="span"><span><AiOutlineCheck /></span> <span className="span">Pausas para anúncios</span> </li>
									<li className="span"><span><AiOutlineCheck /></span> <span className="span">Apenas através de streaming</span> </li>
									<li className="span"><span><AiOutlineCheck /></span> <span className="span">Criação de playlists ilimitadas</span> </li>
								</div>
							</ul>

						</div>
					</div>

					<div className="section-plans-item item-2">
						<div className="section-plans-item--head">
							<p>Premium</p>
						</div>
						<div className="section-plans-item--body">
							<ul>
								<div>
									<li className="span"><span><AiOutlineCheck /></span> <span className="span">+ de 1 milhão de músicas</span> </li>
									<li className="span"><span><AiOutlineCheck /></span> <span className="span">Som de alta qualidade</span> </li>
									<li className="span"><span><AiOutlineCheck /></span> <span className="span">Sem Pausas para anúncios</span> </li>
									<li className="span"><span><AiOutlineCheck /></span> <span className="span">Acesso ao ToquePlay X</span> </li>
									<li className="span"><span><AiOutlineCheck /></span> <span className="span">Controle total do Player </span> </li>
									<li className="span"><span><AiOutlineCheck /></span> <span className="span">Acesso as músicas a venda</span> </li>
									<li className="span"><span><AiOutlineCheck /></span> <span className="span">Criação de playlists ilimitadas</span> </li>
								</div>
							</ul>
						</div>
					</div>
				</section>


				<Footer />

			</ContainerAbout >
		</div>
	)
}
export default About;
