import React, { useState } from 'react';
import { FullContainer } from '../styles/pages/eulaStyles'
import { Container } from '../styles/pages/responsive/eulaStyles';
import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import under_construction from "../assets/under_construction_46pa.svg";

const Building = () => {
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const onShowMenu = (value: boolean) => {
		setShowMenu(!showMenu);
	}
	return (
		<FullContainer>
			<Container>
				<div className="main-container">
					<header className="header" >
						<div className="nav-bar">
							<NavBar status={true} showMenu={showMenu} setShowMenu={setShowMenu} />
						</div>
					</header>
					{showMenu ?
						<MenuBar showMenu={showMenu} onShowMenu={onShowMenu} /> : ''}
					<main id="main_eula">
						<div className="build-container">
							<h2>Estamos a compôr esta partitura!</h2>
							<div className="image">
								<img src={under_construction} alt="not find" />
							</div>
						</div>
					</main>

					<Footer />
				</div>
			</Container>
		</FullContainer >


	)
}

export default Building;

