import styled from 'styled-components'
//import bainner_header from '../../assets/bainner.jpg';
export const FullContainer = styled.div`
   .main-container{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 10vh auto auto;
    grid-template-areas: "header header header" 
    "main main main" 
    "footer footer footer";
    position: relative;                 
   }
   .header{
      grid-area: header;      
      background-position:center;
      background-size:cover;
      background-repeat:no-repeat;
      height: 10vh;
      width: 100%;
      z-index: 0;
      position: relative;
	  background-color: #000;
      .nav-bar{
         background-color: #000;
         width: 100vw;
      }
      }
   /***********************************MAIN STYLES */
  
   #main_eula{
       height: auto;
       width: 100vw;
       background-color: #fff;
   }
   .eula-content{
	   color: #000;
	   padding: 30px 60px;
	   h1,h2{
		   color: #555;
		   font-size: 30pt;
		   padding: 10px 0px;
	   }
	   h3{
		   color: #555;
		   font-size: 20pt;
		   padding: 10px 0px;
	   }
	   p{
		   text-align: justify;
		   font-size:12pt;
	   }
   }
    
  `


