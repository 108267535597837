import React, { InputHTMLAttributes, useRef } from "react";
//import { IconBaseProps } from "react-icons";
import { Container } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  editeArtist?:boolean;
  onEditeArtist?: (newValue: boolean) => void;
  //icon?: React.ComponentType<IconBaseProps>;
}
  const Input: React.FC<InputProps> = ({ onEditeArtist ,editeArtist, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Container aria-disabled={true}>
      {/* {<Icon size={20} />} */}
      <input ref={inputRef} {...rest}/>
      {editeArtist? <span className="edit" onClick={() =>{
        if(onEditeArtist){onEditeArtist(!editeArtist);};
}}>x</span>:""}
 
    </Container>
  );
};

export default Input;