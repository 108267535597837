import React from 'react';
import Styled from 'styled-components';
import { FaHome, FaCreditCard } from 'react-icons/fa';


const Menu = (): JSX.Element => {
    return (
        <MenuItemStyles>
            <ul>
                <li className='profile_active'>
                    <a href='/dashboard/profile'>
                        <FaHome />
                        <span>Perfil</span>
                    </a>
                </li>
                <li className='payment_plans_active'>
                    <a href='/dashboard/paymentplans'>
                        <FaCreditCard />
                        <span>Pagamentos</span>
                    </a>
                </li>
            </ul>
        </MenuItemStyles>
    );
};

const MenuItemStyles = Styled.div`
    margin-top: 4rem;
    
    ul {
        list-style: none;
        li {
            padding: 0.8rem 2rem;
            cursor: pointer;
            &:hover {
                background: #222;
            }

            &:hover a {
                color: #ff2a00;
            }

            a {
                color: #fff;
                font-weight: 700;
                text-decoration:none;
                display: flex;
                align-items: center;

                span {
                    margin-left: 0.5rem;
                }
            }
        }
    }
`;

export default Menu;
