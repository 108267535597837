import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IndividualPlan from './IndividualPlan';
import CurrentUserPlan from './CurrentUserPlan';
import {PlanType, UserType } from '../../../types';
import noAdsImg from '../../../assets/sem_ads.png';
import skipImg from '../../../assets/skip.png';
import playAllMusicsImg from '../../../assets/tocar_musicas.png';
import downlongMusicsImg from '../../../assets/baixar_musicas.png';
import { getAllPlans, getCurrentUserPlan } from '../../../services/server';
import { firestore } from '../../../firebase/config';

const AllPlans = (): JSX.Element => {
    /*const [loadingPlan, setLoadingPlan] = useState<boolean>(false);
    const [loadingModal, setLoadingModal] = useState(true);*/
    const [daysLeft, setDaysLeft] = useState<number>(0);
    const [allPlans, setAllPlans] = useState<PlanType[]>([]);

    const currentPlan = JSON.parse(localStorage.getItem('currentPlan') as string); 

    const user = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;
	/*
    useEffect(() => {

        const currentPlan = JSON.parse(localStorage.getItem('currentPlan') as string);

        if (currentPlan !== null && currentPlan.user === user.phoneNumber) {
            setLoadingPlan(true)
            setLoadingModal(false);
        } else {
            setLoadingPlan(false);
            setLoadingModal(false);
        }

    }, [user.phoneNumber]);*/

   useEffect(() => {

	let pos: number;
	let planAction: PlanType;
	const userPlanActive: PlanType[] = [];
	const query =	getCurrentUserPlan(user.phoneNumber as string);
	const unsubscribe = firestore.onSnapshot(query, async (querySnapshot) => {

		if (!querySnapshot.empty) {
			//setLoadingPlan(true);
           // setLoadingModal(false);

			querySnapshot.docChanges().forEach(change => {
				const data = change.doc.data() as PlanType;
				data.id = change.doc.id;
				let p;
				switch (change.type) {
					case 'added':
						userPlanActive.push(data);
						break;
					case 'modified':
						planAction = change.doc.data() as PlanType;
						planAction.id = change.doc.id;
						pos = userPlanActive.findIndex((value) => value.id === planAction.id);

						if (pos === -1) {
							userPlanActive.push(planAction);
						} else {
							userPlanActive.splice(pos, 1);
							userPlanActive.splice(pos, 0, planAction);
						}
						break;
					case 'removed':
						p = userPlanActive.findIndex(value => value.id === data.id);
						userPlanActive.splice(p, 1);
						break;
				}
			});
			setDaysLeft(userPlanActive[0].expiration.seconds as number);
			localStorage.setItem('currentPlan', JSON.stringify({
				plan: 'premiunPlan',
				user: user.phoneNumber
			}));
		} else {
			//setLoadingPlan(false);
            //setLoadingModal(false);
		}

	});
	return () => {
		unsubscribe();
	};
	
    }, [user.phoneNumber]);

	useEffect(() => {

		let pos: number;
		let planAction: PlanType;
		const allPlansArr: PlanType[] = [];
		const query = getAllPlans ();
		const unsubscribe = firestore.onSnapshot(query, async (querySnapshot) => {

			if (!querySnapshot.empty) {
				//setLoadingModal(false);
				querySnapshot.docChanges().forEach(change => {
					const data = change.doc.data() as PlanType;
					data.id = change.doc.id;
					let p;
					switch (change.type) {
						case 'added':
							allPlansArr.push(data);
							break;
						case 'modified':
							planAction = change.doc.data() as PlanType;
							planAction.id = change.doc.id;
							pos = allPlansArr.findIndex((value) => value.id === planAction.id);

							if (pos === -1) {
								allPlansArr.push(planAction);
							} else {
								allPlansArr.splice(pos, 1);
								allPlansArr.splice(pos, 0, planAction);
							}
							break;
						case 'removed':
							p = allPlansArr.findIndex(value => value.id === data.id);
							allPlansArr.splice(p, 1);
							break;
					}
				});
				setAllPlans(allPlansArr);
			} else {
				//setLoadingPlan(false);
			}

		});
		return () => {
			unsubscribe();
		};

	}, []);


    return (
        <>
            <AsideLeft></AsideLeft>
            <Container>

                {
					
                    currentPlan ?
                        <CurrentUserPlan daysLeft={daysLeft} />
                        :
                        <><div className='payment--header'>
                            <h1>Por que Premium?</h1>
                            <div className='payment-info'>
                                {/* item 1 */}
                                <div className='payment-card-info'>
                                    <div className='card-icon'>
                                        <img src={downlongMusicsImg} alt='playAllMusicsImg' height={140} width={140} />
                                    </div>
                                    <div className='card-label'>
                                        <ul>
                                            <li className='title'>Baixar músicas.</li>
                                            <li>Baixe as suas playlists para ouvir sem internet.</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* item 2 */}
                                <div className='payment-card-info'>
                                    <div className='card-icon'>
                                        <img src={noAdsImg} alt='sem_ads' height={140} width={140} />
                                    </div>
                                    <div className='card-label'>
                                        <ul>
                                            <li className='title'>Sem interrupções de anúncios</li>
                                            <li>Desfrute de música sem parar.</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* item  3*/}
                                <div className='payment-card-info'>
                                    <div className='card-icon'>
                                        <img src={skipImg} alt='skipImg' height={140} width={140} />
                                    </div>
                                    <div className='card-label'>
                                        <ul>
                                            <li className='title'>Você no comando</li>
                                            <li>Avance, recue, repita, repita e repita quantas vezes você quiser.</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* item 4 */}
                                <div className='payment-card-info'>
                                    <div className='card-icon'>
                                        <img src={playAllMusicsImg} alt='playAllMusicsImg' height={140} width={140} />
                                    </div>
                                    <div className='card-label'>
                                        <ul>
                                            <li className='title'>Acesso sem restrições</li>
                                            <li className='label'>Álbuns a venda nunca será uma barreira para ouvires a música completa.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <AllPaymentplans>
								{
									allPlans.map((value, index)=> (
										<IndividualPlan key={index} planData={value}/>
									))
								}
                               
                            </AllPaymentplans></>


                }

				{
					/**
					 *  loadingModal && !loadingPlan?
                        <LoagingInfo>
                            <div>Loading...</div>
                        </LoagingInfo>
                    :
					 */
				}

               



            </Container>
        </>

    );
};

/*
const LoagingInfo = styled.div`
	position: fixed;   
	z-index: 100;   
	width: 100%;   
	height: 100vh;   
	left: 0;   
	top: 0;   
	right: 1em;   
	background: rgba(0, 0, 0, 0.95)!important;    
	pointer-events: all;   
	display: flex;   
	justify-content:center;   
	align-items:center;   
	div{       
		background:#fff;       
		color: #000 ; 
		font-size: 2em;
		padding:0.5em;
	} ;
`;*/

const AsideLeft = styled.div`
 width: 15%;
 	@media (max-width: 768px) {
		width: 0;
	}

`;
const Container = styled.div`
    width: 85%;
    background-color: #fff;
	
    h1{
        font-size: 3em;
        color: #000;
        text-align:center;
        font-weight: bold;
        margin: 1rem 0;
    };
    .payment-info{
        display: flex;
        justify-content: center;
        margin-top: 1em;
        background-color: #eee;

    }
    .payment-card-info{
        text-align: center;
        margin:2rem;
        width: 20%;
       
        .card-icon{
            /* background-color: #fa1200; */
            /* border: 1px solid  #fff; */
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 150px;
            border-radius: 50%;
            margin: 0 auto;
            margin-bottom:2rem;
        };
        .card-label{
          
            li{
                list-style:none; 
                font-size:1.1em;
                color:#444
            }
            .title{
                font-weight: bold;
                font-size:1.2em;
                margin-bottom:10px;
                color: #000;
            }
        }
    }
	@media (max-width: 768px) {
		width: 100%;
			.payment-info{
				display: block;
				margin-bottom: 2em;
				padding: 1em;

    		}
			.payment-card-info{
				text-align: center;
				width: 100%;
				border-bottom: 10px solid #fff;
				margin: 0 auto;

			}
	}

`;
const AllPaymentplans = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 1em;
    .Plan{
        width: 25%;
        box-shadow: 0 0 2px #666;
        margin: 1em;
        border-radius: 5px;
        position: relative;
        margin-bottom: 9rem;
        .plan--header{
            padding-bottom: 1.5em;
            margin-bottom: 1em;
            h2{
                font-size:2em;
                font-weight:bold;
                text-align:center;
                color:#444;
                background:#ddd;
                letter-spacing:1px;
            }
        }
        .plan--header-info li{
            margin-top:0.5rem;
            color: #000;
            font-weight: bold;
            font-size: 1.3em;
            list-style:none;
            padding-left:10px;
            text-align:center;
        }
        .plan-list-details{
            padding-bottom:2em;
            /* height: 25rem; */
        }
        .plan-list-details li{
            list-style:none;
            font-size:1.1em ;
            display: flex;
            margin:1em;
            border-bottom:1px solid #ddd;

            .text-info{
                font-size: 1em;
                color:#666;
                font-weight:400;
            }
        }
        .button-and-terms{
            width: 100%;
            display: flex;
            justify-content: center; 
            align-items: center; 
            flex-direction: column;
            button{
                    display: inline-block;
                    padding: .3em 3em;
                    color: white;
                    margin-bottom: 0.5em;
                    font-size: 1.2em;
                    font-weight: 400;
                    text-transform: uppercase;
                    vertical-align: middle;
                    
                    border: 1px solid transparent;
                    cursor: pointer;
                    background-color:#fa1200;
                    border-radius: 5px;
                    
                    &:hover {
                        background: #777;
                    }

                    &:active {
                       transform:translateY(5px)
                    }

            }
             .link-terms-apply span{
                margin-left:5px;
                color:#000;
            }
            .link-terms-apply a{
                text-decoration:none;
                color:#000;
                &:hover{
                    color:#555;
                }
            }
            .btn-link-plan{
                text-align:center;
            }
        }
    }

	@media (max-width: 768px) {
		padding-left:1em;
		flex-direction: column;
		margin-bottom: 5em;

		.Plan{
			width: 95%;
			margin: 0 auto;
			margin-bottom: 2em;
			.plan--header{
            h2{
                font-size:2em;
                font-weight:400;
            }
			
		}
		}
		
		
	}

    
`;

export default AllPlans;