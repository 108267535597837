import styled from "styled-components";

export const Container = styled.nav`
   
     .full-menu {
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		height: 80px;
		display:flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items:center;
		margin-top: 10px;
		padding: 20px 50px;
		z-index: 9999;

       .logo{ width: 180px;
           	img{ width:100%; }  
		}

       .btn-create{
		   display: flex;
		   min-width: 350px;
		   justify-content: center;
		   align-items: center;
		   .currentUser{
			svg{
				border: 1px solid #999;
				border-radius: 50%;
			}
		   }

		   .btn-access{ display: none; }
		   
           a {
				border:none;
				outline: none;
				background-color: transparent;
				cursor: pointer;
				font-size: 1.2em;
				border-radius:5px;
				padding: 10px;
				color: #f5f5f5;
				:hover{ color: #ff2a00; }
           }

		    .user-container {
			  	min-width: 150px;
			   	display: flex;
				align-items: center;
				justify-content: center;

				p {
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
				}

				svg {
					font-size:25px;
					margin: 0 2px;
				}
				
				span {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 5px;
				}

			   button {
				   	border: 0;
					background: transparent;
					margin-left: 5px;
				:hover{
               		color: #ff2a00;
            	 }
			   }
		   } 
         }
     } 
     .mobile-menu{display:none}
	 
     @media (max-width: 600px) {
			width: 100%;

		.full-menu {
			display: none;
		};
		
     .mobile-menu {
        width: 100%;
		display: flex;
		padding: 10px 20px;
		justify-content: space-between;
		
		.logo{
			img {
				width: 150px;
			}
		}


     }

}
`