import { useState } from 'react';
import { FullContainer } from '../styles/pages/termsStyles'
import { Container } from '../styles/pages/responsive/termsStyles'

import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

const Terms = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const onShowMenu = (value: boolean) => {
    setShowMenu(!showMenu);
  }
  return (
    <FullContainer>
      <Container>
        <div className="main-container">
          <header className="header" >
            <div className="nav-bar">
              <NavBar showMenu={showMenu} setShowMenu={setShowMenu} />
            </div>
          </header>
          {showMenu ?
            <MenuBar showMenu={showMenu} onShowMenu={onShowMenu} /> : ''}
          <main id="main_terms">
			  <div className="terms-content">
                <h2>Privacidade e Termos de Uso</h2>
                <h3>Política Privacidade</h3>
                <p>
                {
                    `A sua privacidade é importante para nós. É política do ToquePlay respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site ToquePlay, e outros sites que possuímos e operamos.\n
Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.\n
Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.\n
Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.\n
O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.\n
Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.\n
O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.
`}
                </p>
                <h3>Políticas de Cookies ToquePlay</h3>
                <p>O que são Cookies?</p>
                <p>
                {`Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da funcionalidade do site.`}
                </p>
                <p>Como usamos os Cookies?</p>
                <p>
                {`Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados ​​para fornecer um serviço que você usa.`}
                </p>
                <p>Cookies que definimos</p>
                <p>1. Relacionados à conta</p>
                <p>
                {`Se você criar uma conta connosco, usaremos cookies para o gerenciamento do processo de inscrição e administração geral. Esses cookies geralmente serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair.`}
                </p>
                <p>2. Relacionados ao login</p>
                <p>
                {`Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.`}
                </p>
                <p>3. Relacionados a pesquisa</p>
                <p>
                {`Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.
                `}
                </p>
                
                <h3>1. Termos</h3>
                <p>
                {`Ao acessar ao site ToquePlay, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.`}
                </p>
                <h3>2. Uso de Licença</h3>
                <p>
                {`É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site ToquePlay , apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode: 

1. modificar ou copiar os materiais; \n
2. usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial); \n
3. tentar descompilar ou fazer engenharia reversa de qualquer software contido no site ToquePlay; \n
4. remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou \n
5. transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.\n
Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por ToquePlay a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou impresso.
`}
                </p>
                <h3>3. Isenção de responsabilidade</h3>
                <p>
                {`1. Os materiais no site da ToquePlay são fornecidos 'como estão'. ToquePlay não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.\n
2. Além disso, o ToquePlay não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ​​ou à confiabilidade do uso dos materiais em seu site ou de outra forma relacionado a esses materiais ou em sites vinculados a este site.
                `}
                </p>
                <h3>4. Limitações</h3>
                <p>
                {`Em nenhum caso o ToquePlay ou seus fornecedores serão responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em ToquePlay, mesmo que ToquePlay ou um representante autorizado da ToquePlay tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos conseqüentes ou incidentais, essas limitações podem não se aplicar a você.`}
                </p>
                <h3>5. Precisão dos materiais</h3>
                <p>
                {`Os materiais exibidos no site da ToquePlay podem incluir erros técnicos, tipográficos ou fotográficos. ToquePlay não garante que qualquer material em seu site seja preciso, completo ou atual. ToquePlay pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto, ToquePlay não se compromete a atualizar os materiais.`}
                </p>
                <h3>Modificações</h3>
                <p>
                {`O ToquePlay pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atual desses termos de serviço.`}
                </p>
                <h3>Lei aplicável</h3>
                <p>
                {`Estes termos e condições são regidos e interpretados de acordo com as leis do ToquePlay e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade.
                `}
                </p>
				</div>
          </main>

          <Footer />
        </div>
      </Container>
    </FullContainer >


  )
}

export default Terms;

