import { Link } from 'react-router-dom';
//import ToquePlayLogo from '../../assets/logotipo.svg';
//import { FiMenu } from 'react-icons/fi'
import { Container } from './styles';
import { LogOut } from '../../services/server';
//import { FaUser } from 'react-icons/fa';

import { UserType } from '../../types';
import { FaUser } from 'react-icons/fa';

interface Props {
	showMenu: boolean;
	status?: boolean;
	setShowMenu: (value: boolean) => void;
	currentUser?: UserType;
}

const NavBar: React.FC<Props> = (props: Props) => {
	const { status, /*showMenu, setShowMenu,*/ currentUser } = props;
	const user = currentUser as UserType;

	return (
		<Container>
			<div className="full-menu">
				<div className="logo">
					<Link to="/"><img src={"/logotipo.svg"} alt="logo-mobile-toqueplay" /></Link>
				</div>
				<div className="btn-create">
					<a href="/artist/home">{status ? "" : "Artistas"}</a>
					<a href="/store" >{status ? "" : "Praça"}</a>
					<a href="/about" >{status ? "" : "Sobre o ToquePlay"}</a>
					{
						user ?
							<div className='user-container'
								style={{
									display: "flex", alignItems: "center",
									minWidth: "150px"
								}}
							>
								<div >
									<a href='/dashboard/profile' target="_blank">
										
										{
											user?.displayName !== null ? user?.displayName :
												user.displayName
										}
										{/* <FaUser size={20} /> */}
									</a>
								</div>
								<div className='currentUser'><FaUser size={40} /></div>
								
								<span style={{ margin: "0 10px" }}>|</span>
								<button
									onClick={() => {
										LogOut();
										window.location.reload();
										localStorage.removeItem('userToquen');
									}} >Sair</button>
							</div> :
							<a href="/login">{status ? "" : "Login"}</a>
					}
					<div className="btn-access">
						<a target='_blank' rel='noreferrer' href="https://artists.toqueplay.com/">Acessar Conta</a>
					</div>
				</div>
			</div>

			<div className="mobile-menu">
				<div className="logo">
					<Link to="/"><img src={"/logotipo.svg"} alt="logo-mobile-toqueplay" /></Link>
				</div>
				{/* <div className="btn-create" onClick={() => setShowMenu(!showMenu)} style={{ display: status ? "none" : "flex" }}>
					<span>
						<FiMenu size={25} className="noHover" />
					</span>
				</div> */}
			</div>
		</Container>

	);
}

export default NavBar;
