import { getApp, initializeApp } from 'firebase/app';
import { 
    Timestamp, 
    getFirestore, 
    connectFirestoreEmulator, 
    collection, 
    addDoc, 
    setDoc, 
    doc, 
    getDoc, 
    getDocs, 
    query, 
    collectionGroup, 
    onSnapshot, 
    where,
	startAt,
	endAt,
	orderBy,
	initializeFirestore
} from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { 
	getAuth, 
	signInWithPopup, 
	GoogleAuthProvider, 
	createUserWithEmailAndPassword, 
	signOut,
	RecaptchaVerifier,
	connectAuthEmulator,
	OAuthProvider
} from 'firebase/auth';
import { connectStorageEmulator, getStorage } from "@firebase/storage";

const app = initializeApp({
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
})

initializeFirestore(app, { ignoreUndefinedProperties: true })

export const dbFirestore = getFirestore();
export const functions = getFunctions(getApp());
export const authApp = getAuth();
export const storage = getStorage();

export const connectEmulatorFs = connectFirestoreEmulator;
export const connectEmulatorAuth = connectAuthEmulator;
export const connectEmulatorFn = connectFunctionsEmulator;
export const connectStorage = connectStorageEmulator;

export const firestore = {
	Timestamp,
	collection,
	addDoc,
	setDoc,
	doc,
	getDoc,
	getDocs,
	query,
	collectionGroup,
	onSnapshot,
	where,
	startAt,
	endAt,
	orderBy
	
};

// const app = initializeApp(firebaseConfig);

export const auth = {
	getAuth, 
	signInWithPopup, 
	GoogleAuthProvider,
	createUserWithEmailAndPassword,
	signOut,
	RecaptchaVerifier,
	OAuthProvider
};
