import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html{
	scroll-behavior: smooth;
  }
  :root {
  --main-color: #317EEB;
  --hover-color: #2764BA;
  --body-color: #141414;
  --box-color: #080c10;
  --letters: #fff;
  }
  body {

  font-family: 'Roboto', sans-serif;
  overflow-x:hidden;
  
  }

  body,
  input,
  textarea,
  button {
   font-family: 'Roboto', sans-serif;
  
    color: var(--letters)};

  h1 {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
  }

  h2 {
    font-size: 1.5rem;
  }

  button {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    color: var(--letters)
  }

 

  input {
    outline: none;
  }

  ul li{
    list-style: none;
  }
  a{
    text-decoration:none;
    color:#fff;
  }
  .modalContent,
  .secondModal {
    >img {
      width: 15rem;
      margin: 15px auto;
      display: flex;
      justify-content: center;
      align-self: center;
    }
    > form {
      margin: 20px 0;
      width: 100%;
      text-align: center;

    > label {
      display: flex;
      align-items: flex-start;
      margin: 15px 0;
      color: #1c1c1c;
    }

    >button {
      margin-top: 25px;
    }
  }
}

.secondModal {
  >h1 {
    text-align: center;
    margin: 4rem 0 0 0;
  }

  >span {
    display: block;
    text-align: center;
    color: #1c1c1c;
    font-size: 15px;
    margin: 20px 0;
  }
}

.build-container{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	padding: 100px;
	background-color: #000;

	@media (max-width:1024px){
		padding:100px;
		text-align: center;
	}
	@media (max-width:600px){
		padding: 50px;
	}

	h2{
		font-size: 2.5em;
		color: #fff;
		
		@media (max-width:600px){
			font-size: 2em;
			text-align: center;
		}
	}
	}
	.image{
		width: 40%;
		@media (max-width:1024px){
			width: 90%;
	}
		@media (max-width:600px){
			font-size: 2em;
			text-align: center;
			padding-top: 70px;
			width: 80%;
		}
		padding-top: 100px;
		img{
			width: 100%;

		}
	}


`;

export const Content = styled.div`
   max-width:1200px;
   width: 96%;
   margin: auto;
   display: flex;
   flex-direction: column;
 
`
