import { ProviderAccount, ProviderType, UserArtist } from "../types"
import { firestore, dbFirestore } from "../firebase/config";

export const createAccountProvider = (data: any) => {
	return new Promise(async (resolve, reject) => {
		const provider: ProviderAccount = {
			type: data.type,
			active: false,
			providerName: data.providerName,
			phoneNumber: data.phoneNumber,
			account: { email: data.email },
			representativeName: data.representativeName,
			requestDate: firestore.Timestamp.fromDate(new Date())
		}
			
		const uid = `${data.email.toLowerCase().replace(/[^a-zA-Z0-9.]/gm, '-')}`;

		try {
			const resp = await getProviderExiste(uid);
			if (resp === "exist") {
				resolve(2);
			} else {
				await firestore.setDoc(firestore.doc(dbFirestore, "providers", uid), provider);
				resolve(1);
			}
		} catch (e) {
			console.log(e);
			resolve(3);
			reject(e);
		}
	})
}
export const createAccountArtist = (data: UserArtist) => {

	return new Promise(async (resolve, reject) => {
		const artistData: UserArtist = {
			type: data.type,
			active: true,
			artist: data.artist,
			provider: { providerName: data.provider.providerName },
			phoneNumber: data.phoneNumber,
			requestAccount: { email: data.requestAccount?.email, requestDate: firestore.Timestamp.fromDate(new Date()) },
			fullname: data.fullname,
			singerGenres: data.singerGenres
		}
		const uid = `${artistData.artist
			.toLowerCase()
			.replace(/[^a-zA-Z0-9.]/gm, '-')}_document`;

		try {
			const artistRef = firestore.doc(dbFirestore, 'artists', uid);
			firestore.setDoc(artistRef, artistData, { merge: true });
			resolve(1);
		} catch (e) {
			reject(e);
		}
	})

}

export const listArtists = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const q = firestore.query(firestore.collection(dbFirestore, "artists"));
			const querySnapshot = await firestore.getDocs(q);
			let artist: any = [];
			querySnapshot.forEach((doc) => {
				artist.push({ 
					artist: doc.data().artist, 
					profile: doc.data().profilePhoto, 
					provider: doc.data().provider, 
					docId: doc.id });
			});
			resolve(artist);
		} catch (e) {

			reject(e);
		}
	})
}

export const getProviderExiste = async (id: string) => {
	const albumsRef = firestore.doc(dbFirestore, "providers", id);
	const docSnap = await firestore.getDoc(albumsRef);
	if (docSnap.exists()) {
		return "exist";
	} else {
		return "not found"
	}
}

export const dataContentProvider = async () => {

	const db = firestore.query(firestore.collection(dbFirestore, "providers"),
	firestore.where('active', '==', true));

	const querySnapshot = await firestore.getDocs(db);
	const providers: ProviderType[] = [];

	querySnapshot.forEach((doc) => {
		providers.push({
			active: doc.data().active,
			label: doc.data().providerName,
			value: doc.data().providerName,
			email: doc.data().account.email
		});

	});

	return providers
}

export const getArtistExiste = (data: any) => {
	return new Promise(async (resolve, reject) => {
		try {
			const albumsRef = firestore.collection(dbFirestore, "artists");
			const q = firestore.query(albumsRef, firestore.where("artist", "==", data.artist))
			const querySnapshot = await firestore.getDocs(q)
			querySnapshot.forEach((doc) => {
				if (doc.data().account) {
					resolve("existe");
				} else {
					resolve("continue");
				}
			});
		} catch (e) {

			reject(e);
		}
	})
}
