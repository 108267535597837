import React from 'react';
import Styled from 'styled-components';
import { UserType } from '../../types';
import Login from '../login-page/Login';
import AllPlans from './allPlans/AllPlans';
import Header from './Header';
import Sidebar from './SideBar';

const Paymentplans = (): JSX.Element => {
    const user = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;

    if(user === null){
        return(
            <Login />
        )
    }

    
    return (
        <DashboardStyles>
            <Header />
            <Wrapper>
                <Sidebar />
                <AllPlans/>
            </Wrapper>
        </DashboardStyles>
    );
};

const DashboardStyles = Styled.div`
    display: flex;
    flex-direction: column;
    /* background:#fff; */
  
`;
const Wrapper = Styled.div`
    display: flex;
    flex-direction: row;
    margin-top:5em;
    /* height:100vh; */
    .payment_plans_active{
        background: #222;
        a {
            color: #ff2a00;
        }
    }

    .profile_active{
        background: #000;
        a {
            color: #fff;
        }
    }
    
`;
export default Paymentplans;
