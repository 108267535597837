import styled from 'styled-components'
//import bainner_header from '../../assets/bainner.jpg';
export const Container = styled.div`
@media only screen and (max-height: 768px) {
	.body-bainner{
			.artist{
				width: 200px;
				min-width:200px;
				height:200px;
				clip-path: circle();
				img{
					width: 100%;
					height: 100%;
				}
				
			}
		}
	}
@media (max-width: 1100px) {
	.body-bainner{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex: none;
			position: relative;
			height: auto;
			margin-top:10%;
			margin-bottom: 30px;
			.header-wrapper{
				flex: none;
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				order: 2;
			}
			.descrition-bainner{
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				height: auto;
				h1{
					font-size: 50pt;
					
				}
				.btn-access{
					padding: 20px 0px;	
					display: flex;
					align-items: center;
					justify-content: center;
				}
				
			}
			.footer-go-home{
				display: none;
			}
				
			.artist-content{
				width: 100%;
				height:auto;
				display: flex;
				justify-content:space-between;
				flex-direction: row;
				
			}
			.artist-content:nth-child(3){
				order: 1;
				align-items: center;
				margin-top: 30px;
				}
				.artist-content:nth-child(2){

					order: 3;
					align-items: flex-end;
				}

				.first-child{
					margin-top: 0px;
				}
				.secand-child{
					margin-bottom: 0px;
				}

				.artist{
				z-index: 0;
				width: 160px;
				clip-path: circle();
				img{
					width: 100%;
					height: 100%;
				}
			}
			
		}
		.section-artist{
		
			.wrappper-content{
			height: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			order: 2;
			padding: 10px;
			flex: 1;
		h2{
			color: #000;
			font-size:35pt;
		}

		
			.card-pub{
				flex: 1;
				display: none;
			}
		}
}

@media (max-width: 768px) {
	.body-bainner{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex: none;
			position: relative;
			height: auto;
			margin-top:10%;
		
			.header-wrapper{
				flex: none;
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				order: 2;
			}
			
			.artist-content{
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-direction: row;
			}
			.artist{
				z-index: 0;
				width: 180px;
				min-width:180px;
				height: 180px;
				clip-path: circle();
				img{
					min-height: 180px;
					min-width: 180px;
					width: 100%;
					height: 100%;
				}
			
			}
			
		}
		.section-artist{
			
			.card-content{
		    display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap:10px;
  			row-gap: 1px;
			
			
			div{
				width:100%;
			   
				h3{
					color: #000;
					font-size: 18pt;
				}
				p{
					font-size:15pt;
					color: #555;
				}
			}
		}
	}
}

}
@media (max-width: 600px) {
	.header{
		height: 90vh;
		.nav-bar  .mobile-menu{
                display:block;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                height: 65px;
                display:flex;
                justify-content: space-between;
                align-items:center;
                padding: 20px 30px;
                z-index: 9999;
			
                :hover { color: inherit; }
                .logo{
                    width: 150px;

                    img{
                        width:100%;
                        } 
                }

                .btn-create{
                    border:none;
                    outline: none;
                    background-color: transparent;
                    cursor: pointer;
                    font-size: 1.2em;
                    :hover { color: inherit; }
                }
                .noHover{
                            pointer-events: none;
                        }
               
        } 
	}
	.body-bainner{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex: none;
			height: 80vh;
		
			.mobile-btn{
				display: none;
			}
	
		
			.header-wrapper{
				flex: none;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
			
				
			}
			.mobile-btn{
				display: flex;
				order: 4;
				width: 100%;
				padding: 20px 0px;
				justify-content: center;
				

				button,a{
					padding: 10px 8px;
					background-color: #f00;
					display: block;
					border-radius: 30px;
					width: 150px;
					text-align: center;
					cursor: pointer;
					outline: none;
					border: none;
					:nth-child(2){
						margin-left: 20px;
						background-color: #fff;
						color: #000;
						
					}
				
				}
			}
			.descrition-bainner{
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				height: auto;
				padding-top: 20px;
				h1{
					font-size: 36pt;
					text-align: center;
				}
				.btn-access{
					display: none;
				}
				
			}
			
			.artist-content{
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: row;
				padding: 30px 10px 10px 10px;
			
				
				
			}
			.artist{
				z-index: 0;
				width: 100px;
				min-width:100px;
				height: 100px;
				clip-path: circle();
			
				img{
					min-height: 100px;
					min-width: 100px;
					width: 100%;
					height: 100%;
				}
			
			}
			
		}

		.section-artist{
			
			.wrappper-content{
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				order: 2;
				padding: 10px;
				flex: 1;
				h2{
					text-align: center;
					font-size: 25pt;
				}
				.card-content{
					display: grid;
					grid-template-columns: 1fr;
					grid-template-rows: auto;
					row-gap: 0px;
					div{
						width:100%;
						margin-top: 5px;
					
						h3{
							color: #000;
							font-size: 15pt;
						}
						p{
							font-size:10pt;
							color: #555;
						}
					}

				}
		}
		}
				

	}
	


@media (max-width: 320px) {
	.header{
		height: 100vh;

		.descrition-bainner{
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				height: auto;
				h1{
					font-size: 26pt;
					line-height: 55px;
					
				}
				.btn-access{
					display: none;
				}
				
			}
		}
		.artist-content{
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: row;
				padding: 30px 0px;
		
			
				

				
				.artist{
				z-index: 0;
				width: 70px;
				min-width:70px;
				height: 70px;
				clip-path: circle();
			
				
			
				
				img{
					min-height: 90px;
					min-width: 90px;
					width: 100%;
					height: 100%;
				
					
				}
			
			}
			}
			

	}


@media (max-width: 280px) {
	.header{
		.mobile-btn{
				padding: 20px 10px;
				a{
					padding: 10px 0px;
					font-size: 10pt;
				}
			}
	}
	}
  `



