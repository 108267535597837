
import React from 'react';
import GlobalStyle from './styles/Global';

import { BrowserRouter } from 'react-router-dom';
//import { getAnalytics } from 'firebase/analytics';
import Routes from './routes';
import {
	authApp,
	connectEmulatorAuth,
	connectEmulatorFn,
	connectEmulatorFs,
	dbFirestore,
	functions
} from './firebase/config';


const App: React.FC = () => {

	if (window.location.hostname === "localhost") {
		connectEmulatorFs(dbFirestore, "localhost", 8080);
		connectEmulatorAuth(authApp, "http://localhost:9098");
		connectEmulatorFn(functions, "localhost", 5001);
	}
	//getAnalytics();

	return (
		<BrowserRouter>
			<Routes />
			<GlobalStyle />
		</BrowserRouter>
	);
};


export default App;