import { useState, useEffect} from 'react';
import { Container } from '../../styles/pages/store/styles';
import { IoCart } from 'react-icons/io5'
import ClipLoader from "react-spinners/ClipLoader";
import { httpsCallable } from "firebase/functions";
import { GrClose } from 'react-icons/gr';
import Timer from '../../components/timer';

import multicaixaExpress from "../../assets/express.webp";
// import PayPal from '../../services/paypal';
import NavBar from '../../components/NavBar';
import { firestore, functions } from '../../firebase/config';

import { DocumentData } from 'firebase/firestore';
import { AlbumType, UserType } from '../../types';
import styled from 'styled-components';
import MenuBar from '../../components/MenuBar';
import { useHistory } from 'react-router-dom';
import { getAlbums} from '../../services/server';
// import { FaSearch } from 'react-icons/fa';

const Store = () => {
	// const [user] = useAuthState(auth.getAuth());
	const user = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;
	const userPhoneNumber = JSON.parse(localStorage.getItem('phoneNumber') as string) as string;
	const currentNumber = userPhoneNumber !== null ? userPhoneNumber : '';


	// ------------------------------------------------------------------------
	const [openModalBuyModal, setOpenModalBuyModal] = useState(false);
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const loading = true;
	const onShowMenu = (value: boolean) => {
		setShowMenu(!showMenu);
	}
	const [multicaixaExpr, setMulticaixaExpr] = useState(false);
	const [, setPaypal] = useState(false);
	const indicativo = '244';
	const [loadingBtn, setLoadingBtn] = useState(false);
	// const [loaderSpinner, setLoaderSpinner] = useState(false);
	const [buySuccess, setBuySuccess] = useState(false);
	const [IsKeepPhoneNumber, setIsKeepPhoneNumber] = useState(false);

	const [buyError, setBuyError] = useState(false);

	const [listOfAlbums, setListOfAlbums] = useState<AlbumType[]>([]);
	const [albumInfo, setAlbumInfo] = useState<AlbumType>();
	const [mobilePhone, setMobilePhone] = useState<string>('');
	const provider = 'Multicaixa Express';
	const description = 'Compra do álbum ';
	const [token, setToken] = useState<string>('');
	// const [bought, setBought] = useState<string[]>([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [boughtAlbum, setBoughtAlbum] = useState<string[]>([]);
	const [loadingAlbums, setLoadingAlbums] = useState(true);
	// const [isEmptyResult, setIsEmptyResult] = useState(false);
	// const [resultMessage, setResultMessage] = useState(false);

	// const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	// const [artistName, setArtistName] = useState<string>('');


	// const albumBought = JSON.parse(localStorage.getItem('bought_albums') as string);

	useEffect(() => {
		const getAlbums = httpsCallable(functions, 'getAlbumsPurchased');

		const allAlbumsIds: string[] = [];
		getAlbums()
			.then((result: any) => {
				result?.data.map((doc: AlbumType)=>{
					return allAlbumsIds.push(doc.albumId as string);
				})
				setBoughtAlbum(allAlbumsIds);
			})
			.catch((e) => {
				//console.log('ERRRRRROR ', e);
			});
			

	}, []);



	useEffect(() => {

		let pos: number;
		let albumAction: DocumentData;
		const allAlbums: DocumentData[] = [];
		const query = getAlbums();
		const unsubscribe = firestore.onSnapshot(query, async (querySnapshot) => {

			if (!querySnapshot.empty) {
				setLoadingAlbums(false);
				querySnapshot.docChanges().forEach(change => {
					const data = change.doc.data() as DocumentData;
					let p;
					switch (change.type) {
						case 'added':
							allAlbums.push(data);
							break;
						case 'modified':
							albumAction = change.doc.data() as DocumentData;
							albumAction.albumId = change.doc.id;
							pos = allAlbums.findIndex((value) => value.albumId === albumAction.albumId);

							if (pos === -1) {
								allAlbums.push(albumAction);
							} else {
								allAlbums.splice(pos, 1);
								allAlbums.splice(pos, 0, albumAction);
							}
							break;
						case 'removed':
							p = allAlbums.findIndex(value => value.albumId === data.albumId);
							allAlbums.splice(p, 1);
							break;
					}
				});
				setListOfAlbums(allAlbums);
			} else {
				setLoadingAlbums(false);
			}

		});
		return () => {
			unsubscribe();
		};

	}, []);


	// ------------------------------------------------------------------------7

	const requestToken = () => {
		const getAcessToken = httpsCallable(functions, 'requestToken');
		getAcessToken()
			.then((result: any) => {
				setToken(result.data.access_token);
			})
			.catch((e) => {
				console.log(e);
			});
	}
	// ------------------------------------------------------------------------


	const requestMakePayment = () => {
		if(token !== ''){

			setLoadingBtn(true);
			return new Promise(async (resolve) => {
				
				const getPayment = httpsCallable(functions, 'makeGpoPaymentMusic');
				getPayment(
					{
						amount: albumInfo?.price,
						mobilePhone: indicativo + mobilePhone,
						albumSelected: albumInfo,
						token: token,
						description: description + albumInfo?.albumTitle,
						provider: provider,
						user: user,
					}
				)
					.then((result: any) => {
	
	
						if (result.data.errorCode) {
	
							switch (result.data.errorCode) {
	
								case 'PROCESSOR_907':
									setErrorMessage('Operação recusada. Por favor, tente novamente. Caso persista, entre em contacto connosco.');
									break;
	
								case 'ISSUER_907':
									setErrorMessage('Não existem fundos suficientes na tua conta.');
									break;
	
								case 'EPMS_907':
									setErrorMessage('Operação recusada pelo processador de pagamentos - EMIS.');
									break;
	
								case 'PROCESSOR_940':
									setErrorMessage('Você cancelou o pagamento.');
									break;
	
								case 'TIMEOUT':
									setErrorMessage('O tempo para concluir o pagamento esgotou. Por favor, tente novamente.');
									break;
	
								case 'BANK_822':
									setErrorMessage('Excedido o limite de pagamento.');
									break;
	
								case 'EXT_AUTH_907':
									setErrorMessage('O tempo para concluir o pagamento esgotou. Por favor, tente novamente.');
									break;
	
								case "EPMS_940":
									setErrorMessage('Você cancelou o pagamento.');
									break;
							}
	
							setBuyError(true);
							setTimeout(() => {
								setMobilePhone('');
								setOpenModalBuyModal(false);
								setLoadingBtn(false);
								setBuyError(false);
							}, 5000)
	
	
						} else if (result.data.id) {
							setBuySuccess(true);
							setTimeout(() => {
								setOpenModalBuyModal(false);
								setLoadingBtn(false);
								setBuySuccess(false);
								setMulticaixaExpr(false);
								window.location.reload();
	
							}, 4000)
	
	
							setTimeout(() => {
								if (userPhoneNumber === null) {
									setIsKeepPhoneNumber(true);
								}
							}, 6000)
	
	
						}
	
					}).catch((e) => {
						setBuyError(true);
	
						setTimeout(() => {
							setOpenModalBuyModal(false);
							setLoadingBtn(false)
							setBuyError(false);
							setMulticaixaExpr(false);
						}, 5000)
	
						console.log("GOT ERROR:", e);
						setErrorMessage('Por alguma razão o pagamento não foi efectuado.');
					})
			});

		}else{
			console.log('TOKEN ERROR...')
		}

		
	}
	// Ary:
	// 924024724
	// Ce:
	// 921785735

	const history = useHistory();
	const goLogin = () => {
		history.push('/login');
	};
	const currentUser = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;


	// const SearchArtistAlbums = (e: FormEvent<HTMLFormElement>) => {
	// 	e.preventDefault();
	// 	setLoadingSearch(true);
	// 	setListOfAlbums([]);
	// 	setLoadingAlbums(true);

	// 	const str = artistName;
	// 	const artist = str.charAt(0).toUpperCase() + str.slice(1);

	// 	const allAlbums: DocumentData[] = [];
	// 	const query = getArtistAlbums(artist);
	// 	firestore.onSnapshot(query, async (querySnapshot) => {

	// 		if (!querySnapshot.empty) {
	// 			setIsEmptyResult(false);
	// 			setLoadingAlbums(false);
	// 			setLoadingSearch(false);
	// 			setResultMessage(true)
	// 			querySnapshot.docChanges().forEach(change => {
	// 				const data = change.doc.data() as DocumentData;
	// 				allAlbums.push(data);
	// 			});
	// 			setListOfAlbums(allAlbums);
	// 		} else {
	// 			setLoadingAlbums(false);
	// 			setLoadingSearch(false);
	// 			setIsEmptyResult(true);
	// 		}
	// 	});

	// }

	const time = 0;

	return (
		<>
			<div className="nav-bar" style={{ background: "#000" }}>
				<NavBar showMenu={showMenu} setShowMenu={setShowMenu} currentUser={currentUser} />
			</div>
			{showMenu ?
				<MenuBar showMenu={showMenu} onShowMenu={onShowMenu} /> : ''}
			<Container>

				<div className="store-container">
					<header>
						<h1>Praça da independência</h1>

						{/* <form onSubmit={(e) => SearchArtistAlbums(e)}>
							<input
								type='search'
								required
								placeholder='Procurar artista...'
								onChange={(e) => setArtistName(e.target.value)}
							/>
							<button type='submit'>
								{
									loadingSearch ? <ClipLoader color={"#fff"} loading={true} size={20} /> : <FaSearch color='#fff' size={20} />
								}
							</button>

						</form> */}

					</header>

					{/* {
						resultMessage && (
							<AlbumsResult>
								<h3>Resultados da pesquisa : </h3>
							</AlbumsResult>
						)
					} */}


					<div className="albums-container">

						{listOfAlbums.length > 0 ? (
							listOfAlbums.map((album, index) => {

								return (
									<div className="album-card" key={index} >
										<div className="album-img">
											<img src={album.cover} alt="" />
										</div>
										<div className="album-info">
											<h2>{album.albumTitle}</h2>
											<h3>{Array.isArray(album.artist) ? album.artist.map(artist => artist.artist).join(' x ') : album.artist?.artist}</h3>
											<p>{album.price} KZ</p>
										</div>

										{
											boughtAlbum.length > 0 && boughtAlbum.includes(album.albumId as string) ?

												<div className="buy-btn">
													<h3 style={{ color: '#888 ' }}>Comprado </h3>
												</div>
												:
												<div className="buy-btn">
													<button
														onClick={() => {
															if (user) {
																setOpenModalBuyModal(true);
																setAlbumInfo(album);
																requestToken();
															} else {
																goLogin()

															}
														}} > <IoCart /> Comprar </button>

												</div>
										}

									</div>
								)

							})
						) : loadingAlbums ? <LoagingAlbumsModal>
							<div>A Carregar albums ...</div>
						</LoagingAlbumsModal> :

							<h1>Sem albuns a venda</h1>

						}
					</div>
				</div>

				{openModalBuyModal &&
					(
						<ModalCover>
							<ContainerInfo>
								<div className="close-modal-btn"><button onClick={() => {
									setOpenModalBuyModal(false);
									setMulticaixaExpr(false);
									setMobilePhone('');

								}}><GrClose /></button></div>
								<div className="header-content">
									<h3>Selecione o método de pagamento</h3>
								</div>
								<div className="payment-method">
									<div className="multcaixa-express"
										onClick={() => {
											setMulticaixaExpr(true)
											setPaypal(false);
											setMobilePhone('');
										}}
									>
										<img src={multicaixaExpress} alt="" />
									</div>
									{/* <div className="paypal"
										onClick={() => {
											setMulticaixaExpr(false)
											setPaypal(true);
										}}
									>
									
										<PayPal albumInfo={albumInfo} user={user} />
									</div> */}
								</div>
								<div className="payment-content">
									{multicaixaExpr &&
										<div className="multicaixa-payment">
											<form onSubmit={(e) => {
												e.preventDefault();
											}}>

												<p>Digite o número associado ao Multicaixa Express</p>
												<div className="payment-field">
													<p>+244</p>
													<input type="tel" placeholder="número"
													
														maxLength={9}
														size={9}
														onChange={(e) => {
															setMobilePhone(e.target.value);
														}}
														
														defaultValue={currentNumber}
													/>
												</div>
												<div className="experess-btn">

													{
														loadingBtn !== false  && mobilePhone.length === 9 ?
															<div className="loader-spiner">
																<p>Irá receber uma notifição no seu Multicaixa Express</p>
																<ClipLoader color={"#FF5F00"} loading={loading} size={30} />
																<Timer 
																	time={time}
																	setOpenModalBuyModal={setOpenModalBuyModal}
																	setLoadingBtn={setLoadingBtn}
																	setBuyError={setBuyError}
																	setMulticaixaExpr={setMulticaixaExpr}
																/>
															</div> :

															token !== '' ?
															<button
																type="submit"
																onClick={() => {
																	if (mobilePhone.length === 9) {
																		requestMakePayment();
																	}
																
																}}
															>  Prosseguir</button>

															:
															<ClipLoader color={"#FF5F00"} loading={true} size={30} />


													}

												</div>
											</form>
										</div>
									}
								</div>
							</ContainerInfo>
						</ModalCover>
					)

				}

			</Container>

			{IsKeepPhoneNumber &&
				(
					<KeepNumberPhoneModal>
						<div className='modal'>
							<p>Deseja guardar o número do telefone, para usa-lo na próxima compra?</p>
							<div className='btn-options'>
								<button
									onClick={() => {
										localStorage.setItem('phoneNumber', JSON.stringify(mobilePhone));
										setIsKeepPhoneNumber(false);
										window.location.reload();
									}}
								>Sim</button>
								<button className='no'
									onClick={() => {
										setIsKeepPhoneNumber(false);
										window.location.reload();
									}}
								>Não</button>
							</div>
						</div>
					</KeepNumberPhoneModal>
				)
			}



			{buySuccess &&
				(
					<SucessModal>
						<div>
							<p>Compra efetuada com sucesso!</p>
						</div>
					</SucessModal>
				)
			}

			{
				buyError &&
				(
					<ErrorModal>
						<div>
							<p>{errorMessage}</p>
						</div>
					</ErrorModal>
				)
			}

			{/* {
				isEmptyResult && (
					<NoResultFound>
						<div>
							<h1>Nenhum album encontrado a venda</h1>
							<h2>Em nome de {artistName}</h2>
							<button
								onClick={() => {
									window.location.reload()
								}}
							>
								Fechar
							</button>
						</div>
					</NoResultFound>
				)
			} */}

		</>
	)
}

// const AlbumsResult = styled.div`
// 	padding:2em;
// 	display: flex;   
// 	justify-content:left;   
// 	align-items:left;   
// 	width:100%;
// 	h1{
// 		font-weight:bold;
// 		font-size:1.4em;
// 	}
// `;

// const NoResultFound = styled.div`
// 	position: fixed;   
// 	z-index: 100;   
// 	width: 100%;   
// 	height: 100vh;   
// 	left: 0;   
// 	top: 0;   
// 	right: 1em;   
// 	background: rgba(0, 0, 0, 0.95)!important;    
// 	pointer-events: all;   
// 	display: flex;   
// 	justify-content:center;   
// 	align-items:center;   
// 	div{       
// 		background:#fff;       
// 		color: #000 ; 
// 		font-size: 2em;
// 		padding:1em;
// 		display: flex;   
// 		justify-content:center;   
// 		align-items:center;   
// 		flex-direction:column;
// 		h1,h2{
// 			color: #000 ; 
// 			font-weight:400;
			
// 		}
// 		button{
// 			width:20%;	
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			background: transparent;
// 			color: #fff;
// 			height: 40px;
// 			width: 250px;
// 			border-radius: 3px;
// 			text-transform: uppercase;
// 			background-color:#fa1200;
// 			border:none;
// 			margin-top:2em;
// 			&:active{
// 				transform:translateY(5px)
// 			}
// 		}
// 	} ;
	
// `;



const LoagingAlbumsModal = styled.div`
	position: fixed;   
	z-index: 100;   
	width: 100%;   
	height: 100vh;   
	left: 0;   
	top: 0;   
	right: 1em;   
	background: rgba(0, 0, 0, 0.95)!important;    
	pointer-events: all;   
	display: flex;   
	justify-content:center;   
	align-items:center;   
	div{       
		background:#fff;       
		color: #000 ; 
		font-size: 2em;
		padding:0.5em;
	} ;
`;


export const KeepNumberPhoneModal = styled.div`
	position: fixed;   
	z-index: 100;   
	width: 100%;   
	height: 100vh;   
	left: 0;   
	top: 0;   
	right: 1em;   
	background: rgba(0, 0, 0, 0.90)!important;    
	pointer-events: all;   
	display: flex;   
	justify-content:center;   
	align-items:center;   
	
	.modal{       
		background:#fff;    
		width:400px;
		height:200px;   
		padding:1em;   
		color: #333;
		display: flex;   
		justify-content:center;   
		align-items:center; 
		flex-direction:column;
		padding:1em;
		text-align:center;
		.btn-options{
			display: flex;   
			justify-content:center;   
			align-items:center;
			padding:1em;
			button {
				background-color:#fa1200;
				text-transform: uppercase;
				width: 120px;
				height: 45px;
				border: 0;
				border-radius: 4px;
				transition: filter .2s;
				margin:1em;
				&:hover {
					filter: brightness(0.8);
				}
				&:active {
					transform:translateY(5px)
				}
			}
			.no{
				background-color:#000;
			}
		}
	} ;
`;

export const SucessModal = styled.div`
	position: fixed;   
	z-index: 100;   
	width: 100%;   
	height: 100vh;   
	left: 0;   
	top: 0;   
	right: 1em;   
	background: rgba(0, 0, 0, 0.75)!important;    
	pointer-events: all;   
	display: flex;   
	justify-content:center;   
	align-items:center;   
	
	div{       
		background:#fff;       
		padding:5em;   
		color: #333;
	} ;
`;

export const ErrorModal = styled.div`
	position: fixed;   
	z-index: 100;   
	width: 100%;   
	height: 100vh;   
	left: 0;   
	top: 0;   
	right: 1em;   
	background: rgba(0, 0, 0, 0.75)!important;    
	pointer-events: all;   
	display: flex;   
	justify-content:center;   
	align-items:center;   
	div{       
		border-radius: 8px;
		background:#fff;       
		padding:5em; 
		color: #890F0D ; 
	} ;
`;

// const SpinnerContainer = styled.div`
// 	width: 100%;
// 	height: 100vh;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// `;

const ContainerInfo = styled.div`
	min-height: auto;
	max-width: 600px;
	width: 100%;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0;
	@media (max-width: 768px) {	
		width: 95%;
		margin: 0 auto;
		padding: 1em;
		max-width: auto;
	}

	.close-modal-btn {
		width: 100%;
		margin-top: -40px;
		display: flex;
		justify-content: end;
		margin-left: 10px;

		button {
			height: 60px;
			width: 60px;
			border-radius: 50%;
			border: 0;
			font-size: 20px;
		}
	}

	.header-content {

		margin-top: 30px;

		h3 {
			text-transform: capitalize;
			font-size: 16px;
			color: #333;
		}
	}

	.payment-method {
		display: flex;
		margin: 20px 0px;
		

		.paypal-button{
			&:hover {
			background:none!important;
				
			}
		}
		.multcaixa-express, .paypal {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 80px;
			width: 150px;
			border-radius: 1px;
			
			&:hover {
				cursor: pointer;
			}
		}

		.multcaixa-express{
			background:#FF5F00;
			img {
				width: 80px;
			}

			&:hover {
				border: 2px solid #FF5F00;
			}
		}
		
		.paypal {
			margin-left: 10px;
			background: #0070ba;
			/* height: 10px!important; */
			

			&:hover {
				border: 2px solid #333C83;

			}
		}
	}

	.payment-content {

		h1 {
		color: #333;
		}
		

		.multicaixa-payment {
			display: flex;
			flex-direction: column;
			p {
				color: #333;
				margin: 10px 0;
			}
			.payment-field {
				padding: 0 15px;
				display: flex;
				align-items: center;
				height: 60px;
				border: 1px solid #FF5F00;
				border-radius: 4px;
				p {
					margin-right: 5px;
					color: #333;
				}

				input {
					width: 85%;
					padding: 0 10px;
					height: 45px;
					border: 0;
					color: #333;
					font-size: 16px;
				}
			}

			.experess-btn{
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 20px 0;
				height: 60px;
				width: 100%;

				button {
					background: #FF5F00;
					text-transform: uppercase;
					width: 120px;
					height: 45px;
					border: 0;
					border-radius: 4px;
					transition: filter .2s;

					&:hover {
						filter: brightness(0.8);
					}
				}
			}

			.loader-spiner {
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				width: 100%;

				p {
					font-size: 12px;
				}
			}
		}
	}
`;

const ModalCover = styled.div`
	height: 100vh;
	background: rgba(0, 0, 0, .9);
	position: fixed;
	width: 100%;
	z-index: 100;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	
`;


export default Store;

