import { Timestamp } from 'firebase/firestore';
import React, { useState } from 'react';
// import { MdDone } from 'react-icons/md';
import { PlanType, UserType } from '../../../types';
import PayPlanModal from './PayPlanModal';

interface Props {
    planData: PlanType
}

const IndividualPlan = ({ planData }: Props): JSX.Element => {
	const user = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;

    const [checkout, setCheckout] = useState<boolean>(false);

    const handleCheckout = () => {
        setCheckout(true);
    };
    const handleCloseCheckout = () => {
        setCheckout(false);
    };

	const  addMonths = (numOfMonths: number, date: Date) =>{
		date.setMonth(date.getMonth() + numOfMonths);

		return date;
	};


	const onMonthOnFuture = new Date();
	const expiration = addMonths(1, onMonthOnFuture);
	const today = new Date();

    const plan: PlanType = {
		...planData,
        user: user,
		expiration: expiration as unknown as Timestamp,
		dateSubscribed: today as unknown as Timestamp
    };

    return (
        <div className='Plan'>
            <div className='plan--header'>
                <h2>{planData.planName}</h2>
                <ul className='plan--header-info'>
                    <li className='monthly-price'>{planData.price} Akz/mês</li>
                    <li className='account'>1 Conta</li>
                </ul>
            </div>
            <div className='button-and-terms'>
                <div className='btn-link-plan'>
                    <button onClick={handleCheckout}>Subscreva</button>
                </div>

                <br/>
                <div className='link-terms-apply'>
                    <a href='/'>Termos e condições aplicáveis</a>
                </div>
                <br/>
            </div>
            {checkout ? (<PayPlanModal closeModal={handleCloseCheckout} planData={ plan} />) : ''}
        </div>
    );
};

export default IndividualPlan;
