import React from 'react';
import Styled from 'styled-components';
import LogoutMenu from './LogoutMenu';
import Menu from './Menu';

const Sidebar = (): JSX.Element => {
    return (
        <SidebarStyles>
            <Menu />
            <LogoutMenu />
        </SidebarStyles>
    );
};

const SidebarStyles = Styled.aside`
    background: #000;
    height: 90vh;
    width: 15%;
    position: fixed;
    top: 0;
    right: 2em;
    left: 0;
    margin-top:4em;
	@media (max-width: 768px) {
		display:none
		
	}
`;

export default Sidebar;
