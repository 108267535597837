import { User, UserCredential } from "firebase/auth";
import { DocumentData, Query } from "firebase/firestore";
import { auth, dbFirestore, firestore } from "../firebase/config";
import { UserType } from "../types";


export const LoginWithGoogle = async (): Promise<UserCredential> => {

	return new Promise(async (resolve, reject) => {

		const googleProvider = new auth.GoogleAuthProvider();
		googleProvider.addScope('email');

		await auth.signInWithPopup(auth.getAuth(), googleProvider)
			.then(result => {

				const data = result as UserCredential;
				resolve(data);

			}).catch(error => {
				reject(error);
			})
	})

}

export const LoginWithApple = async (): Promise<UserCredential> => {

	return new Promise(async (resolve, reject) => {

		const appleProvider = new auth.OAuthProvider('apple.com');
		appleProvider.addScope('email');

		await auth.signInWithPopup(auth.getAuth(), appleProvider)
			.then(result => {

				const data = result as UserCredential;

				resolve(data);

			}).catch(error => {
				reject(error);
			})
	})

}



export const LogOut = () => {
	auth.signOut(auth.getAuth())
}


export const getUserAuth = async (user: User): Promise<UserType> => {


	return new Promise(async (resolve, reject) => {

		if (user !== null) {
			// google auth
			const email = user.email as string;
			const userRef = firestore.doc(dbFirestore, 'users', email);

			const dataDoc = await firestore.getDoc(userRef);

			if (dataDoc.exists()) {
				const data = dataDoc.data() as UserType
				resolve(data);
			} else {

				reject('user not found');
			}
		}

	})
}


export const getAlbums = (): Query<DocumentData> => {
	const albumsRef = firestore.collection(dbFirestore, 'albums');
	return firestore.query(albumsRef,
		firestore.where('price', '!=', 0)
	);
};



export const getArtistAlbums = (artist: string): Query<DocumentData> => {
	const albumsRef = firestore.collection(dbFirestore, 'albums');
	return firestore.query(albumsRef,
		firestore.where('artistInAlbum', 'array-contains-any', [artist]),
		firestore.where('price', '!=', 0),
		// firestore.where('artist.artist', '>=', artist),
		// firestore.where('artist.artist', '<=', artist + '\uf8ff'),
	);
};




export const getAllPlans = (): Query<DocumentData> => {
	const ref = firestore.collection(dbFirestore, 'premium_plans');
	return firestore.query(ref,
		firestore.where('price', '!=', 0));
};


export const getCurrentUserPlan = (user: string): Query<DocumentData> => {

	const ref = firestore.collection(dbFirestore, 'premium_active');
	return firestore.query(ref, firestore.where('user.phoneNumber', '==', user));
};