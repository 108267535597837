import React, { PropsWithChildren, useRef } from 'react';
import { Background, ModalWrapper } from './styles';
//import { useSpring, animated } from 'react-spring';
interface Props {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	setSelectTypeUser: (value: string) => void;
	setItemSelect:(value: boolean) => void;
	itemSelect: boolean;
	clearMessage: ()=> void | undefined
}
export const Modal: React.FC<PropsWithChildren<Props>> = ({ showModal, setShowModal, setSelectTypeUser, setItemSelect, itemSelect,clearMessage, children }) => {

	const modalRef = useRef();
	// const animation = useSpring({
	// 	config: {
	// 		duration: 250
	// 	},
	// 	opacity: showModal ? 1 : 0,
	// 	transform: showModal ? `translateY(0%)` : `translateY(-100%)`
	// });

	const closeModal = (e: any) => {
		if (modalRef.current === e.target) {
			setShowModal(false);
			setSelectTypeUser("");
			clearMessage();
		}
	};
	const VerifyIsOpenItem=()=>{
		if(itemSelect===true){
			setItemSelect(false)
		}
	}
	
	return (
		<Background onClick={VerifyIsOpenItem} showModal={showModal}>
			{/* <animated.div style={animation}>
				<ModalWrapper onClick={VerifyIsOpenItem}>
					<div className="btn-create noHover" onClick={() => {closeModal(!showModal)}}>
						<button className="close" />
					</div>
					{children}
				</ModalWrapper>
			</animated.div> */}
			<div>
				<ModalWrapper onClick={VerifyIsOpenItem}>
					<div className="btn-create noHover" onClick={() => {closeModal(!showModal)}}>
						<button className="close" />
					</div>
					{children}
				</ModalWrapper>
			</div>
		</Background>
	);
};

export default Modal;