import styled from 'styled-components'

export const ContainerAbout = styled.div`

	.main-container{

		min-width: 1366px;
	}

   .header{

      min-width: 100vw;
      z-index: 0;
      position: relative;

	  .nav-bar{
			z-index: 10;
			min-height: 14vh;
			min-width: 100vw;
			background-color: #000;

			@media screen and (max-width: 700px) {
				height: 20vh;
			}

			@media screen and (max-width: 600px) {
				height: 5vh;
			}
        }

   }
   
   
   .bainner{
    
	   height: 78vh;
       z-index: -1;
       background-color: #521314;
       position: relative;

	   @media screen and (max-width: 1366px){
			min-height: 78vh;
	   }


		@media screen and (max-width: 900px){
			height: 50vh;
			min-height: 0;
		}

		@media screen and (max-width: 600px){
			height: 40vh;
			min-height: 0;
		}
			
	
        .toqueplay-brown-logo{

			display: grid;
            object-fit: cover;
			place-items: center;
			justify-items: center;
			align-items: center;
			width: 100%;
			position: absolute;
			top: 20%;
		
			@media screen and (max-width: 1024px) {

				top: 25%;

			}

			@media screen and (max-width: 900px){
				
				top: 20%;
		}

			@media screen and (max-width: 500px) {
					height: 10vh;
					top: 40%;
				}
		
		}

		.left-aside-image-header{

			position: absolute;
			left:0;
			top: -5%;
			width: 52%;

			@media screen and (max-width: 1024px){
				
				width: 52%;
				top: -1%;
			}

			@media screen and (max-width: 900px){
				
				top: -3%;
			}

			

			@media screen and (max-width: 500px) {
					width:80% ;
			}
			

		}

		.right-image-aside-header{

			position: absolute;
			right: 0;

			@media screen and (max-width: 1024px){
				
				width: 50%;
				top: 5%;
			}

			@media screen and (max-width: 900px){
				
				top: 3%;
				width: 40%;
			}

			@media screen and (max-width: 800px){ 
				
				width: 45%;
			} 

			@media screen and (max-width: 600px){ 
				
				width: 50%;
			} 

			@media screen and (max-width: 500px) {
					display: none;
				}
		
		}
		

        .bainner-text{

            font-size: 2.3rem;
			right: 15%;
			position: absolute;
			top: 40%;
			text-align: center;

			@media screen and (max-width: 1024px) {

				font-size: 1.7rem;
				padding: 1vw;
				padding-top: 0;
				padding-bottom: 0;

			}

			@media screen and (max-width: 900px) {
				font-size: 1.5rem;
				padding: 0;
				top: 37%;
				right: 13%;
			}

			@media screen and (max-width: 800px){ 
				
				font-size: 1.4rem;
				right: 12%;
			} 

			@media screen and (max-width: 700px) {
				font-size: 1.2rem;

			}

			@media screen and (max-width: 600px) {
				font-size: 1.1rem;

			}

			@media screen and (max-width: 500px) {
					display: none;
				}
            
        }

   }

   .about-app-container{

		   width: 100%;
		   height: 80vh;
		   text-align: justify;
		   position:relative;

		   
		   .about-app-title-text{
				font-size: 2.5rem;
				color: #521314;
				//color: #d00;
				margin-top: 10vh;
				margin-left: 7vw;
				margin-bottom: 5vh;

				@media screen and (max-width: 1024px) {
					font-size: 2.3rem;
				}

				@media screen and (max-width: 900px) {
					font-size: 1.8rem;
				}

				
			}

		   .half-love-disc-img{

			position:absolute;
			left:0;
			
			@media screen and (max-width: 1024px) {
					width: 18%;
			}

			@media screen and (max-width: 700px) {
				
				width: 22%;
			}

			@media screen and (max-width: 500px) {
					display: none;
				}
		   }

		   .about-app-p-container{

				padding-top: 0;
				padding-bottom: 0;
				width: 95%;
				font-size: 1rem;

				@media screen and (max-width: 1024px) {
					font-size: 0.95rem;
					width: 95%;
				}

				@media screen and (max-width: 800px) {
				
					width: 92%;
				}

				@media screen and (max-width: 500px) {
					width: 90%;
				}
		   }

		   .about-app-content-text{
				color: #000;
				line-height: 3vh;
				margin-left: 7vw;
				
				@media screen and (max-width: 1024px) {
					font-size: 0.9rem;
				}


				@media screen and (max-width: 700px) {
				
					font-size: 0.8rem;
				}
					
		   }

		   div p:nth-child(1){
			
			padding-bottom: 2vh;
		   }

		   div p:nth-child(3){
			
			padding-bottom: 2vh;
		   }

		   .songs-img{

				position:absolute;
				right:2%;
				top: 70%;

				@media screen and (max-width: 800px){ 
			
					right: 5%;
				} 

				@media screen and (max-width: 700px) {
				
					width: 15%;
		
				}

				@media screen and (max-width: 500px) {
					width: 13%;
					top: 80%;
				}
				
		   }
		  
		}

	   .footer-store-head{

		  margin-top: 5vh;
          display: grid;
		  grid-template-columns: 2;
		  grid-auto-flow: row;
          
		  .footer-containner{
			display: grid;
			justify-content: center;
			
			.store-icon{
              cursor: pointer;
			  width: 10vh;
              :hover{
                transition: all ease .2s;

              }
            
			}

			.icon-android{
				grid-column: 1/2;
				width: 12.5vw;
				object-fit: cover;

				@media screen and (max-width: 900px) {
					width: 16.5vw;
				}

				@media screen and (max-width: 700px) {
					width: 20.5vw;
				}
				
				@media screen and (max-width: 500px) {
					width: 25.5vw;
				}

			}

			.icon-apple{
				grid-column: 3/4;
				width: 10vw;
				position: relative;
				top: 1.4vh;
				object-fit: cover;

				@media screen and (max-width: 900px) {
					width: 13vw;
				}

				@media screen and (max-width: 700px) {
					width: 16vw;
				}

				@media screen and (max-width: 500px) {
					width: 20vw;
				}
			}
           
          }

        }  

	
		   .container-artists-img{

				display: grid;
				grid-template-columns: repeat (auto-fit,minmax(28rem,1fr));
				grid-auto-flow: column;
				justify-content: center;
				position: relative;
				background-color: #fff;
				margin-top: 5vh;
				margin-bottom: 25vh;

				@media screen and (max-width: 800px) {
					display: none;
				}
			
				.artists-img-item{
					
					width:7vw;
					height:50vh;
					position: relative;
					object-fit: cover;
				}

				.overlap-div{

					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					background-color: #3D0C0C;
					opacity: 0.74;
				}

				.overlap-div-text{
		
					color: #fff;
					font-size: 2rem;
					font-weight: bold;
					transform: translateY(38vh);
					position: absolute;
					left: 20%;

					@media screen and (max-width: 1024px) {
					font-size: 1.8rem;
					}

				}

		   }



	.section-1{

		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(	17rem,1fr));
		gap: 0 10vh;
		margin-top: 25vh;
		margin-bottom: 25vh;
	
		@media screen and (max-width: 800px){ 
				
			margin-top: 10vh;
		} 

		
		.text-aside-container{
        
        width: 40vw;
        height: 50vh;
        color: #000;
        margin-top: -5vh;
		margin-left: 10vh;
		
		

		@media screen and (max-width: 1024px) {
			margin-left: 5vh;
		}	

		@media screen and (max-width: 900px) {
			margin-left: 2vh;
			margin-top: -2vh;

		}

		@media screen and (max-width:800px){
				margin-left: 4vh;
				margin-top: 2vh;
		}

		@media screen and (max-width:700px){
				margin-top: 8vh;
		}

		
		@media screen and (max-width:600px){		

			width: 50vw;
			transform:translateX(28%);
			margin-top: 4vh;

		}
		
		@media screen and (max-width: 500px) {
			margin-top: 4vh;
			margin-bottom: 8vh;
			width: 60vw;
			transform:translateX(20%);
					
		}

        .text-aside--img{
		   position: relative;
		   top: 18vh;
		   margin-left: 1vw;
		
		   @media screen and (max-width: 1024px) {

				margin-left: -1vw;
		   }

		   @media screen and (max-width: 900px) {

				margin-left: -1vw;
				width: 20%;
				top: 13vh;
			}

			@media screen and (max-width: 800px) {
				
				top: 12vh;
				margin-left: -3vw;
				
			}

			@media screen and (max-width: 700px) {
					width: 15%;
					top: 8vh;
			}

			@media screen and (max-width: 600px) {
					width: initial;
					top: 8vh;
					margin-left: -12vw;
			}

			@media screen and (max-width: 500px) {
				
				width: 25%;
				margin-left: -8vh;
			}


        }

        .text-aside--text{
            color: #521314;
            position: relative;
            left: 23%;
			line-height: 7vh;
			font-size: 2.5rem;		
			@media screen and (max-width: 1024px) {
					font-size: 2.2rem;
					
			}	

			@media screen and (max-width: 900px) {
					font-size: 1.7rem;
					line-height: 6vh;
			}	

			@media screen and (max-width: 800px) {
				margin-left: -4vw;
				
			}

			@media screen and (max-width: 700px) {
					font-size: 1.3rem;
					line-height: 4vh;
			}

			@media screen and (max-width: 600px) {
					font-size: 2rem;
					line-height: 6vh;
					top: -20%;
			}

			@media screen and (max-width: 500px) {
				
				font-size: 1.8rem;
				top: -13%;
				margin-left: -7vw;
			}
        }

        .text-aside--content-text{
			position: relative;
			top:2vh;
            text-align: justify;
			left: 23%;
			line-height: 3vh;

			@media screen and (max-width: 1024px) {
				
				font-size:0.9rem ;
			}

			@media screen and (max-width: 900px) {
					font-size: 0.9rem;
			}

			@media screen and (max-width: 800px) {
				
				margin-left: -4vh;
				
			}

			@media screen and (max-width: 700px) {
					font-size: 0.7rem;
					
			}

			@media screen and (max-width: 600px) {
					font-size: 1rem;
					top: -18%;
					
			}

			@media screen and (max-width: 500px) {
					font-size: 0.8rem;
					top: -12%;
					margin-left: -7vw;
					
			}
			
        }

    }

		.img-aside-container{
			background-color: #fff;
			width: 50vw;
			height: 50vh;
			color: #000;
			padding-top: 10vh;
			position: relative;
			//margin-bottom: 40vh;

			@media screen and (max-width: 1024px) {
				
				right: -2%;
			} 


			@media screen and (max-width: 800px){ 
				
				right: -3%;
				
			} 

			@media screen and (max-width: 700px){ 
				
				right: 3%;
				
			} 

			@media screen and (max-width:600px){		

				width: 90vw;
				left: 8%;
				margin-top: -10vh;

			}

			@media screen and (max-width:500px){		

			width: 90vw;
			left: 8%;
			margin-top: -25vh;

			}

		}

		.img-aside--img{
			width: 70%;
			height: 80%;
			object-fit: cover;
			position: relative;
			border-radius: 0.5vw;
			position: absolute;
			right: 20%;


			@media screen and (max-width:600px){		
				width: 80%;
				right: 8%;
				border-radius: 2vw;

			}

		}

		.img-aside-container::before{
			content: " ";
			width: 35vw;
			height: 40vh;
			position: absolute;
			background-color: #f8f6f6;
			border: 0.3vh solid #3D0C0C;
			left:2%;
			top: 4vh;
			border-radius: 0.8vw;

			@media screen and (max-width:600px){		

				width: 72vw;
				border-radius: 2vw;

			}
			

			
		}

	}

		.section-2{

			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(17rem,1fr));
			gap: 0 10vh;
			margin-top: 10vh;
			margin-bottom: 20vh;
			position: relative;

			@media screen and (max-width: 1024px) {

				gap: 0 17vh;
			}

			
		.img-aside-container-especial{
			background-color: #fff;
			width: 50vw;
			height: 50vh;
			color: #000;
			padding-top: 10vh;
			position: relative;
			margin-left: 20vh;
			margin-bottom: 15vh;



			@media screen and (max-width: 1024px) {

				margin-left: 15vh;
			}

			@media screen and (max-width: 900px) {

				margin-left: 12vh;
			}

			@media screen and (max-width: 800px) {
				
				margin-left: 10vh;
				
			}

			@media screen and (max-width: 700px) {
				
				margin-left: 8vh;
				
			}

			@media screen and (max-width:600px){		

				width: 90vw;
				left: 8%;
				margin-top: 10vh;

			}


		}

		.img-aside--img--especial{
			width: 70%;
			height: 100%;
			object-fit: cover;
			position: relative;
			border-radius: 0.5vw;


			@media screen and (max-width:600px){		

				width: 80%;
				right: -2%;
				border-radius: 2vw;

			}
		}

		.img-aside-container-especial::before{
			content: " ";
			width: 35vw;
			height: 40vh;
			position: absolute;
			background-color: #f8f6f6;
			border: 0.3vh solid #3D0C0C;
			left:-8%;
			top: 4vh;
			border-radius: 0.8vw;

			@media screen and (max-width:600px){		

				width: 72vw;
				border-radius: 2vw;

			}
			
		}

		.nome-artista{
		
			font-size: 1.5rem;
			position: absolute;
			bottom: 5%;
			left: 3%;

			@media screen and (max-width:1024px) {
				
				font-size: 1.2rem;
			} 

			@media screen and (max-width: 900px) {

				margin-left: -1vw;
				width: 20%;
				font-size: 0.9rem;
			}

			@media screen and (max-width: 600px) {

				margin-left: 3vw;
				width: 20%;
				font-size: 1.3rem;
			}
		}


		.text-aside-container-especial{
        
			width: 50vw;
			height: 50vh;
			background-color: #fff;
			color: #000;
			margin-top: 15vh;
			position: relative;

			
			@media screen and (max-width:1024px) {
				
				right: 6vh;
			} 

			@media screen and (max-width:900px) {
				
				right: 10vh;
				margin-top: 20vh;
			} 

			@media screen and (max-width:800px) {
				
				right: 11vh;
			} 

			@media screen and (max-width:700px) {
				
				margin-top: 22vh;
				right:8vh;
			} 

			@media screen and (max-width:600px) {
				
				left: 16vw;
				margin-top: -2vh;
			} 

			@media screen and (max-width:500px) {
				
				width: 60vw;
			} 

		}

		.text-aside--img--especial{
			position: relative;
			top: -4vh;
			left: 1vw;

			@media screen and (max-width: 1024px) {
				left: -1vw;
			}

			@media screen and (max-width: 900px) {
				width: 20%;
					
			}

			@media screen and (max-width: 800px) {
				
				width: 16%;
				
			}

			@media screen and (max-width: 700px) {
			
				width: 15%;
				top: -5vw;
				
			}

			@media screen and (max-width: 600px) {
				width: initial;
				left: -3vw;
			}

			@media screen and (max-width: 500px) {
				width: 25%;
				left: -3vw;
				top:-11vw; 
			}

		}

		
        .text-aside--text--especial{
            color: #3D0C0C;
            position: relative;
            top: -52%;
            left: 20%;
			line-height: 8vh;
			font-size: 2.5rem;
			@media screen and (max-width: 1024px) {

				font-size: 2.3rem;
			}

			@media screen and (max-width: 900px) {
				
				font-size: 1.7rem;
				line-height:5vh;
				top: -45%;
			}

			@media screen and (max-width: 800px){ 
				
				left: 17%;
				top: -33%;
			} 
			

			@media screen and (max-width: 700px) {
					font-size: 1.3rem;
					
			}

			@media screen and (max-width: 600px) {
					font-size: 2rem;
					line-height: 6vh;
					top: -50%;
					left: 33%;
			}
			@media screen and (max-width: 500px) {
				
				font-size: 1.8rem;
				margin-left: -7vw;
			}


        }

        .text-aside--content-text--especial{
			position: relative;
			top: -48%;
            text-align: justify;
			left: 20%;
			line-height: 3vh;

			@media screen and (max-width: 900px) {
				
				font-size: 0.9rem;
				top:-42%;
			}

			@media screen and (max-width: 800px){ 
				
				top: -32%;
				left: 17%;
			} 
			

			@media screen and (max-width: 700px) {
				font-size: 0.7rem;
					
			}

			@media screen and (max-width: 600px) {
					font-size: 1rem;
					top: -50%;
					left: 33%;
			}

			@media screen and (max-width: 500px) {
				
				font-size: 0.8rem;
				margin-left: -7vw;
			}

        }


    }



.section-3{

	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(17rem,1fr));
	gap: 0 10vh;
	margin-top: 10vh;

	@media screen and (max-width: 500px) {
		margin-top: -30vh;
	}
	.text-aside-container{

	width: 50vw;
	height: 50vh;
	color: #000;
	margin-top: -13vh;
	position: relative;
	margin-left: 10vh;
	margin-bottom: 30vh;

	@media screen and (max-width: 1024px) {

		margin-left: 5vh;
	}

	@media screen and (max-width: 900px) {
			margin-left:3vh ;
			margin-top: 1vh;
	}

	@media screen and (max-width: 700px) {
			margin-left:2vh ;
			margin-top: 1vh;
	}

	@media screen and (max-width: 600px) {
			margin-left:18%;
			margin-top: -15vh;
			width: 60vw;
	}

	@media screen and (max-width: 500px) {
			margin-left:15%;
			margin-top: -15vh;
			width: 60vw;
	}

		

	}


	.text-aside--img{
	
	position: relative;
	top: 21vh;
	left: 1vw;
	
	@media screen and (max-width: 1024px) {
			
			margin-left: -4vw;
		}

		@media screen and (max-width: 900px) {
				
				width: 20%;
				top: 13vh;
				margin-left: -2vw;
			}

		@media screen and (max-width: 800px) {
				
				top: 11vh;
				margin-left:-3vw;
				
		}

		@media screen and (max-width: 700px) {
				
				top: 10vh;
				
		}

		@media screen and (max-width: 600px) {
			width: initial;
			margin-left: -16vw;
			top: 21vh;
			
		}

		@media screen and (max-width: 500px) {
			width: 30%;
			margin-left: -10vw;
			top: 13vh;
			
		}


	}

	.text-aside--text{
		color: #521314;
		position: relative;
		left: 23%;
		line-height: 7vh;
		font-size: 2.5rem;

		@media screen and (max-width: 1024px) {
			
			font-size: 2.2rem;
			left: 20%;

		}

		@media screen and (max-width: 900px) {
				
				font-size: 1.7rem;
				line-height:5vh;
				left: 18%;
			}

			@media screen and (max-width: 800px) {
				
				left: 15%;
			}

			@media screen and (max-width: 700px) {
				
				font-size: 1.3rem;
				line-height:4vh;
				
			}

			@media screen and (max-width: 600px) {
				
				font-size: 2rem;
				line-height:6vh;
				
			}
			@media screen and (max-width: 500px) {
				
				font-size: 1.8rem;
				line-height:5vh;
				
			}
			
	}

	.text-aside--content-text{
		position: relative;
		margin-top: 2vh;
		text-align: justify;
		left: 23%;
		line-height: 3vh;

		@media screen and (max-width: 1024px) {
				
			left: 20%;
		}

		@media screen and (max-width: 900px) {
				
			font-size: 0.9rem;
			left: 18%;
		}

		@media screen and (max-width: 800px) {
				
			left: 15%;
		}

		@media screen and (max-width: 700px) {
				
			font-size: 0.7rem;
			line-height:3vh;
				
		}

		@media screen and (max-width: 600px) {
				
			font-size: 1rem;
		}

		@media screen and (max-width: 500px) {
				
			font-size: 0.8rem;
			line-height:3vh;
				
		}

	}


	.img-aside-container{
		background-color: #fff;
		width: 50vw;
		height: 50vh;
		color: #000;
		padding-top: 10vh;
		position: relative;
		

		@media screen and (max-width: 1024px) {

			left: 4%;
		}

		@media screen and (max-width: 800px) {
				
			left: 6%;
		}

		@media screen and (max-width: 700px) {
				
			left: 1%;
		}


		@media screen and (max-width:600px){		

			width: 90vw;
			left: 8%;
			margin-top: -25vh;
			margin-bottom: 20vh;

		}

		@media screen and (max-width:500px){		

		margin-top: -35vh;
		margin-bottom: 20vh;

		}
		
	}

	.img-aside--img{
		width: 70%;
		height: 80%;
		object-fit: cover;
		position: relative;
		border-radius: 0.5vw;
		position: absolute;
		right: 20%;

		@media screen and (max-width:600px){		

			width: 80%;
			right: 8%;
			border-radius: 2vw;

		}

		
	}

	.img-aside-container::before{
		content: " ";
		width: 35vw;
		height: 40vh;
		position: absolute;
		background-color: #f8f6f6;
		border: 0.3vh solid #3D0C0C;
		left:2%;
		top: 4vh;
		border-radius: 0.8vw;

		@media screen and (max-width:600px){		

		width: 72vw;
		border-radius: 2vw;

		}
	}

	

}

.section-slogan{
	
	.section-slogan-text{

		color: #521314;
		font-size: 2.5rem;
		text-align: center;
		margin-top: 10vh;
		margin-bottom: 20vh;
		font-weight: bold;

		@media screen and (max-width: 1024px){
			font-size: 2.3rem;
		}

		@media screen and (max-width: 900px){
			font-size: 1.7rem;
		}

	}

}


.section-plans{

     
      display: grid;
	  grid-template-columns: repeat(auto-fit, minmax(20rem,1fr));
	  gap: 0 -30vw;
      justify-items: center;
      justify-content: center;
      place-items: center;
	  position: relative;
	  margin-bottom: 25vh;

		@media screen and (max-width: 690px){
			gap:0 -4vw;
		}
	  
		@media screen and (max-width: 600px){
			gap:25vh 0vw;
			left: -15%;
		}

	  .section-plans-item{
		width: 30vw;
		height: 50vh;
	  }

	  .section-plans-item--head{

		background-color: #521314;
		width: 30vw;
		height: 8vh;
		text-align: center;
		font-size: 2rem;
		line-height: 8vh;

		@media screen and (max-width: 1024px){
			font-size: 1.8rem;
		}

		@media screen and (max-width: 900px){
			font-size: 1.5rem;
			width: 35vw;
		}

		@media screen and (max-width: 690px){
			width: 33vw;
			font-size: 1.3rem;
		}

		@media screen and (max-width: 600px){
			width: 60vw;
			font-size: 1.5rem;
		}

		
	  }

	  .section-plans-item--body{

		width: 30vw;
		height: 42vh;
		border: 0.1vh solid #000;
		border-top: none;
		padding: 5vh;
		color: #000;

		@media screen and (max-width: 1024px){
			font-size: 0.95rem;
		}

		@media screen and (max-width: 900px){
			font-size: 0.8rem;
			width: 35vw;

		}

		@media screen and (max-width: 700px){
			font-size: 0.75rem;
		}

		@media screen and (max-width: 690px){
			font-size: 0.7rem;
			width: 33vw;
			height: 53vh;
		}

		@media screen and (max-width: 600px){
			font-size: 1rem;
			width: 60vw;
			height: 40vh;
		}

	  }

	  .body-1{

		padding: 10vh;
		padding-left: 5vh;

		@media screen and (max-width: 1024px){
			padding-left: 2.3vh;
		}

		@media screen and (max-width: 700px){
			padding-left: 1.3vh;
			padding-top: 5vh;
		}

	  }

	  .span{

		color: #000;
		line-height: 4vh;
	  }

	  .package{
		padding-top: 8vh;
		text-align: center;
	  }

	  .package-text{

		color: #000;
	  }

	  .package-price{
		color: #521314;
	  }

	  .premium{
		padding-top: 5vh;
	  }
    
  }
	   
`


/*export const Content = styled.div`
   max-width: 1200px;
   width: 96%;
   margin: auto;
   z-index: 0;
`*/