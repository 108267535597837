import styled from "styled-components";

export const Container = styled.div`
@media (max-width: 1024px) {
    *{
     -webkit-user-select: none;
     -khtml-user-select: none;
     -moz-user-select: -moz-none;
     -o-user-select: none;
     user-select: none;
     :no-button, :link, :active:hover { color: inherit; }
    }
    .header{
        width: 100vw;
        font-size: 70%;
        height: 80vh;
        display: flex;
        justify-content: center;
        z-index: 0;
    .bainner{
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        z-index: 2;
        height: 100vh;
            .text-header{
               text-align: center;
               padding: 0px 20px;
                h1{
                    font-size: 3.4rem;
                }
        }
        .cover{
           
             
        .show-info-smortphone{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                position:absolute;
                top: 15%;
                max-width: 90%;
                width: 50%;
            .img-sm{
                display: block;
            }
            .img-bg{
                display: none;
            }
            img{
            
                position: relative;
                padding: 15px;
                top: none;

            }
    
            }
        }
    }
  }
}
@media (max-width:1024px){
    footer {
        grid-area: footer; 
        background:var(--body-color); 
        width: 100vw;
        height: auto;
        font-size: 100%;

        .containner{
            width: 100%;
            max-width: 1200px;
            width: 96%;
            margin: auto;

            .footer-content{
                width: 100%;
                .footer-information{
                    width: 100%;
                   display: grid;
                   grid-template-columns: repeat(4, 1fr);
                   justify-content: center;
                   grid-template-rows: 2;
                    .box{
                        ul{
                        width: 100%;
                        cursor: pointer;
                        h2{
                            font-size:1.2rem;
                            color: #444;
                        }
                        li{
                            list-style: none;
                            margin: 5px;
                            font-size: 15px;
                            padding: 0.5rem 0.5rem;
                            display: block;
                           
                                                    
                            :hover{
                                    color: #d00;
                                    transition: all .5s;
                                    text-decoration: underline;
                                    cursor: pointer;
                            }
                        }
                        .noHover{
                                pointer-events: none;
                            }
                       }
                    }
                    .img-multiple-devices{
                        display: none;
                    }
                    .btn-free-access{
                        display: none;
                    }
                    
                }
                .descrition-footer{
                    padding: 10px;
                    display: flex;
                    flex-direction:column;
                    justify-content: flex-start;

                    .discription{
                        order: 1;
                        width: 100%;
                    }
                }
                
                .social-media{
                    display: block;
                    padding: 10px;
                    justify-content: flex-end;
                    order: 2;
                    span{
                        margin-left: 10px;
                        font-size: 1.5rem;
                    }
                }
                .noHover{
                                pointer-events: none;
                            }
            }
        }
       }
    }


@media (max-width: 768px) {
    *{
     -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
    }
    .header{
        width: 100vw;
        font-size: 70%;
        height: 100vh;
        display: flex;
        justify-content: center;
        z-index: 0;
    .bainner{
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        z-index: 2;
        height: 100vh;
        
            .text-header{
                flex: none;
                display: none;
              
        }
        .mobile-header-title{
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 0%;
                z-index: 3;
                text-align: left;
                padding: 10px 50px;
                width: 100%;
                background: rgba(1,2,9,.9) 50%;
                height: 50%;
                
               h1{
                font-size: 5.5em;
               }
            }  
        .cover{
            z-index: 1;
            max-width: 100%;
            max-height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;
             
        .show-info-smortphone{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                position:absolute;
                top: 15%;
                max-width: 90%;
                width: 60%;
                height: 60%;
               
            .img-sm{
                display: block;
            }
            .img-bg{
                display: none;
            }
            img{
            
                position: relative;
                padding: 15px;
                top: none;
            }
            }
        }
    }
     
         
  }
}
@media (max-width:768px){
      .section-card-mobiles{
        font-size: 60%;
        max-width: 1200px;
        width: 96%;
        height: auto;
        margin: auto;
      
        /* Handle */

        .title{
            flex:none;
            border-radius: 10px;
            margin-left: 20px;
            scroll-snap-align: start;
            margin-top: 10px;
       
        }
    }
}
@media (max-width:768px){
    .section-plans{ 
        font-size: 50%; 
        .plans-descrition{
            .title{
                width: 90%;
                margin: 0px auto;
            }
            .plans {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;
                width: 90%;
                margin: auto;
               
                .plans-card{
                    border-radius: 10px;
                    padding: 2rem 1em;
                    width: 80%;
                    margin: 0px 10px;
                    display: flex;
                    justify-content: center;
                  
                    text-align: left;
                    background: linear-gradient(to bottom, transparent  4%, #3E0B0C 96%);
                    >div{
                       
                    >h3{
                        font-size:2em;   padding: 10px;
                    }
                    h1{
                        font-size:4em;   padding: 10px;
                    }
                    }
                    ul {
                      width: 100%;
                     display: block;
                     flex-direction: column;
                     justify-content: start;
                     align-items:flex-start;
          
                    }
                    ul li{
                    font-size: 15px;
                    list-style: none;
                    padding: 0.5rem 0.5rem;
                    font-weight: bold;
                    cursor: pointer;
                    text-align: left;
                    color: #fff;
                   text-decoration: none;
                   .noHover{
                        pointer-events: none;
                    }
                }
            }
        }
    }

}
}
/******************************************footer responsive/******************************* */ 

  @media (max-width:768px){
    footer {
        grid-area: footer; 
        background:var(--body-color); 
        width: 100vw;
        height: auto;
        font-size: 100%;
        .containner{
            width: 100%;
            max-width: 1200px;
            width: 96%;
            margin: auto;

            .footer-content{
                width: 100%;
             

                .footer-information{
                    width: 100%;
                   display: grid;
                   grid-template-columns: repeat(2, 1fr);
                   justify-content: center;
                   grid-template-rows: 2;
                    .box{
                        ul{
                        width: 100%;
                        cursor: pointer;
                        h2{
                            font-size:1.2rem;
                            color: #444;
                        }
                        li{
                            list-style: none;
                            margin: 5px;
                            font-size: 15px;
                            padding: 0.5rem 0.5rem;
                            display: block;
                         
                            :hover { color: inherit; }
                        }
                        .noHover{
                            pointer-events: none;
                        }
                       }
                    }
                    .img-multiple-devices{
                        display: none;
                    }
                    .btn-free-access{
                        display: none;
                    }
                    
                }
                .descrition-footer{
                    padding: 10px;
                    display: flex;
                    flex-direction:column;
                    justify-content: flex-start;

                    .discription{
                        order: 1;
                        width: 100%;
                    }
                }
                
                .social-media{
                    display: block;
                    padding: 10px;
                    justify-content: flex-end;
                    order: 2;
                    span{
                        margin-left: 10px;
                        font-size: 1.5rem;
                    }

                }
                .noHover{
                        pointer-events: none;
                    }
            }
        }
       }
    }

/******************************************************Mobiles devices************************************ */
@media(max-width:600px){
    *{
     -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
    }
      .main-container{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 100vh auto auto;
        grid-template-areas: "header header header" "main main main" "footer footer footer";
      
      }
  }
  @media(max-width:600px){
      .main-container{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 100vh auto auto;
        grid-template-areas: "header header header" "main main main" "footer footer footer";
      }
  }
/**************************************Responive responve layout tablet**************************** */

    /**************************************Responive mobile layout**************************** */
@media (max-width: 600px) {
    .header{
        width: 100vw;
        font-size: 50%;
        height: 100vh;
        display: flex;
        justify-content: center;
        z-index: 0;
    .bainner{
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        z-index: 2;
        height: 100vh;
            .text-header{
                flex: none;
                display: none;
        }
        .mobile-header-title{
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 5%;
                z-index: 3;
                text-align: left;
                padding: 10px 40px;
                width: 100%;
                background: #000;
                
               h1{
                font-size: 4.5em;
               }
            }  
        .cover{
            z-index: 1;
            max-width: 100%;
            max-height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;
           
             
        .show-info-smortphone{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                position:absolute;
                top: 15%;
                max-width: 90%;
                width: 80%;
                height: 80%;
               
            .img-sm{
                display: block;
            }
            .img-bg{
                display: none;
            }
            img{
            
                position: relative;
                padding: 15px;
                top: none;
            }
    
            }
        }
        .btn-access-test{display:block}
         .btn-access-test{
            position: absolute;
            bottom: 4%;
            display: flex;
            justify-content: center;
            width: 100%;
            z-index: 9999;
            padding: 20px 0px;
     
            button{
                width: 80%;
                z-index: 99999;
                height: 40px;
                color: #111;
                font-weight: bold;
                background-color: #fff;
                border-radius: 5px;
                border: none;
                outline: none;
            }
        }
    }
     .nav-bar .full-menu{
            display: none;
        }
         .nav-bar  .mobile-menu{
                display:block;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                height: 65px;
                display:flex;
                justify-content: space-between;
                align-items:center;
                padding: 20px 30px;
                z-index: 9999;
                :hover { color: inherit; }
                .logo{
                    width: 150px;

                    img{
                        width:100%;
                        } 
                }

                .btn-create{
                    border:none;
                    outline: none;
                    background-color: transparent;
                    cursor: pointer;
                    font-size: 1.2em;
                    :hover { color: inherit; }
                }
                .noHover{
                            pointer-events: none;
                        }
               
        } 
   
  }
.footer-store-header{
    display: none;
}
}

/**************************************nav style responive************************** */
/*******************************************MAIN RESPOSIVE STYLES ***************************** */
@media (max-width:600px){
    main{
        width: 100vw;
        margin:  auto;
    }
 
}

/*******************************section responsive styles ********* */
  @media (max-width:600px){

	.mobile-content-carousel{
		height: 100px;
			width: 400px;
			display: flex;
			align-items: center;
			overflow-y: hidden;
			position: relative;
			width: 100%;
			height: 20%;
	}
      .section-card-mobiles{
        font-size: 60%;
        max-width: 1200px;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
		overflow-x: scroll; /* Hide horizontal scrollbar */
  		overflow-Y: hidden; /* Add vertical scrollbar */
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling:touch;
        z-index: 1;
		background-color: #000;
        ::-webkit-scrollbar {
        width: 1px;
        }
        ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px grey; 
        border-radius: 0px;
        }
        /* Handle */

        .title{
            flex:none;
            border-radius: 10px;
            margin-left: 10px;
            scroll-snap-align: center;
            height: auto;
			
           
            .question-descrition{
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    text-align: left;
                    align-items: flex-start;
                    bottom: 0px;
                    left: 0;
                    z-index: 99;
                  
                    .text-content{
                        width: 100%;
                        padding: 60px 20px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        flex-direction: column;
                        height: 100%;
                        position: absolute;
                        bottom: 0px;
                        background: linear-gradient(to bottom, transparent, #000);
                        text-align: center;
				
                        h1{
                        font-size: 2.6em;
                        }
                        p{
                        font-size: 1.6em;
                        }
                    }
                    h1{
                    width: 100%;
                }
                   

                    .read-more{
                        display:flex;
                        width: 100%;
                        padding: 10px 0px;
                        font-size:1.8em;
                        font-weight: bold;
                        align-items: center;
                        height: 50px;
                }
                    
            }
        }

        .title:nth-child(1){
            justify-content:none;
            flex-direction: none;
            position: relative;
			width: 85%;
            height: 90vh;
            background: linear-gradient(to bottom, transparent  1%, rgba(67, 33, 13,0.8) 98%);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
			border: none;
			background-color: #000;
			
			

            .question-descrition{
                width: 100%;
                position: absolute;
                display: flex;
                justify-content: flex-end;
                align-items: flex-flex-end;
                height: 100%;
                background: linear-gradient(to bottom, transparent  20%, rgba(67, 33, 13,0.8) 80%);
                border-bottom-left-radius:10px;
                border-bottom-right-radius:10px;
                padding-bottom:30px;
                text-align: center;
				position: absolute;
				
                
             }
            .show-info-smortphone{
                justify-content:none;
                flex-direction: none;
                width: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 90%;
                    object-fit: cover;
                }
               }
            }

        .title:nth-child(2){
            justify-content:none;
            flex-direction: none;
            position: relative;
			width: 80%;
            height: 90vh;
            background: linear-gradient(to bottom, transparent  2%, rgba(11, 36, 77, 0.7) 98%);
            display: flex;
            justify-content: center;
            align-items: center;
			position: relative;
			float: none;
			zoom: 1;
		
		
            .question-descrition{
                width: 100%;
                position: absolute;
                display: flex;
                justify-content: flex-end;
                background: linear-gradient(to bottom, transparent  2%, rgba(11, 36, 77, 0.7) 98%);
                height: 100%;
                border-bottom-left-radius:10px;
                border-bottom-right-radius:10px;
                padding-bottom: 20px;
            }
            
                .show-info-smortphone{
                justify-content:none;
                flex-direction: none;
                width: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 90%;
                    object-fit: cover;
                }
               }
        }


        .title:nth-child(3){
            justify-content:none;
            flex-direction: none;
            position: relative;
			width: 80%;
            height: 90vh;
background: linear-gradient(to bottom, transparent  2%, rgba(49, 11, 77, 0.7) 98%);
            display: flex;
            justify-content: center;
            align-items: center;
            .question-descrition{
                width: 100%;
                position: absolute;
                display: flex;
                justify-content: flex-end;
                height: 100%;
                background: linear-gradient(to bottom, transparent  2%, rgba(49, 11, 77, 0.7) 98%);
                border-bottom-left-radius:10px;
                border-bottom-right-radius:10px;
                padding-bottom: 20px;
            }
            
            .show-info-smortphone{
                justify-content:none;
                flex-direction: none;
                width: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 90%;
                    object-fit: cover;
                }
               }
        }

            .title:nth-child(4){
            justify-content:none;
            flex-direction: none;
            height: auto;
            position: relative;
			width: 85%;
            height: 90vh;
            background: linear-gradient(to bottom, transparent  2%, rgba(89, 28, 99, 0.7) 98%);
            margin: auto 10px;

            .question-descrition{
                width: 100%;
                position: absolute;
                display: flex;
                justify-content: flex-end;
                bottom:0px;
                height: 100%;
                background: linear-gradient(to bottom, transparent  2%, rgba(89, 28, 99, 0.7) 98%);
                border-bottom-left-radius:10px;
                border-bottom-right-radius:10px;
                padding-bottom: 30px;
                }
            .show-info-smortphone{
                justify-content:none;
                flex-direction: none;
                width: 100%;
                display: flex;
                justify-content:space-between;
                align-items: center;
                    img{
                    width: 100%;
                    object-fit: cover;
                    }
                }
            }
        
    }
  }
  /********************************SELECTION RESPONIVE COMPUTAR  */

  @media (max-width: 600px) {
    .selection-computer{
        display: none;
  }
}
   
@media (max-width:600px){
    .selection-computer{
        display: none;
    }
}
/**************************************Responive responve layout***************************/

@media (max-width:600px){
    .section-plans{ 
        font-size: 50%; 
        .plans-descrition{
            .title{
                width: 90%;
                margin: 0px auto;
            }
            .plans {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;

                .plans-card{
                    border-radius: 10px;
                    padding: 2rem 1em;
                    width: 90%;
                    margin: 10px auto;
                    background: linear-gradient(to bottom, transparent  4%, #3E0B0C 96%);
                    >div{
                    >h3{
                        font-size:2em;   padding: 10px;
                    }
                    h1{
                        font-size:4em;   padding: 10px;
                    }
                    }
                    ul {
                      width: 100%;
                     display: block;
                     flex-direction: column;
                     justify-content: start;
                     align-items:flex-start;
                    }
                    ul li{
                    font-size: 14px;
                    list-style: none;
                    padding: 0.5rem 0.1rem;
                    font-weight: bold;
                    cursor: pointer;
                    text-align: left;
                    pointer-events: none;
                    :hover{
                           
                            transition: all .5s;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                }
                .noHover{
                        pointer-events: none;
                    }
            }
        }
    }

}
}

/******************************************footer responsive/******************************* */ 


@media (max-width:600px){
    footer {
        grid-area: footer; 
        background:var(--body-color); 
        width: 100vw;
        height: auto;
        font-size: 100%;
        .containner{
            width: 100%;
            max-width: 1200px;
            width: 96%;
            margin: auto;

            .footer-content{
                width: 100%;
                display: block;
                flex-direction: none;
                width: 100%;
                .footer-information{
                    width: 100%;
                    padding: 10px;
                    display: block;
                    justify-content: none;
                    align-items: none;
                    .img-multiple-devices{
                        display: none;
                    }
                    .box{
                       
                      .option{
                        width: 100%;
                        cursor: pointer;
                        margin-left:10px;
                      
                        h2{
                            font-size:1.2rem;
                            color: #444;
                        }
                        li{
                            list-style: none;
                            margin: 5px 0px;
                            font-size: 15px;
                            display: block;
                            margin-left: 0px;
                            padding: 2px 0px;
                         
                            :hover{
                                    color: #d00;
                                    transition: all .5s;
                                    text-decoration: underline;
                                    cursor: pointer;
                            }
                        }
                       }
                    }
                    
                    .box-init{
                        display: none;
                    }
                    .btn-free-access{
                        display: none;
                        button{
                            display: none;
                        }
                    }
                }
                .descrition-footer{
                 padding: 0.5rem 0.5rem;
                    display: block;
                    flex-direction:column;
                   

                    .discription{
                        order: 2;
                        width: 100%;
                    }
                }
                
                .social-media{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                }
            }

            .terms{
              width: 90%;
              display: block;
              justify-content: none;
              align-items: none;
              margin:auto;
              padding: 30px;
              ul{
                display:flex;
                flex-direction: column;
                justify-content: center;
                display: flex;
             
                li{
                  margin: 0px;
                  list-style: none;
                  font-size: 10px;
                  width: 100%;

                }
              }
          .footer-copyrith{
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            span{
              font-size:12px;
            }
          
        
          }
          .footer-copyrith{
            span{
              font-size:12px;
            }
          }
        }
      }
    }
}
@media (max-width: 414px) {
        .header{
            width: 100vw;
            font-size: 50%;
            height: 100vh;
            display: flex;
            justify-content: center;
            z-index: 0;
        
        .bainner{
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            z-index: 2;
            height: 100vh;
                .text-header{
                    flex: none;
                    display: none;
                    
            }
            .mobile-header-title{
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: 0%;
                    z-index: 3;
                    text-align: left;
                    padding: 10px 40px;
                    width: 100%;
                    background: #000;
                    height: 40%;
                h1{
                    font-size: 4em;
                }
                }  
            .cover{
                z-index: 1;
                max-width: 100%;
                max-height: 100vh;
                width: 100%;
                display: flex;
                justify-content: center;
                position: relative;
                
            .show-info-smortphone{
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    position:absolute;
                    top: 15%;
                    max-width: 90%;
                    width: 80%;
                    height: 80%;
                
                .img-sm{
                    display: block;
                }
                .img-bg{
                    display: none;
                }
                img{
                
                    position: relative;
                    padding: 15px;
                    top: none;
                }
        
                }
            }
            .btn-access-test{display:block}
            .btn-access-test{
                display: none;
            }
        }
        .footer-store-head{
            width: 100%;
            padding: 0px;
            .footer-containner{
                width: 100%;
                padding: 0px 20px;
                display: flex;
                justify-content: center;
            }
        }
    }
}
@media (max-width:414px){
    .section-plans{ 
        font-size: 50%; 
        .plans-descrition{
            .title{
                width: 90%;
                margin: 0px auto;
            }
            .plans {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                .plans-card{
                    border-radius: 10px;
                    padding: 2rem 1em;
                    width: 90%;
                    margin: 10px auto;
                    >div{
                    >h3{
                        font-size:2em;   padding: 10px;
                    }
                    h1{
                        font-size:4em;   padding: 10px;
                    }
                    }
                    ul {
                      width: 100%;
                     display: block;
                     flex-direction: column;
                     justify-content: start;
                     align-items:flex-start;
                    
                    }
                    ul li{
                    font-size: 16px;
                    list-style: none;
                    padding: 0.5rem 0.1rem;
                    font-weight: bold;
                    cursor: pointer;
                    text-align: left;
                    display: flex;
                    width: 100%;
                  
                    position: relative;
                    .span{
                        display: flex;
                        align-items: center;
                        width: 80%;
                        position: absolute;
                        margin-left: 24%;
                        margin-right: 24%;
                    }
                    :hover{
                            transition: all .5s;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                }
            }
        }
    }

}
}

@media (max-width: 411px) {
        .header{
            width: 100vw;
            font-size: 50%;
            height: 100vh;
            display: flex;
            justify-content: center;
            z-index: 0;
        
        .bainner{
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            z-index: 2;
            height: 80vh;
        
        

                .text-header{
                    flex: none;
                    display: none;
                    
            }
            .mobile-header-title{
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: 0%;
                    z-index: 3;
                    text-align: left;
                    padding: 10px 40px;
                    width: 100%;
                    background: #000;
                    height: 32%;
                
                h1{
                    font-size: 4.5em;
                }
                }  
            .cover{
                z-index: 1;
                max-width: 100%;
                max-height: 100vh;
                width: 100%;
                display: flex;
                justify-content: center;
                position: relative;
                
            .show-info-smortphone{
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    position:absolute;
                    top: 15%;
                    max-width: 90%;
                    width: 80%;
                    height: 80%;
                
                .img-sm{
                    display: block;
                }
                .img-bg{
                    display: none;
                }
                img{
                
                    position: relative;
                    padding: 15px;
                    top: none;
                }
        
                }
            }
           
            .btn-access-test{display:block}
            .btn-access-test{
                display: none;
            }
        }
        
    }
}


@media (max-width: 375px) {
        .header{
            width: 100vw;
            font-size: 50%;
            height: 100vh;
            display: flex;
            justify-content: center;
            z-index: 0;
        
        .bainner{
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            z-index: 2;
            height: 80vh;
        

                .text-header{
                    flex: none;
                    display: none;
                    
            }
            .mobile-header-title{
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: 8%;
                    z-index: 3;
                    text-align: left;
                    padding: 10px 40px;
                    width: 100%;
                    background: #000;
                    height: 25%;
                h1{
                    font-size: 3.5em;
                    margin-top: 20px;
                }
                }  
            .cover{
                z-index: 1;
                max-width: 100%;
                max-height: 100vh;
                width: 100%;
                display: flex;
                justify-content: center;
                position: relative;
                
            .show-info-smortphone{
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    position:absolute;
                    top: 15%;
                    max-width: 90%;
                    width: 80%;
                    height: 80%;
                
                .img-sm{
                    display: block;
                }
                .img-bg{
                    display: none;
                }
                img{
                
                    position: relative;
                    padding: 15px;
                    top: none;
                }
        
                }
            }
            .btn-access-test{display:none}
            .btn-access-test{
                display: none;
            }
        }
        
    }
}
@media (max-width:375px){
    .section-plans{ 
        font-size: 50%; 
        .plans-descrition{
            .title{
                width: 90%;
                margin: 0px auto;
            }
            .plans {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                .plans-card{
                    border-radius: 10px;
                    padding: 2rem 1em;
                    width: 90%;
                    margin: 10px auto;
                    >div{
                    >h3{
                        font-size:2em;   padding: 10px;
                    }
                    h1{
                        font-size:4em;   padding: 10px;
                    }
                    }
                    ul {
                      width: 100%;
                     display: block;
                     flex-direction: column;
                     justify-content: start;
                     align-items:flex-start;
                    
                    }
                    ul li{
                    font-size: 16px;
                    list-style: none;
                    padding: 0.5rem 0.1rem;
                    font-weight: bold;
                    cursor: pointer;
                    text-align: left;
                    display: flex;
                    width: 100%;
                  
                    position: relative;
                    .span{
                        display: flex;
                        align-items: center;
                        width: 80%;
                        position: absolute;
                        margin-left: 18%;
                        margin-right: 18%;
 
                    }

                    :hover{
                         
                            transition: all .5s;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                }
            }
        }
    }

}
}


@media (max-width: 360px) {
        .header{
            width: 100vw;
            font-size: 50%;
            height: 100vh;
            display: flex;
            justify-content: center;
            z-index: 0;
        
        .bainner{
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            z-index: 2;
            height: 80vh;
        

                .text-header{
                    flex: none;
                    display: none;
                    
            }
            .mobile-header-title{
                    display: flex;
                    align-items: center;
                    position: absolute;
                    z-index: 3;
                    text-align: left;
                    padding: 20px 40px;
                    width: 100%;
                    background: #000;
                    height: 25%;
                    
                h1{
                    font-size: 4em;
                    margin-top: 50px;
                }
                }  
            .cover{
                z-index: 1;
                max-width: 100%;
                max-height: 100vh;
                width: 100%;
                display: flex;
                justify-content: center;
                position: relative;
                
            .show-info-smortphone{
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    position:absolute;
                    top: 15%;
                    max-width: 90%;
                    width: 80%;
                    height: 80%;
                   
                
                .img-sm{
                    display: block;
                }
                .img-bg{
                    display: none;
                }
                img{
                
                    position: relative;
                    padding: 15px;
                    top: none;
                }
        
                }
            }
            .btn-access-test{display:none}
            .btn-access-test{
                display: none;
            }
        }
        
    }
}

@media (max-width: 320px) {
        .header{
            width: 100vw;
            font-size: 50%;
            height: 100vh;
            display: flex;
            justify-content: center;
            z-index: 0;
        
        .bainner{
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            z-index: 2;
            height: 80vh;
            .text-header{
                flex: none;
                display: none;
                    
            }
            .mobile-header-title{
                    display: flex;
                    align-items: center;
                    position: absolute;
                    z-index: 3;
                    text-align: left;
                    padding: 10px 40px;
                    width: 100%;
                    background: #000;
                    height: 25%;
                    
                h1{
                    font-size: 3.2em;
                    margin-top: 50px;
                }
                }  
            .cover{
                z-index: 1;
                max-width: 100%;
                max-height: 100vh;
                width: 100%;
                display: flex;
                justify-content: center;
                position: relative;
                
            .show-info-smortphone{
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    position:absolute;
                    top: 15%;
                    max-width: 90%;
                    width: 80%;
                    height: 80%;
                
                .img-sm{
                    display: block;
                }
                .img-bg{
                    display: none;
                }
                img{
                
                    position: relative;
                    padding: 15px;
                    top: none;
                }
        
                }
            }
            .btn-access-test{display:none}
            .btn-access-test{
                display: none;
            }
        }
        
    }
}
`