import React from 'react';
import Styled from 'styled-components';
import { UserType } from '../../types';
import Login from '../login-page/Login';
import AccountOverview from './AccountOverview';
import UserPlan from './UserPlan';

const Profile = (): JSX.Element => {
    const user = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;
	
    if(user === null){
        return(
            <Login />
        )
    }
    return (
        <>
        <AsideLeft />
        <Container>
            <AccountOverview />
            <UserPlan />
        </Container>
        </>
    );
};

const AsideLeft  = Styled.div`
    width:15%;
	@media (max-width: 768px) {
		width:0;
		
	}
`;

const Container = Styled.div`
    width:85%;
	@media (max-width: 768px) {
		width:100%;
	}
    
`;

export default Profile;
