import styled from "styled-components";

export const Container  = styled.div`
	min-height: 100vh;
	background: #000;

	.header-container {
		min-height: 160px;
	}

	.login-container {
		display: flex;
		/* justify-content: center; */
		align-items: center;
		flex-direction: column;
		width: 100%;
		min-height: 80vh;

		h1 {
			text-transform: uppercase;
			font-size: 25px;
			/* margin: 20px 0px; */
		}

		p {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 10px;
			text-align: center;
			hr {
				margin: 0 10px;
			width: 80px;
			}
		}



		.login-field {
			display: flex;
			/* justify-content: center; */
			align-items: center;
			flex-direction: column;
			max-width: 400px;
			width: 100%;
			min-height: 300px;
			border-radius: 30px;
			
			.login-provider {
				cursor: pointer;
				display: flex;
				justify-content: center;
				margin: 10px 0px;
				height: 65px;
				width: 80%;
				background: #000;
				border: 1px solid #fff;
				border-radius: 10px;
				box-shadow: 0px 0px 4px 4px rgba(20,20, 20, .9);

				&:hover {
					filter: brightness(0.8);
				}

				p {
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 15px;
					svg {
						font-size: 20px;
						margin: 0 10px;
					}
				}


			}
			.apple-provider{
				background:#333333;
			}

			form {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				width: 100%;

				.mobile-field {
					
					display: flex;
					justify-content: center;
					align-items: center;
					height: 65px;
					border-radius: 8px;
					display: flex;
					background-color: #fff;
					width: 80%;


					 p {
						 
						color: #333;

						svg {
							margin: 0 5px;
						}
					 }

					 input {
						 border: 0;
						 height: 50px;
						 background-color: transparent;
						 width: 80%;
						color: #222;
					 }
				}

				.btn-send {
					text-transform: uppercase;
					margin: 10px 0;
					width: 80%;
					height: 50px;
					background-color: #f01;
					border-radius: 8px;
					border: 0;
				}
			}
		}

		
	}
`;

