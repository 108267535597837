import React from "react";
import { Switch, Route } from "react-router-dom";
import artistHome from "../pages/artist-pages/artistHome";
import Building from "../pages/building";
import Eula from "../pages/eula";
import Home from "../pages/home";
import Terms from "../pages/terms-privacy";
import Store from "../pages/store-page/Store";
import Login from "../pages/login-page/Login";
import Paymentplans from "../pages/dashboard/Paymentplans";
import Dashboard from "../pages/dashboard/Dashboard";
import About from "../pages/about/about";


const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/store" exact component={Store} />
    <Route path="/login" exact component={Login} />
	<Route path="/eula" exact component={Eula} />
	<Route path="/terms-and-privacy" exact component={Terms} />
	<Route path="/artist/home" exact component={artistHome} />
	<Route path="/about" exact component={About} />
	<Route path="/building-page" exact component={Building} />
	<Route path="/dashboard/profile" exact component={Dashboard} />
	<Route path="/dashboard/paymentplans" exact component={Paymentplans} />
  </Switch>
);
export default Routes;

