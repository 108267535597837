import React from 'react';
import styled from 'styled-components';
import {AiFillCheckCircle} from 'react-icons/ai';

interface Props{
    daysLeft: number
}

const CurrentUserPlan = ({daysLeft}: Props): JSX.Element => {

    /*const SECONDS_IN_DAY = 86400;
    const _30Days = daysLeft;
    const secondsSinceEpoch = Math.round(Date.now() / 1000);
    const expirationTime = secondsSinceEpoch + _30Days * SECONDS_IN_DAY;

    const d = new Date(expirationTime);
    const numberOfDays = Math.floor(daysLeft);
    const numberOfHours = d.getHours();*/

	const date = new Date(daysLeft * 1000);

    return (
        <>
            <AsideLeft />
            <Container>
                <h3>Assinatura e Pagamento</h3>
                <div className='current_plan'>
                    <div className='circle-nameplane'><div><AiFillCheckCircle className='circle_icon'/></div> <div><span>ToquePlay Premium</span></div></div>
                    <div className='text-info'>
                        <p>Sua assinatura terminara no dia {date.toLocaleDateString()}<span>
                        </span></p>
                    </div>
                </div>
            </Container>
        </>
    );
};

const AsideLeft = styled.div`
    width: 15%;
	@media (max-width: 768px) {
		width: 0;
	}

`;


const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #eee;

    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    h3{
        font-weight: 400;
        font-size:4em;
        color: #4aa85f;
        border-bottom: 1px solid #dddcdc;
    };
    .current_plan{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        height: 300px;
        width:80%;
        margin: 0 auto;
        .circle_icon{
            font-weight: bold;
            font-size: 4em;
            color: #4aa85f;
        }
        .circle-nameplane{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        span{
            font-size: 1.9em;
            font-weight:400;
            color: #039121;
        }
        p{
            margin: 0 auto;
            font-size: 2em;
            color: #333;
            margin-top:1em;
            line-height: 1.5em;
            text-align:center;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            span{
                font-size: 1em;
                font-weight:bold;
                color: #000;
            }
        }
    }

	@media (max-width: 768px) {
		display: block;
		h3{
        font-weight: 400;
        font-size:2em;
        padding: 0.5em;
		text-align: center;
		padding-top: 1em;
		padding-bottom: 2em;
    	};
		.current_plan{
			width: 100%;
			padding: 1em;
		}
	}

`;
export default CurrentUserPlan;