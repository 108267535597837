import styled from 'styled-components'
//import bainner_header from '../../assets/bainner.jpg';
export const FullContainer = styled.div`
   .main-container{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 100vh auto auto;
    grid-template-areas: "header header header" 
                          "main main main" 
                          "footer footer footer";
                        
   }
   .header{
      grid-area: header;      
      background-position:center;
      background-size:cover;
      background-repeat:no-repeat;
      background: rgb(255,42,0);
      background: radial-gradient(circle, rgba(255,42,0,1) 0%, rgba(181,31,3,1) 0%, rgba(107,19,6,1) 0%, rgba(46,9,8,1) 31%, rgba(26,6,9,1) 46%, rgba(1,2,10,1) 61%); 
      height: 100vh;
      width: 100%;
      z-index: 0;
      position: relative;
      background-color: #f00;
    
      .bainner{
       display:flex;
       flex-direction:row;
       background: linear-gradient(to bottom, transparent  2%, #01020A 98%);
       height:100%;
       margin: auto;
       flex: 1;
       width: 70%;
       z-index: 1;
      }
      .text-header{
         flex: 1;
         display: flex;
         justify-content: center;
         align-content: flex-end;
         align-items: center;
         width: 50%;
         h1{
           min-width: 500px;
           max-width: 500px;
           max-block-size: 500px;
           font-size:3.5rem;
           font-weight: 800;
           font-weight: bold;
           left: left;
           font-family: 'Roboto', sans-serif;
           text-shadow:1px 5px rgba(1,2,10,1);
         }
       }
       .mobile-header-title{
         display: none;
       }
      .cover{
       flex: 1;
       display: flex;
       align-items: end;
       justify-content: flex-end;
       width: 50%;
        .show-info-smortphone{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height:100%;
            max-width: 1200px;
            width: 70%;
            .img-sm{
            display: none;
          }
          .img-bg{
            display: block;
            display: flex;
            align-items: end;
            
          }
          img{
            width: 100%;
          }
 
        }

      }

      .btn-access-test{display:none}
      
        .footer-store-head{
          display: flex;
          padding: 10px 50px;
          z-index: 9999999;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;

          .footer-containner{
            width: 100%;
            
            display: flex;
            align-items: center;


            .store-icon{
              width: 140px;
              cursor: pointer;
              :hover{
                opacity: .5;
                transition: all ease .2s;

              }

            }
            .icon-android{
              width: 110px;
            }
           
          }
        }  

      }
   /***********************************MAIN STYLES */
   main{
    grid-area: main;
    z-index: 99;
    margin: auto;
    background: #000000;
    background: radial-gradient(circle, #000000 0%,#000000 0%, #000000  0%, #000000 40%, rgba(26,6,9,1) 46%, rgba(1,2,10,1) 61%);
    width: 100%;
  }
  .section-card-mobiles{
      max-width: 1200px;
      width: 96%;
      display: flex;
      flex-direction: column;
      margin: 1% auto;
      z-index: 9999;
      height: auto;
	  .mobile-content-carousel{
		  display: none;
	  }
      .read-more{
        display: none;
      }
      .title{
        display: flex;
        margin: 2% auto;
        padding: 2rem;
        height: 100vh;
      }
      .title:nth-child(1){
        justify-content:space-between;
        flex-direction: row;
        width: 100%;
    
     
    .question-descrition{
      width: 50%;
      order: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      h1{
        font-size: 3em;
      }
      >p{
        font-size: 1.5em;
      }
    }
      .show-info-smortphone{
        width: 50%;
        order: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          width: 50%;
        }
      }

     }
    .title:nth-child(2){
      justify-content:space-between;
      flex-direction: row;
      width: 100%;
  
    .question-descrition{
      width: 50%;
      order: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      h1{
        font-size: 3em;
      }
      >p{
        font-size: 1.5em;
      }
    }
      .show-info-smortphone{
        width: 50%;
        order: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          width: 50%;
        }
      }

    }
      .title:nth-child(3){
      justify-content:space-between;
      flex-direction: row;
      width: 100%;
     
      .question-descrition{
          width: 50%;
          order: 2;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          h1{
            font-size: 3em;
          }
          >p{
            font-size: 1.5em;
          }
        }
        .show-info-smortphone{
            width: 50%;
            order: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img{
              width: 50%;
            }
          }

        }
        .title:nth-child(4){
          justify-content:space-between;
          flex-direction: row;
          width: 100%;
          height: 100vh;
         
      .question-descrition{
          width: 45%;
          order: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          h1{
            font-size: 3em;
          }
          >p{
            font-size: 1.5em;
          }
        }
        .show-info-smortphone{
            width: 50%;
            order: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 100%;
            }
          }

        }
  }
  .selection-computer{
	  display: none;
	  /*
        display: flex;
        justify-content:space-between;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100vh;
      .title{
          max-width: 1200px;
          width: 96%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin: auto;
          padding: 50px;
          .question-descrition{
              width: 100%;
              padding: 40px 0px;
            h1{
              font-size: 3em;
              color: #fff;
              padding: 30px;
            }
            >p{
              font-size: 1.5em;
            }
        }
        .show-info-smortphone{
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 1200px;
            img{
              width: 70%;
            }
          }
        }
		*/
  }

  .section-plans{
      max-width: 1200px;
      width: 96%;
      display: flex;
      flex-direction: column;
      margin: auto;
    .plans-descrition{
          width: 100%;
          padding: 30px 0px;
          height: auto;
          .title{
              h1{
                color: #fff;
                font-size: 2.5em;
                font-weight: bold;
              }
          }
          .plans {
              display: flex;
              justify-content: space-evenly;
              padding: 30px 0px;
            
             
              .plans-card{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                    transform: translateY(-30%);
                    height: auto;
                    box-shadow: rgba(0,0,0,0.5);
                    animation-name: slider;
                    opacity: 0;
                    animation-duration: 2s;
                    animation-fill-mode: forwards;
                    width: 38%;
                    padding: 10px;
                    border-radius:10px;
                    position: relative;
                    ul {
                      width: 100%;
                     display: flex;
                     flex-direction: column;
                     justify-content: center;
                     align-items: center;
                    }
                    :hover{
                      background: linear-gradient(to bottom, transparent  4%, #3E0B0C 96%);
                    }
                   
                   .package{
                      display: flex;
                      flex-direction: column;
                      margin-top:15px;
                      padding: 10px;
                      align-items: center;
                      width: 100%;
                        h3{
                          color: #fff;
                          text-align: center;
                          font-size:1.5em;
                      }
                      h1{
                        color: #d00;
                        text-align: center;
                        font-size: 2.5em;
                        font-weight: bold;
                      }
                      
                    }
                    .animation-play{
                      width: 100%;
                      height: auto;
                      svg{
                        width: 100%;
                      }
                    }
                    ul{
                      text-align: left;
                    }
                    .primium{
                        opacity: 0.4;
                    }
                    ul li{
                      font-size: 18px;
                      list-style: none;
                      color: #fff;
                      padding: 0.5rem 1rem;
                      font-weight: bold;
                      .span{
                          margin-left: 20px;
                      }
              }
          }
      }
    }
    @keyframes slider{
        from {
            opacity: 0;
        }
        to{
          transform: translateY(0);
          opacity: 1;
         
        }
      }
  }

/**************************************************************footer***************************** */
  
  `

  export const Menu = styled.div`
    
  `


