import React from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { FaCreditCard,FaCompactDisc, FaHome, FaUserCircle } from 'react-icons/fa';
import Styled from 'styled-components';

import { UserType } from '../../types';
import Login from '../login-page/Login';
import { useState } from 'react';


const Header = (): JSX.Element => {
	const user = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;
	const [showMenu, setShowMenu] = useState(false);

	if (user === null) {
		return (
			<Login />
		)
	}

	return (
		<div>
			<HeaderStyles>
				<a href='/'><img className="logo" src={"/logotipo.svg"} alt='Logotipo Toqueplay' /></a>
				<div className='user-container'>

					{
						user?.displayName !== null ? user?.displayName :
							user.displayName
					}

					{
						user?.photoUrl !== null ?
							<img className="user-picture" src={user?.photoUrl} alt='Foto' height={50} width={50} />
							:
							<FaUserCircle size={40} className="user-icone" />

					}
				</div>

				<div className="mobile-menu" onClick={()=> setShowMenu(true)}>
					<AiOutlineMenu size={30} />
				</div>


			</HeaderStyles>
			{
				showMenu && (
					<ListMenuMobile id='menu-mobile'>

						<div className="container">
							<div>
								<div>
									{
										user?.photoUrl !== null ?
											<img className="user-picture" src={user?.photoUrl} alt='Foto' height={50} width={50} />
											:
											<FaUserCircle size={60} className="user-icone" />

									}
								</div>
								<div className='current-user'>
								{
										user?.displayName !== null ? user?.displayName :
											user.displayName
								}
								</div>
								<ul>
									<li className='profile_active'>
										<a href='/dashboard/profile'>
											<FaHome size={25} color='#ff2a00'/>
											<span>Perfil</span>
										</a>
									</li>
									<li className='payment_plans_active'>
										<a href='/dashboard/paymentplans'>
											<FaCreditCard size={25} color='#ff2a00'/>
											<span>Pagamentos</span>
										</a>
									</li>
									<li className='payment_plans_active'>
										<a href='/store'>
											<FaCompactDisc size={25} color='#ff2a00'/>
											<span>Praça da Independência</span>
										</a>
									</li>
								</ul>
								<div className="close-button" onClick={()=> setShowMenu(false)}>
									x
								</div>
							</div>
						</div>
					</ListMenuMobile>
				)
			}

		</div>
	);
};

const ListMenuMobile = Styled.div`
@media (max-width: 768px) {
	.close-button{
		font-size: 2em;
		font-weight:bold;
		background: #ff2a00;
		margin-top: 1em

	}
	.container{
		padding: 0 2rem;
		background: rgba(0, 0, 0, 1.10);
		height: 100vh;
		display: flex;
		flex-direction:'column';
		justify-content: space-between;
		position: fixed;
		align-items: center;
		top: 0;
		right: 0;
		left: 0;
		z-index: 2000;
		width: 100%;

		ul {
        list-style: none;
		width:100%;
		margin-top:1em;
		
        li {
            padding: 0.8rem 0;
            cursor: pointer;
			border-bottom:1px solid #999;
			margin-bottom:1em;
            &:hover {
                background: #222;
            }

            &:hover a {
                color: #ff2a00;
            }
			

            a {
                color: #fff;
               
                text-decoration:none;
                display: flex;
                align-items: center;
				font-size:1em;
                span {
                    margin-left: 0.5rem;
                }
            }
        }
    }
		}
	div{
		text-align:center;
		
		width:100%;

	}
	.current-user{
		font-size:2em;
		text-transform: capitalize;
	}
		
	}
	
`;

const HeaderStyles = Styled.header`
  padding: 0 2rem;
  background: #111;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  position: fixed;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
	z-index: 1030;
  width: 100%;

  .mobile-menu{
	display: none
  }
  .logo {
    width: 150px;
  }
  .user-picture{
	  border-radius:50%;
  }

  .user-container{
    display: flex;
    justify-content: center;
    align-items:center;
	font-size:1.2em;
	ul{
		li{
			display:inline-block;
			margin-right:2em;
			a {
            	color: #ff2a00;
				&:hover{
					color: #fff;

				}
        	}
		}
	}
	.user-icone,.user-picture{
		margin-left:1rem;
		border: 1px solid  #999;
	}
  }

  @media (max-width: 768px) {
	.user-container{
		display:none
	}
	.mobile-menu{
	display: block
  }
		
	}
`;

export default Header;
