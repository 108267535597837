import styled from "styled-components";

export const Container = styled.div`
	min-height: 100vh;
	background-color: #000;
	width: 100%;

	.store-container {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding: 40px 60px;
		margin: 0 auto;
		padding-top:10%;

		@media (max-width: 768px) {
			padding: 0;
			padding-bottom:20%;
			
		}
		
		h1 {
			font-size: 28px;
			font-weight: bold;
			text-transform: uppercase;
			text-align: center;
			@media (max-width: 768px) {
				font-size: 18px;
				text-align: center;
				width: 100%;
				margin-top: 2em;
			}
		}

		.albums-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: left;
			max-width: 100%;
			min-height: 400px;
			margin: 20px auto;
			

			@media (max-width: 768px) {
				width: 100%!important;
				max-width: 100%;
				margin: 0;
				
			}

			.album-card {
				margin: 10px 15px;
				background: rgba(69, 69, 69, .25);
				width: 250px;
				min-height: 360px;
				cursor: pointer;
				border-radius: 8px;
				.album-img {
					padding: 10px;
					img {
						border-radius: 4px;
						width: 100%;
						height: 220px;
						@media (max-width: 768px) {
							height: 320px;
						}
					}
				}
				@media (max-width: 768px) {
					width: 100%;
					
				}

				.album-info {
					height: 105px;
					padding: 10px;
					margin: 5px 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					h2, h3 {
						text-align: center;
						font-size: 16px;
						color: #dddd;
					}
					h3 {
						
					}

					p {
						color: #696969;
						font-weight: bold;
						font-size: 18px;
					}
				}
				
				.buy-btn {
					display: flex;
					justify-content: center;	
					margin-bottom:  10px;
					button {
						display: flex;
						justify-content: center;
						align-items: center;
						border: 1px solid #fff;
						background: transparent;
						color: #cdcdcd;
						height: 45px;
						width: 150px;
						border-radius: 3px;
						text-transform: uppercase;

						svg {
							font-size: 20px;
							margin: 0 5px;
						}
					}
				}
			}

			
		}



		header{
			display: flex;
			justify-content: space-between;
			width:100%;
			border-bottom:1px solid #222;
			padding:2em;
			padding-top:0;

			form{
				display: flex;
				justify-content: center;
				align-items: center;
				width:40%;
				position:relative;
			
				input{
					height: 40px;
					width:80%;
					border:none;
					color:#000;
					padding:7px 12px;
				}
				button{
					width:20%;	
					display: flex;
					justify-content: center;
					align-items: center;
					background: transparent;
					color: #cdcdcd;
					height: 40px;
					width: 150px;
					border-radius: 3px;
					text-transform: uppercase;
					background-color:#fa1200;
					border:none;
					&:active{
						transform:translateY(5px)
					}
				}
			}
		}
	}
`;