import React, { useEffect } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { firestore } from '../../firebase/config';
import { getCurrentUserPlan } from '../../services/server';
import { UserType } from '../../types';
//import { useState } from 'react';


const UserPlan = (): JSX.Element => {
    const currentPlan = JSON.parse(localStorage.getItem('currentPlan') as string); 
    const user = JSON.parse(localStorage.getItem('userToquen') as string) as UserType;

	useEffect(() => {
		const query =	getCurrentUserPlan(user.phoneNumber as string);

		const unsubscribe = firestore.onSnapshot(query, async (querySnapshot) => {
	
			if (!querySnapshot.empty) {
				localStorage.setItem('currentPlan', JSON.stringify({
					plan: 'premiunPlan',
					user: user.phoneNumber
				}));

			} else {
				localStorage.removeItem('currentPlan');
			}
	
		});
		return () => {
			unsubscribe();
		};
		
		}, [user.phoneNumber]);
    return (
        <div>
            {
                currentPlan ?
                    <PremiumPlan>
                        <h3>Plano Activo</h3>
                        <div className='current_plan'>
                            <div className='circle-nameplane'>
                                <div><AiFillCheckCircle className='circle_icon' /></div>
                                <div>
                                    <span>ToquePlay Premium</span>
                                </div>
                            </div>
                        </div>
                    </PremiumPlan>
                    :
				   <Container>
				   <h3>Plano Activo</h3>
				   <div className='toqueplay-plan'>
					   <div className='premium_plan'>ToquePlay Freemium</div>
					   <div className='current-plan-details'>
						   <p className='plan_benefits'>Reproduzir músicas e tem anúncios</p>
						   <div className='plan_name'>Grátis</div>
					   </div>
				   </div>
				   <button> <a href={'/dashboard/paymentplans'}>Junte-se ao Premium</a></button>
			   </Container>
				
            }
            <br/>
            <br/>

        </div>
    );
};

const PremiumPlan = styled.div`
    width: 100%;
    background-color: #eee;
    padding:1em;
    padding-bottom:2em;

    h3{
        font-weight: 400;
        font-size:2em;
        color: #000;
        border-bottom: 1px solid #dddcdc;
    };
	@media (max-width: 768px) {
		margin-top: 2em;
		height: 100vh;
		.current_plan{
			.premium_plan{
				text-align: center;
			}
		}
	}
    .current_plan{
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        background-color: #fff;
        height: 200px;
        width:100%;
        .circle_icon{
            font-weight: bold;
            font-size: 5em;
            color: #4aa85f;
        }
        .circle-nameplane{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
        }
        span{
            font-size: 2em;
            font-weight:400;
            color: #039121;
        }
    }
`;
const Container = styled.div`
    padding: 1em;
	padding-top: 0;

	
    h3{
        font-size: 1.4rem;
        font-weight: bold;
        color:black;
    }
	@media (max-width: 768px) {
		margin-top: 2em;
		height: 100vh;
		h3{
        text-align: center;
		width: 100%;
    	}
	}
    .premium_plan{
        background-color: #ddd;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        color: #000;
        font-weight: bold;
        font-size: 4em;
        padding-left: 0.9rem;
        margin-top: 0.5em;
    }
    .current-plan-details{
        padding-top:3em;
        padding-left:3em;
        border: 1px solid #ccc;
        p{
            font-size:1.2em;
            font-weight:bold;
            margin-bottom: 2em;
            color: #444;

        }
        .plan_name{
            font-size:2.5em;
            font-weight:bold; 
            margin-bottom: 1em;
            color: #444;

        }

    }

     button{
        display: inline-block;
        padding: 1em 2em;
        color: white;
        margin-top: 1em;
        margin-bottom: 5em;

        font-size: 1.3em;
        font-weight: bold;
        vertical-align: middle;
        background-image: none;
        border: 1px solid #999;
        cursor: pointer;
        background-color: transparent;
        border-radius: 2em;
        text-decoration:none;

        &:hover {
            background: #eee;
        }
        &:active{
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -o-transform: translateY(5px);
        }
    }
    a{
        text-decoration:none;
        color: black;
    }
`;

export default UserPlan;