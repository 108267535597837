import styled from 'styled-components'
//import bainner_header from '../../assets/bainner.jpg';
export const Container = styled.div`
@media (max-width: 768px) {
	.eula-content{
	   color: #000;
	   padding: 10px 20px;
	 
	   h2{
		   color: #555;
		   font-size: 30pt;
		   padding: 10px 0px;
	   }
	   h3{
		   color: #555;
		   font-size: 20pt;
		   padding: 10px 0px;
	   }
	   p{
		   text-align: justify;
		   font-size:12pt;
	   }
   }
}

@media (max-width: 600px) {
	.header{
		.nav-bar  .mobile-menu{
                display:block;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                height: 65px;
                display:flex;
                justify-content: space-between;
                align-items:center;
                padding: 20px 30px;
                z-index: 9999;
                :hover { color: inherit; }
                .logo{
                    width: 150px;

                    img{
                        width:100%;
                        } 
                }

                .btn-create{
                    border:none;
                    outline: none;
                    background-color: transparent;
                    cursor: pointer;
                    font-size: 1.2em;
                    :hover { color: inherit; }
                }
                .noHover{
                            pointer-events: none;
                        }
               
        } 
	}
    .eula-content{
	   color: #000;
	   padding: 10px 20px;
	   h1{
		color: #555;
		   font-size: 25pt;
		   padding: 10px 0px;
	   }
	   h2{
		   color: #555;
		   font-size: 20pt;
		   padding: 10px 0px;
	   }
	   h3{
		   color: #555;
		   font-size: 20pt;
		   padding: 10px 0px;
	   }
	   p{
		   text-align: left;
		   font-size:12pt;
	   }
   }
}

  `
