import styled from "styled-components";

export const Container = styled.footer<{background?:string}>`
/**************************************************************footer***************************** */
       font-size: 100%;
        grid-area: footer; 
        width: 100vw;
        display: flex;
        align-items: flex-end;
		background-color: ${(props) => (props.background? props.background : "#101012")};
        padding: 50px 0px;
        position: relative;
        bottom: 0px;
        .containner{
          max-width: 1200px;
          width: 96%;
          margin: auto;
          .footer-content{
            display: flex;
            flex-direction: column;
            width: 100%;
          
           
            .footer-information{
              width: 100%;
              display: flex;
              justify-content: space-between;
              .box{
                        ul{
                        width: 100%;
                        cursor: pointer;
                        h2{
                            font-size:1.4rem;
                            color: #444;
                        }
                        .eula-company{
                          font-size:1.1rem;
                        
                        }
                        li{
                            list-style: none;
                            margin: 5px 0px;
                            font-size: 15px;
                            padding: 0.5rem 0;
                            display: block;
                            font-size:1em;
                            a{
                                :hover{
                                color: #d00;
                              }
                            }
                            
                          }
                        }
                      }

           .img-multiple-devices{
             position: relative;
             display: flex;
             flex-direction: column;
             width: 25%;
           
             
             .box-init{
              width: 100%;
           
            svg{
              width: 100%;
            }
           }
           .btn-free-access{
             
            .footer-store{
              width: 100%;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
               .img-store{
                 width: 150px;
                 display: flex;
                 align-items: center;
                 margin-top: 20px 0px;
                 cursor: pointer;
                 :hover{
                 opacity: 0.5;
                 transition: all ease-out .5s;
               }
               
                 img{
                   width: 100%;
                 }
               }
               .icon-apple{
                 padding: 10px;
                 width:130px;
               }
             }
           
           }
          }
          }
            .descrition-footer{
              width: 100%;
              display: flex;
              flex-wrap:wrap;
              align-items: center;
              flex: 1;
              margin-top: 20px;
              .social-media{
                flex: 1;
                display:flex;
                align-items: center;
              
                span{
                  font-size: 1.6rem;
                  cursor: pointer;
                  margin-right: 20px;
                    a{
                      :hover{
                      color: #d00;
                    }
                  }
                  
                }
              }
            }
            .terms{
                  width: 100%;
                  display: block;
                  justify-content: none;
                  align-items: none;
                  padding: 20px 5px;
              ul{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                order: 2;
                width: 100%;
                li{
                  margin: 2px;
                  list-style: none;
                  font-size: 10px;
                }
              }
          .footer-copyrith{
            span{
              font-size:12px;
            }
          }
        }
    }
}



/************************************resposive ************************************ */

@media (max-width:600px){
        grid-area: footer; 
        background:var(--body-color); 
        width: 100vw;
        height: auto;
        font-size: 100%;
        .containner{
            width: 100%;
            max-width: 1200px;
            width: 96%;
            margin: auto;

            .footer-content{
                width: 100%;
                display: block;
                flex-direction: none;
                width: 100%;
                .footer-information{
                    width: 100%;
                    padding: 10px;
                    display: block;
                    justify-content: none;
                    align-items: none;
                    .img-multiple-devices{
                        display: none;
                    }
                    .box{
                       
                      .option{
                        width: 100%;
                        cursor: pointer;
                        margin-left:10px;
                      
                        h2{
                            font-size:1.2rem;
                            color: #444;
                        }
                        li{
                            list-style: none;
                            margin: 5px 0px;
                            font-size: 15px;
                            display: block;
                            margin-left: 0px;
                            padding: 2px 0px;
                         
                            :hover{
                                    color: #d00;
                                    transition: all .5s;
                                    text-decoration: underline;
                                    cursor: pointer;
                            }
                        }
                       }
                    }
                   
                    .box-init{
                        display: none;
                    }
                    .btn-free-access{
                        display: none;
                        button{
                            display: none;
                        }
                    }
                }
                .descrition-footer{
                 padding: 0.5rem 0.5rem;
                    display: block;
                    flex-direction:column;
                   

                    .discription{
                        order: 2;
                        width: 100%;
                    }
                }
                
                .social-media{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                }
            }

            .terms{
              width: 90%;
              display: block;
              justify-content: none;
              align-items: none;
              margin:auto;
              padding: 30px;
              ul{
                display:flex;
                flex-direction: column;
                justify-content: center;
                display: flex;
             
                li{
                  margin: 0px;
                  list-style: none;
                  font-size: 10px;
                  width: 100%;

                }
              }
          .footer-copyrith{
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            span{
              font-size:12px;
            }
          
        
          }
          .footer-copyrith{
            span{
              font-size:12px;
            }
          }
        }    
   }
}

`