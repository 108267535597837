import React from 'react';
interface Props{
	setBuyError: (value: boolean)=> void,
	setOpenModalBuyModal: (value: boolean)=> void,
	setLoadingBtn: (value: boolean)=> void,
	setMulticaixaExpr: (value: boolean)=> void,
	time: any
}
const Timer  = ({time, setBuyError, setOpenModalBuyModal, setLoadingBtn, setMulticaixaExpr}:Props) =>{

    const { hours = 0, minutes = 1, seconds = 10 } = time;
    const [[hrs, mins, secs], setTime] = React.useState([hours, minutes, seconds]);

    const tick = () => {
   
        // if (hrs === 0 && mins === 0 && secs === 0) 
        //     reset()
        if (mins === 0 && secs === 0) {
            setTime([hrs - 0, 0, 0]);
        } else if (secs === 0) {
            setTime([hrs, mins - 1, 59]);
        } else {
            setTime([hrs, mins, secs - 1]);
        }
    };

	// const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

    
    React.useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });


	if(`${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}` === '00:00'){
		setBuyError(true)

		setTimeout(() => {
			setOpenModalBuyModal(false);
			setLoadingBtn(false)
			setBuyError(false);
			setMulticaixaExpr(false);
		}, 5000)
	}

	return(
		<>	
		    <p id='time-left'>
				{
				
				`${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}` === '00:00'?
				null
				:
				`${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
				
				}
			</p> 
		</>
	);
}

export default Timer;