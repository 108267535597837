import React, { useState } from 'react';
import { FullContainer } from '../styles/pages/eulaStyles'
import { Container } from '../styles/pages/responsive/eulaStyles';

import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

const Eula = () => {
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const onShowMenu = (value: boolean) => {
		setShowMenu(!showMenu);
	}
	return (
		<FullContainer>
			<Container>
				<div className="main-container">
					<header className="header" >
						<div className="nav-bar">
							<NavBar showMenu={showMenu} setShowMenu={setShowMenu} />
						</div>
					</header>
					{showMenu ?
						<MenuBar showMenu={showMenu} onShowMenu={onShowMenu} /> : ''}
					<main id="main_eula">
						<div className="eula-content">
							<h1>Eula</h1>
							<p>
								<h2>Contrato de Licença de Utilizador Final (EULA)</h2>
								<p>
									OBRIGADO POR USAR O NOSSO APLICATIVO. EM PRIMEIRO LUGAR, NÓS RECOMENDAMOS QUE VOCÊ LEIA ESTE ACORDO DE LICENÇA DE Utilizador FINAL ("EULA") COM ATENÇÃO ANTES DE BAIXAR O APLICATIVO OU QUALQUER PARTE DELE DA GOOGLE PLAY STORE OU DA APPLE APP STORE,
									INSTALAR, COPIAR OU USAR O APLICATIVO.
								</p>
								<p>
									ESTE EULA É UM ACORDO ENTRE VOCÊ E TOQUE MEDIA, LDA E REGE SEU USO DO APLICATIVO QUE ACOMPANHA ESTE EULA, O QUAL INCLUI APLICATIVO MÓVEL E
									PODE INCLUIR MÍDIA, MATERIAIS IMPRESSOS OU DOCUMENTAÇÃO "ON-LINE" OU ELETRÔNICA (COLETIVAMENTE, O "APLICATIVO" OU O "APLICATIVO MÓVEL"). AO BAIXAR O APLICATIVO OU INSTALAR,
									COPIAR OU USAR O APLICATIVO DE QUALQUER OUTRA FORMA, VOCÊ CONCORDA EM CUMPRIR OS TERMOS DESTE EULA. SE VOCÊ NÃO CONCORDAR COM TODOS OS TERMOS E CONDIÇÕES DESTE EULA,
									NÃO ABRA, BAIXE, INSTALE, COPIE OU USE O APLICATIVO DE QUALQUER OUTRA FORMA.
								</p>
								<h3>DECLARAÇÃO DE VONTADE</h3>
								<p>
									O Utilizador declara ter lido, compreendido e aceito todos os termos e condições
									deste EULA, e declara, ainda, ter ciência dos direitos e obrigações nele contido,
									constituindo este instrumento o acordo completo entre as partes.
								</p>
								<h3>Direito de propriedade intelectual</h3>
								<p>
									I. Licença destinada a você: Sujeito à sua conformidade com estes Termos e todos os outros termos e políticas aplicáveis, nós concedemos a você uma licença de uso limitada, não exclusiva,
									não sublicenciável (exceto para Provedores de Serviços conforme descrito abaixo), não transferível e não atribuível de uso , e
									integrar-se à Plataforma, mas apenas na medida permitida nestes Termos e todos os outros termos e políticas aplicáveis. Você não vai vender,
									transferir ou sublicenciar a Plataforma para ninguém. Exceto conforme expressamente licenciado neste documento, você não usará, acessará,
									integrará, modificará, traduzirá, criará trabalhos derivados, fará engenharia reversa ou de outra forma explorará a Plataforma ou qualquer aspecto dela.
									Os produtos do Toque Media reservam todos os direitos, títulos e interesses (incluindo o direito de fazer valer tais direitos) não expressamente concedidos nestes Termos.
								</p>
								<h3>Com a sua permissão</h3>
								<p>
									II. Você nos concede uma licença mundial não exclusiva, transferível, sublicenciável, livre de royalties para: hospedar, usar, distribuir, modificar, executar, copiar,
									executar publicamente ou exibir, traduzir e criar trabalhos derivados de quaisquer informações, dados e outro conteúdo disponibilizado por você ou em seu nome
									(incluindo por seus Provedores de Serviços ou por meio de seu Aplicativo) em conexão com a Plataforma (coletivamente, "Seu Conteúdo") para qualquer finalidade
									comercial em conexão com a operação, fornecimento ou melhoria da Plataforma ou qualquer outro Produto do Toque Media. Esta licença permanece em vigor mesmo se você
									parar de usar a Plataforma. Sem limitação, sua licença para nós inclui: o direito de incorporar Seu Conteúdo em outras partes dos Produtos do Toque Media, o direito de
									atribuir a fonte de Seu Conteúdo usando seu nome, marcas registradas ou logotipos; o direito de usar Seu Conteúdo para fins promocionais e o direito de analisar Seu
									Conteúdo (incluindo para garantir que você está cumprindo estes Termos e todos os outros termos e políticas aplicáveis).
								</p>
								<p>
									III. Se você possuía Seu Conteúdo antes de fornecê-lo a nós, continuará possuindo-o após fornecê-lo a nós, sujeito a quaisquer direitos concedidos nestes
									Termos ou quaisquer outros termos ou políticas aplicáveis ​​e qualquer acesso que você forneça a terceiros compartilhando-o através da Plataforma.
								</p>
								<p>
									IV. Seu nome, marcas registradas e logotipos: Você nos concede uma licença mundial não exclusiva, transferível, sublicenciável, isenta de royalties para usar seu
									nome, marcas registradas e logotipos para fins de distribuição, marketing e promocionais, em conexão com o uso de Produtos do Toque Media, em todos os formatos e
									mídias. Esta licença permanece em vigor para materiais e instâncias existentes, mesmo se você parar de usar a Plataforma.
								</p>
								<h3>Uso dos Dados</h3>
								<p>
									Práticas Proibidas: Você não realizará, nem facilitará ou apoiará terceiros na execução de nenhuma das seguintes práticas proibidas:
								</p>
								<p>
									I. Processamento de dados da plataforma para discriminar ou encorajar a discriminação contra pessoas com base em atributos pessoais, incluindo raça, etnia, cor,
									nacionalidade, religião, idade, sexo, orientação sexual, identidade de gênero, status familiar, deficiência, condição médica ou genética ou quaisquer outras categorias
									proibida pela lei aplicável, regulamento ou política do Toque Media.
								</p>
								<p>
									II. Processamento de dados da plataforma para executar, facilitar ou fornecer ferramentas para vigilância. Vigilância inclui o processamento de dados de plataforma
									sobre pessoas, grupos ou eventos para fins de aplicação da lei ou segurança nacional.
								</p>
								<p>
									III. Venda, licenciamento ou compra de dados da plataforma.
								</p>
								<p>
									IV. Colocar os Dados da Plataforma, ou de outra forma disponibilizar os Dados da Plataforma para, um mecanismo de pesquisa ou diretório sem nosso
									consentimento prévio e expresso por escrito.
								</p>
								<p>
									V. Tentar decodificar, contornar, reidentificar, desanonimizar, decodificar, descriptografar ou fazer hash reverso ou fazer engenharia reversa dos Dados da Plataforma fornecidos a você.
								</p>
							</p>
						</div>
					</main>

					<Footer />
				</div>
			</Container>
		</FullContainer >


	)
}

export default Eula;

