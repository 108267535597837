import styled from "styled-components";
export const Background = styled.div<{showModal:boolean}>`
  display: ${(props) => (props.showModal ? "flex" : "none")};
	overflow: hidden;
    width: 100vw;
	background-color: rgba(0,0,0,0.9);
	position: fixed;
	flex-direction: column;
	justify-content: start;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 2000;
	
`;

export const ModalWrapper = styled.div`
  width: 50%;
  height: 100vh;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
   display: flex;
   flex-direction: column;
   justify-content: start;
   position: relative;
   top: 0;
   @media (max-width: 1100px) {
	width: 70%;
   }

   @media (max-width: 768px) {
	width: 80%;
   }
   @media (max-width: 600px) {
	width: 100%;
   }



  .btn-create{
	border:none;
	outline: none;
	background-color: transparent;
	cursor: pointer;
	height: 80px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 20px;
	
	.close{
		background-color: transparent;
		outline: none;
		height: 32px;
		width: 32px;
		border:none;
		display: flex;
		justify-content: center;
		align-items: center;
		
		&:before, &:after{
			content: '';
			position: absolute;
			width: 2px;
			height: 20px;
			background-color: #000;
		}
		&:before{
			transform: rotate(45deg);
		}
		&:after{
			transform: rotate(-45deg);
		}
	}
}
`;


