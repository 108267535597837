// import React, { useState} from 'react';
import { useAuthState } from "react-firebase-hooks/auth";

import { Container } from './styles';

// import { userToken } from '../../Services/Authentication';
import { auth } from '../../firebase/config';
import { LogOut } from '../../services/server';
import { FaUser } from 'react-icons/fa';

// import { Container } from './styles';
interface Menu {
	showMenu?: boolean,
	onShowMenu: (value: boolean) => void
}
const MenuBar: React.FC<Menu> = (props: Menu) => {
	const { onShowMenu, showMenu } = props;

	// const fetchUserName = async () => {
	// 	try {
	// 	//   const q = firestore.query(firestore.collection(dbFirestore, "users"), firestore.where("uid", "==", user?.uid));
	// 	//   const doc = await firestore.getDocs(q);
	// 	//   const data = doc.docs[0].data();
	// 	} catch (err) {
	// 	//   console.error(err);
	// 	}
	//   };

	// const [loading, setLoading ] = useState<boolean>(true);
	// const [loaderSpinner, setLoaderSpinner ] = useState(false);
	const [user] = useAuthState(auth.getAuth());

	//   useEffect(() => {

	// 	if (loading) {
	// 		setLoaderSpinner(true);
	// 		setTimeout(()=>{
	// 			setLoaderSpinner(false);
	// 		}, 500)

	// 		return
	// 	};

	// 	fetchUserName();

	//   }, [user, loading]);

	return (
		<Container>
			<nav>
				<header>
					<nav className="nav-bar">
						<div className="mobile-menu" >
							<div className="logo">
								<img src={"/logotipo.svg"} alt="menu" />
							</div>
							<div className="btn-create noHover" onClick={() => onShowMenu(!showMenu)}>
								<button className="close" />
							</div>
						</div>
					</nav>
				</header>
			</nav>
			<div className="menus">
				<a href="/store">Praça</a>
				<a href="/artist/home">Artistas</a>
				{
					user !== null ?
						<div className='user-container'
							style={{
								width: "100%", display: "flex", alignItems: "center",
								flexDirection: "column"
							}}>
							{
							} <p style={{
								fontSize: "1.2em",
								margin: "10px 0"

							}}>
								<a href='/dashboard/profile' target="_blank">
									<FaUser size={16} /> {
										user?.displayName !== null ? user?.displayName :
											user.displayName
									}
								</a>

							</p>
							<button
								style={{ fontSize: "1.2em", background: "transparent", border: "0" }}
								onClick={() => {
									LogOut();
								}} >Sair</button></div> :
						<a href="/login">Login</a>
				}
				{/* <a href="/login">Login</a> */}
			</div>
		</Container>
	);
}

export default MenuBar;