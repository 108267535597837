import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from 'firebase/auth';

const auth = getAuth();
declare global {
    interface Window { recaptchaVerifier: any; }
}

window.recaptchaVerifier = window.recaptchaVerifier || {};

// phone number Authentication
const setUPRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': (response:any) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log('reCAPTCHA solved');
        },
        'expired-callback': () => {
            // Response expired. Ask user to solve reCAPTCHA again.
        }
    }, auth );
};

export const onSignInSubmit = async (number:string) => {
    setUPRecaptcha();
    const phoneNumber = '+244' + number;
    const appVerifier = window.recaptchaVerifier;
    return signInWithPhoneNumber(auth , phoneNumber, appVerifier);
};

const storage = localStorage.getItem('user');
export const userToken = JSON.parse(storage as string);