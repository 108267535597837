import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  border: 1px solid #ddd;
  color: #666360;
  display: flex;
  align-items: center;
  
  & + div {
    margin-top: 8px;
  }
  input {
    background: transparent;
    flex: 1;
    border: 0;
	font-size: 14pt;
    color: #1c1c1c;
    &::placeholder {
      color: #666360;
      font-size: 14pt;
      
    }
  }
  svg {
    margin-right: 16px;
  }
  span{
    font-size:.9em;
    cursor:pointer;
    
  }
  .edit{
	  background-color: #ddd;
	  height: 25px;
	  width: 25px;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  border-radius:50%;
  }
`;