export const formatNumbers = (x: number, reduce = true): string => {

    const numString = String(x);

    if (reduce && x >= 1000 ) {

        if (numString.length === 6) {
            return `${numString.slice(0, 3)}k`;
        } else if (numString.length >= 7 && numString.length < 10) {
            if (numString.length === 8) {
                return `${numString.slice(0, 2)}M`;
            } else if (numString.length === 9) {
                return `${numString.slice(0, 3)}M`;
            } else {
                return `${numString.slice(0, 1)}M`;
            }
        } else if (numString.length >= 10 && numString.length < 13) {
            if (numString.length === 11) {
                return `${numString.slice(0, 2)}B`;
            } else if (numString.length === 12) {
                return `${numString.slice(0, 3)}B`;
            } else {
                return `${numString.slice(0, 1)}B`;
            }
        } else if (numString.length >= 13 && numString.length < 16) {
            if (numString.length === 11) {
                return `${numString.slice(0, 2)}T`;
            } else if (numString.length === 12) {
                return `${numString.slice(0, 3)}T`;
            } else {
                return `${numString.slice(0, 1)}T`;
            }
        } else {
            return ((`${ Math.round((x * 738) / 1000) },00`).replace(/\B(?=(\d{3})+(?!\d))/g, '.'))        
        }
    }else if(x <= 1000) {
        return '0,00';
    }else{
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}

export const dataGenres = [
	{ value: 'Afro Beat', label: 'Afro Beat' },
	{ value: 'Afro House', label: 'Afro House' },
	{ value: 'Afro Jazz', label: 'Afro Jazz' },
	{ value: 'Afro Naija', label: 'Afro Naija' },
	{ value: 'Afro Pop', label: 'Afro Pop' },
	{ value: 'Amapiano', label: 'Amapiano' },
	{ value: 'Bossa Nova', label: 'Bossa Nova' },
	{ value: 'Black Music', label: 'Black Music' },
	{ value: 'Blues', label: 'Blues' },
	{ value: 'Cazukuta', label: 'Cazukuta' },
	{ value: 'Chillout', label: 'Chillout' },
	{ value: 'Clássica', label: 'Clássica' },
	{ value: 'Coladeira', label: 'Coladeira' },
	{ value: 'Desconhecido', label: 'Desconhecido' },
	{ value: 'Disco', label: 'Disco' },
	{ value: 'Diversos', label: 'Diversos' },
	{ value: 'Drip', label: 'Drip' },
	{ value: 'Drill', label: 'Drill' },
	{ value: 'Eletrônica', label: 'Eletrônica' },
	{ value: 'Fado', label: 'Fado' },
	{ value: 'Funk', label: 'Funk' },
	{ value: 'Ghetto Zouk', label: 'Ghetto Zouk' },
	{ value: 'Gospel', label: 'Gospel' },
	{ value: 'Hard Rock', label: 'Hard Rock' },
	{ value: 'Heavy Metal', label: 'Heavy Metal' },
	{ value: 'Hip-Hop', label: 'Hip-Hop' },
	{ value: 'Horrorcore', label: 'Horrorcore' },
	{ value: 'House', label: 'House' },
	{ value: 'Jazz', label: 'Jazz' },
	{ value: 'Kizomba', label: 'Kizomba' },
	{ value: 'Kuduro', label: 'Kuduro' },
	{ value: 'Massemba', label: 'Massemba' },
	{ value: 'Música Alternativa', label: 'Música Alternativa' },
	{ value: 'Música Folclórica', label: 'Música Folclórica' },
	{ value: 'Palestras', label: 'Palestras' },
	{ value: 'Pagode', label: 'Pagode' },
	{ value: 'Pop Music', label: 'Pop Music' },
	{ value: 'Pop Rock', label: 'Pop Rock' },
	{ value: 'R&B', label: 'R&B' },
	{ value: 'Reggae Ton', label: 'Reggae Ton' },
	{ value: 'Rumba', label: 'Rumba' },
	{ value: 'Rebita', label: 'Rebita' },
	{ value: 'Música Variadas', label: 'Música Variadas' },
	{ value: 'Reggae', label: 'Reggae' },
	{ value: 'Rock Alternativo', label: 'Rock Alternativo' },
	{ value: 'Rock Clássico', label: 'Rock Clássico' },
	{ value: 'Rock and Roll', label: 'Rock and Roll' },
	{ value: 'Romântica', label: 'Romântica' },
	{ value: 'Tarrachinha', label: 'Tarrachinha' },
	{ value: 'Trap', label: 'Trap' },
	{ value: 'Samba', label: 'Samba' },
	{ value: 'Salsa', label: 'Salsa' },
	{ value: 'Semba', label: 'Semba' },
	{ value: 'Soul', label: 'Soul' },
	{ value: 'World Music', label: 'World Music' },
];
